import React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DEFAULT_PAYMENT_METHOD } from '../../const';

function PaymentMethodSelector({ setFormData, formData, paymentMethods, paymentMethodId = null }) {
  const onPaymentMethodSelected = (e, value) => {
    e.preventDefault();

    setFormData({
      ...formData,
      paymentMethodId: value ? value.id : ''
    });
  };

  return (
    <Autocomplete
      id={'select-payment-method'}
      onChange={(event, value) => onPaymentMethodSelected(event, value)}
      options={paymentMethods}
      getOptionLabel={(option) => option.name}
      renderOption={(option) => option.name}
      defaultValue={
        paymentMethodId
          ? paymentMethods.find((item) => item.id === paymentMethodId)
          : paymentMethods.find((item) => item.name === DEFAULT_PAYMENT_METHOD)
      }
      size={'small'}
      renderInput={(params) => (
        <TextField {...params} placeholder={'Select Payment'} required variant="outlined" />
      )}
    />
  );
}

export default PaymentMethodSelector;
