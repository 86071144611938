import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import MaterialTable from 'material-table';
import moment from 'moment';
import { Pagination } from '@material-ui/lab';
import { getSaleReports } from '../../../actions/reports';
import FilterLayout from '../../layout/FilterLayout';
import { SALES_REPORTS } from '../../../permissions';
import { getBranches } from '../../../actions/system';
import ProductsSelector from '../../common/ProductsSelector';
import { getAllProducts } from '../../../actions/products';
import { getCustomers } from '../../../actions/customers';
import AllBranchSelector from '../../common/AllBranchSelector';
import AllCustomerSelected from '../../common/AllCustomerSelected';
import { getMonthName, numberWithCommas, REPORTS_SECTIONS } from '../../../utils';
import FilterTypeSelector from '../../common/FilterTypeSelector';
import YearSelector from '../../common/YearSelector';
import MonthSelector from '../../common/MonthSelector';

function SalesReports({
  isLoading,
  getSaleReports,
  getBranches,
  getAllProducts,
  getCustomers,
  products: { allProducts },
  customers: { customers },
  auth: { permissions },
  reports: { saleReports },
  system: { branches }
}) {
  const [from_date, setFromDate] = useState(
    moment(Date()).subtract(30, 'days').format('YYYY-MM-DD')
  );
  const [to_date, setToDate] = useState(moment(Date()).format('YYYY-MM-DD'));
  let history = useHistory();

  const initState = {
    customerId: '',
    productId: '',
    branchId: '',
    type: 'daily',
    month: '',
    year: ''
  };
  const [showFilterLayout, setShowFilterLayout] = useState(false);
  const [showYearLayout, setShowYearLayout] = useState(false);
  const [searchExecuted, setSearchExecuted] = useState(false);
  const [formData, setFormData] = useState(initState);

  const { customerId, productId, branchId, type, year, month } = formData;

  useEffect(() => {
    setShowFilterLayout(type === 'daily');
    setShowYearLayout(type === 'yearly');
    setSearchExecuted(false);
    getAllSaleReports();
  }, [type]);

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === SALES_REPORTS)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getCustomers({});
    getAllProducts();
    getBranches();
    getAllSaleReports();
  }, []);

  const getAllSaleReports = () => {
    setSearchExecuted(true);
    getSaleReports({ from_date, to_date, customerId, branchId, productId, type, month, year });
  };

  const handleReportsDetails = (date) => {
    history.push({
      pathname: '/reports/sales/by-date',
      search: `?date=${date}`
    });
  };

  const handleReportsDetailsMonthly = (month, year) => {
    setFormData({
      ...formData,
      type: 'yearly',
      year,
      month
    });

    getSaleReports({
      from_date,
      to_date,
      customerId,
      branchId,
      productId,
      type: 'yearly',
      year,
      month
    });

    setSearchExecuted(true);
  };

  const handleReportsDetailsYearly = (year) => {
    setFormData({
      ...formData,
      type: 'yearly',
      year,
      month: ''
    });

    getSaleReports({
      from_date,
      to_date,
      customerId,
      branchId,
      productId,
      type: 'yearly',
      year,
      month: ''
    });

    setSearchExecuted(true);
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/reports'}>Reports</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/reports/sales'}>Sales Reports</Link>
      </li>
    </ol>
  );

  const tableHead = [
    {
      field: 'date',
      title: 'Date',
      render: (rowData) => (
        <span
          className={'font-weight-bold link-primary hover'}
          onClick={() => handleReportsDetails(rowData.date)}
        >
          {rowData.date}
        </span>
      )
    },
    {
      field: 'totalInvoices',
      title: 'Total Invoices',
      render: (rowData) => numberWithCommas(rowData.totalInvoices)
    },
    {
      field: 'itemsQty',
      title: 'Total QTY',
      render: (rowData) => numberWithCommas(rowData.itemsQty)
    },
    {
      field: 'salesValue',
      title: 'Total Sales (৳)',
      render: (rowData) => numberWithCommas(rowData.salesValue)
    },
    {
      field: 'costValue',
      title: 'Total Costs (৳)',
      render: (rowData) => numberWithCommas(rowData.costValue)
    },
    {
      field: 'totalPaid',
      title: 'Total Paid (৳)',
      render: (rowData) => numberWithCommas(rowData.totalPaid)
    },
    {
      field: 'totalDue',
      title: 'Total Due (৳)',
      render: (rowData) => numberWithCommas(rowData.totalDue)
    },
    { field: 'profit', title: 'Profit (৳)', render: (rowData) => numberWithCommas(rowData.profit) },
    {
      field: 'profitMargin',
      title: 'ProfitMargin(%)',
      render: (rowData) => numberWithCommas(rowData.profitMargin)
    }
  ];

  if (searchExecuted) {
    if (type === 'monthly' || (year && !month && type !== 'daily')) {
      tableHead[0] = {
        field: 'month',
        title: 'Month',
        render: (rowData) => (
          <span
            className={'font-weight-bold link-primary hover'}
            onClick={() =>
              handleReportsDetailsMonthly(rowData.month, year ? year : moment().format('YYYY'))
            }
          >
            {`${getMonthName(rowData.month)}-${year ? year : moment().format('YYYY')}`}
          </span>
        )
      };
    }

    if (type === 'yearly' && !month && !year) {
      tableHead[0] = {
        field: 'year',
        title: 'Year',
        render: (rowData) => (
          <span
            className={'font-weight-bold link-primary hover'}
            onClick={() => handleReportsDetailsYearly(rowData.year)}
          >
            {rowData.year}
          </span>
        )
      };
    }
  }

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={REPORTS_SECTIONS} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <div className="d-flex align-items-center justify-content-between">
                    <AllBranchSelector
                      formData={formData}
                      setFormData={setFormData}
                      branches={branches}
                      all={true}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <div className="d-flex align-items-center justify-content-between">
                    <ProductsSelector
                      formData={formData}
                      setFormData={setFormData}
                      products={allProducts}
                    />
                  </div>
                </div>
              </div>

              {customers && (
                <div className="col-md-3">
                  <div className="form-group">
                    <div className="d-flex align-items-center justify-content-between">
                      <AllCustomerSelected
                        formData={formData}
                        setFormData={setFormData}
                        customers={customers}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className={showFilterLayout || showYearLayout ? 'col-md-3' : 'col-md-2'}>
                <div className="form-group">
                  <div className="d-flex align-items-center justify-content-between">
                    <FilterTypeSelector formData={formData} setFormData={setFormData} type={type} />
                  </div>
                </div>
              </div>

              {!showFilterLayout && !showYearLayout && (
                <div className="col-md-1 align-items-center d-flex">
                  <button
                    className="btn btn-primary btn-block search-button"
                    onClick={getAllSaleReports}
                  >
                    Search
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {showYearLayout && (
          <div id="card-content" className={'mt-4'}>
            <div className="card-header">
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <div className="d-flex align-items-center justify-content-between">
                      <YearSelector formData={formData} setFormData={setFormData} year={year} />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <div className="d-flex align-items-center justify-content-between">
                      <MonthSelector formData={formData} setFormData={setFormData} month={month} />
                    </div>
                  </div>
                </div>
                <div className="col-md-1 align-items-center d-flex">
                  <button
                    className="btn btn-primary btn-block search-button"
                    onClick={getAllSaleReports}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {showFilterLayout && (
          <FilterLayout
            handleActions={getAllSaleReports}
            setFromDate={setFromDate}
            setToDate={setToDate}
          />
        )}

        {saleReports && (
          <Fragment>
            <div id="card-content" className={'mt-4'}>
              <div className="card-header">
                <div className="d-flex justify-content-between align-items-center">
                  <div></div>
                  <div className={'text-center'}>
                    <h5>৳{numberWithCommas(saleReports.report.totalInvoices)}</h5>
                    <p className={'mb-0'}>No of Invoices</p>
                  </div>
                  <div className={'text-center'}>
                    <h5>৳{numberWithCommas(saleReports.report.itemsQty)}</h5>
                    <p className={'mb-0'}>Sales QTY</p>
                  </div>
                  <div className={'text-center'}>
                    <h5>৳{numberWithCommas(saleReports.report.salesValue)}</h5>
                    <p className={'mb-0'}>Sales Value</p>
                  </div>
                  <div className={'text-center'}>
                    <h5>৳{numberWithCommas(saleReports.report.costValue)}</h5>
                    <p className={'mb-0'}>Costs Value</p>
                  </div>
                  <div className={'text-center'}>
                    <h5>৳{numberWithCommas(saleReports.report.profit)}</h5>
                    <p className={'mb-0'}>Profit</p>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>

            <div id="card-content" className={'mt-4'}>
              <div className={'overflow-auto'}>
                <MaterialTable
                  title={'Sales Reports'}
                  columns={tableHead}
                  data={saleReports?.paginatedData}
                  isLoading={isLoading}
                  totalCount={saleReports?.total}
                  options={{
                    headerStyle: {
                      backgroundColor: '#F6F9FC',
                      color: '#000'
                    },
                    rowStyle: () => {
                      return {
                        fontSize: '14px',
                        color: '#000'
                      };
                    },
                    padding: 'default',
                    pageSize: 20,
                    actionsColumnIndex: -1,
                    pageSizeOptions: [10, 25, 50],
                    showFirstLastPageButtons: true,
                    exportAllData: true,
                    exportFileName: `Sales Reports - ${moment(new Date()).format('LL')}`,
                    exportButton: true,
                    searchFieldAlignment: 'left',
                    searchFieldStyle: {
                      marginLeft: '3rem'
                    }
                  }}
                  components={{
                    Pagination: (props) => (
                      <div className="text-center d-flex justify-content-end pagination mt-2">
                        <Pagination
                          color={'primary'}
                          count={saleReports?.lastPage}
                          page={saleReports?.currentPage}
                          siblingCount={2}
                          onChange={(e, value) =>
                            getSaleReports({
                              customerId,
                              branchId,
                              productId,
                              type,
                              month,
                              year,
                              from_date,
                              to_date,
                              page: value
                            })
                          }
                        />
                      </div>
                    )
                  }}
                />
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}

SalesReports.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getSaleReports: PropTypes.func.isRequired,
  getBranches: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  reports: state.reports,
  auth: state.auth,
  system: state.system,
  products: state.products,
  customers: state.customers
});

export default connect(mapStateToProps, {
  getSaleReports,
  getBranches,
  getAllProducts,
  getCustomers
})(SalesReports);
