import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded, Print } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import MaterialTable from 'material-table';
import moment from 'moment';
import {
  addPurchaseOrderPayment,
  cancelPurchase,
  getPurchaseList
} from '../../../actions/purchase';
import { Pagination } from '@material-ui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Swal from 'sweetalert2';
import FilterLayout from '../../layout/FilterLayout';
import { MODIFY_PURCHASE, PURCHASE_ENTRY, PURCHASE_INVOICES } from '../../../permissions';
import { PURCHASE_SECTIONS } from '../../../utils';

function PurchaseInvoices({
  isLoading,
  addPurchaseOrderPayment,
  cancelPurchase,
  getPurchaseList,
  auth: { permissions },
  purchase: { purchase }
}) {
  const history = useHistory();
  const [from_date, setFromDate] = useState(
    moment(Date()).subtract(30, 'days').format('YYYY-MM-DD')
  );
  const [to_date, setToDate] = useState(moment(Date()).format('YYYY-MM-DD'));
  const [allTime, setAllTime] = useState(false);
  const [inputFocus, setInputFocus] = useState(true);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [purchasePaymentForm, setPurchasePaymentForm] = useState({
    id: '',
    amount: '',
    date: moment().format('YYYY-MM-DD'),
    description: '',
    reference: ''
  });
  const { id, amount, date, description, reference } = purchasePaymentForm;

  const onChange = (e) =>
    setPurchasePaymentForm({ ...purchasePaymentForm, [e.target.name]: e.target.value });

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === PURCHASE_INVOICES)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getAllPurchaseList();
  }, []);

  const getAllPurchaseList = () => {
    getPurchaseList({ from_date, to_date });
  };

  const openDialog = (data) => {
    setPurchasePaymentForm({
      ...purchasePaymentForm,
      id: data.id,
      amount: data.due,
      description: `Payment for INV-${data.id}`,
      reference: `INV-${data.id}`
    });
    setDialogOpen(true);
    setInputFocus(false);
  };

  const hideDialog = () => {
    setDialogOpen(false);
    setInputFocus(true);
  };

  const handleHistory = () => {
    history.push('#');
  };

  const handlePurchaseDetails = (id) => {
    history.push({
      pathname: '/purchase/details',
      search: `?id=${id}`
    });
  };

  const cancelPurchaseInvoice = (e, id) => {
    e.preventDefault();

    Swal.fire({
      title: 'Cancel Invoice?',
      text: "Are you sure? You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        cancelPurchase(id);
      }
    });
  };
  const moveToPurchaseUpdate = (id) => {
    history.push({
      pathname: '/purchase/update',
      search: `?id=${id}`
    });
  };

  const handleSaleSearch = (value) => {
    getPurchaseList({ search: value });
  };

  const handleSupplierDetails = (id) => {
    history.push({
      pathname: '/suppliers/details',
      search: `?id=${id}`
    });
  };

  const submitPayNow = (e) => {
    e.preventDefault();

    addPurchaseOrderPayment(purchasePaymentForm, from_date, to_date);
    hideDialog();
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/purchase'}>Purchase</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/purchase/all'}>Invoices</Link>
      </li>
    </ol>
  );

  const tableHead = [
    {
      field: 'id',
      title: 'Inv. No',
      render: (rowData) => (
        <Link
          className={'font-weight-bold link-primary hover text-decoration-none'}
          to={`/purchase/details?id=${rowData.id}`}
        >{`PO-${rowData.id}`}</Link>
      )
    },
    { field: 'date', title: 'Date' },
    {
      field: 'supplier.name',
      title: 'Supplier Name',
      render: (rowData) => (
        <Link
          className={'font-weight-bold link-primary hover text-decoration-none'}
          to={`/suppliers/details?id=${rowData.supplier.id}`}
        >
          {rowData.supplier.name}
        </Link>
      )
    },
    { field: 'supplier.phone', title: 'Phone' },
    // {field: 'discount', title: 'Discount (৳)', type: 'numeric'},
    { field: 'total', title: 'Total (৳)' },
    { field: 'paid', title: 'Paid (৳)' },
    { field: 'due', title: 'Due (৳)' },
    { field: 'user.username', title: 'Purchased By' },
    {
      field: 'status.name',
      title: 'Status',
      render: (rowData) => (
        <span className={rowData.status.name.toLowerCase().trim().replace(' ', '-')}>
          {rowData.status.name}
        </span>
      )
    },
    { field: 'tempDesc', title: 'Desc' }
  ];

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={PURCHASE_SECTIONS} />

      <div className="container-fluid mt-4">
        {permissions.find((item) => item.name === PURCHASE_ENTRY) && (
          <div id="card-content">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5>New Purchase Entry</h5>
                  <p className={'mb-0'}>Add new purchase</p>
                </div>
                <div>
                  <Link
                    to={'/purchase/new'}
                    className="btn btn-primary p-2 rounded text-decoration-none"
                  >
                    <i className="far fa-plus-square me-2" /> Purchase Entry
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}

        <FilterLayout
          handleActions={getAllPurchaseList}
          setFromDate={setFromDate}
          setToDate={setToDate}
          setAllTime={setAllTime}
        />

        {purchase && (
          <div id="card-content" className={'mt-3 invoice-table'}>
            <div className={'overflow-auto'}>
              <MaterialTable
                title={'Invoices'}
                columns={tableHead}
                data={purchase?.paginatedData}
                isLoading={isLoading}
                totalCount={purchase?.total}
                options={{
                  headerStyle: {
                    backgroundColor: '#F6F9FC',
                    color: '#000'
                  },
                  rowStyle: (rowData) => {
                    return {
                      fontSize: '14px',
                      color: '#000',
                      height: 50
                    };
                  },
                  padding: 'default',
                  pageSize: 20,
                  actionsColumnIndex: -1,
                  pageSizeOptions: [10, 25, 50],
                  showFirstLastPageButtons: true,
                  exportAllData: true,
                  exportFileName: `Invoices - ${moment(new Date()).format('LL')}`,
                  exportButton: true,
                  searchFieldAlignment: 'left',
                  searchAutoFocus: inputFocus,
                  searchFieldStyle: {
                    marginLeft: '3rem'
                  }
                }}
                components={
                  !allTime && {
                    Pagination: (props) => (
                      <div className="text-center d-flex justify-content-end pagination mt-2">
                        <Pagination
                          color={'primary'}
                          count={purchase?.lastPage}
                          page={purchase?.currentPage}
                          siblingCount={2}
                          onChange={(e, value) =>
                            getPurchaseList({ from_date, to_date, page: value })
                          }
                        />
                      </div>
                    )
                  }
                }
                onSearchChange={handleSaleSearch}
                // onRowClick={(event, rowData) => handlePurchaseDetails(rowData.id)}
                actions={[
                  {
                    icon: 'refresh',
                    onClick: (event, rowData) =>
                      allTime
                        ? getPurchaseList({ all: 'true' })
                        : getPurchaseList({
                            from_date,
                            to_date
                          }),
                    isFreeAction: true,
                    tooltip: 'Refresh'
                  },
                  (rowData) => ({
                    icon: 'visibility_on',
                    onClick: () => handlePurchaseDetails(rowData.id),
                    isFreeAction: true,
                    tooltip: 'Purchase details'
                  }),
                  (rowData) => ({
                    icon: () => (
                      <Link
                        className={'table-icon'}
                        to={`/purchase/print?id=${rowData.id}`}
                        target="_blank"
                      >
                        <Print />
                      </Link>
                    ),
                    onClick: rowData,
                    tooltip: 'Print Invoice'
                  }),
                  (rowData) => ({
                    icon: 'payment',
                    onClick: () => openDialog(rowData),
                    isFreeAction: true,
                    tooltip: 'Pay Now',
                    disabled: rowData.status.name === 'Paid'
                  }),
                  permissions.find((item) => item.name === MODIFY_PURCHASE) &&
                    ((rowData) => ({
                      icon: 'edit',
                      onClick: (e) => moveToPurchaseUpdate(rowData.id),
                      tooltip: 'Edit Invoice'
                    })),
                  permissions.find((item) => item.name === MODIFY_PURCHASE) &&
                    ((rowData) => ({
                      icon: 'close',
                      onClick: (e) => cancelPurchaseInvoice(e, rowData.id),
                      tooltip: 'Cancel purchase'
                    }))
                ]}
              />
            </div>
          </div>
        )}
      </div>

      <Dialog
        open={dialogOpen}
        fullWidth="sm"
        maxWidth="sm"
        disableBackdropClick
        onClose={hideDialog}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="alert-dialog-slide-title">New Purchase Payment</DialogTitle>
        <DialogContent>
          <div className="card-body">
            <React.Fragment>
              <div className="form-group">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label htmlFor={'subTotal'}>
                      Amount<span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <input
                      type="number"
                      name={'amount'}
                      value={amount}
                      required
                      min={0}
                      onChange={(e) => onChange(e)}
                      step=".01"
                      placeholder={'Amount'}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label className={'m-md-0 mb-1'}>
                      Date<span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <input
                      type="date"
                      name={'date'}
                      required
                      defaultValue={date}
                      onChange={(e) => onChange(e)}
                      className="form-control"
                      placeholder={'Choose a date'}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label htmlFor={'description'}>
                      Description<span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <input
                      type="text"
                      name={'description'}
                      value={description}
                      required
                      onChange={(e) => onChange(e)}
                      disabled={true}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label htmlFor={'reference'}>Reference</label>
                  </div>
                  <div className="col-8">
                    <input
                      type="text"
                      name={'reference'}
                      value={reference}
                      onChange={(e) => onChange(e)}
                      placeholder={'Reference'}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={hideDialog} color="secondary">
            Cancel
          </Button>
          <Button color="primary" onClick={(e) => submitPayNow(e)}>
            Pay Now
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

PurchaseInvoices.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getPurchaseList: PropTypes.func.isRequired,
  purchase: PropTypes.object.isRequired,
  addPurchaseOrderPayment: PropTypes.func.isRequired,
  cancelPurchase: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  purchase: state.purchase,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getPurchaseList,
  addPurchaseOrderPayment,
  cancelPurchase
})(PurchaseInvoices);
