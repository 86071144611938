import {
  AUTH_ERROR,
  GET_TOKEN,
  LOADING,
  LOADING_FALSE,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  REGISTER_FAILED,
  REGISTER_SUCCESS,
  USER_DATA,
  USER_LOADED,
  USER_PERMISSIONS
} from './types';
import { returnErrors } from './messages';
import API from '../api';
import Swal from 'sweetalert2';

// CHECK TOKEN & LOAD USER
export const loadUser = () => async (dispatch) => {
  // AddUser Loading
  dispatch({ type: LOADING });

  try {
    const res = await API.auth.getUser();
    dispatch({
      type: USER_LOADED,
      payload: res.data.data
    });

    dispatch(getLoggedUserPermissions(res.data.data.id));
  } catch (err) {
    dispatch({
      type: AUTH_ERROR
    });
  }
};

export const getLoggedUserPermissions = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.system.getUserPermissions(id);
    dispatch({
      type: USER_PERMISSIONS,
      payload: res.data.data
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR
    });
  }
};

// LOGIN USER
export const login = (email, password) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.auth.login({ email, password });
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({
      type: LOGIN_FAILED
    });
  }
};

export const getToken = () => async (dispatch, getState) => {
  try {
    console.log('Get token called from alert');
    const res = await API.auth.getToken({ token: getState().auth.user.refreshToken });
    dispatch({
      type: GET_TOKEN,
      payload: res.data.data
    });
  } catch (err) {
    // dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

// LOGOUT USER
export const logout = () => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.auth.logout();
    if (res.data.success) {
      dispatch({
        type: LOGOUT_SUCCESS
      });
    }
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

// REGISTER USER
export const register =
  ({ username, email, password, confirm_password }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.auth.register({ username, email, confirm_password, password });
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: REGISTER_FAILED
      });
    }
  };

export const createUser = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.auth.createUser(data);
    dispatch({ type: LOADING_FALSE });
    await Swal.fire(res.data.message, 'User created successfully.', 'success');
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getUserById = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.auth.getUserById(id);
    dispatch({ type: USER_DATA, payload: res.data.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const updateUser = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.auth.updateUser(data);
    dispatch({ type: LOADING_FALSE });
    const dialogRes = await Swal.fire(res.data.message, 'User updated successfully.', 'success');
    if (dialogRes) {
      window.history.back();
    }
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

// UPDATE USER
// export const update = (data) => async dispatch => {
//     dispatch({type: LOADING});
//
//     try {
//         const res = await API.auth.updateUser(data)
//
//         console.log(res.data)
//
//         dispatch({
//             type: UPDATE_SUCCESS,
//             payload: res.data.data
//         })
//     } catch (err) {
//         dispatch(returnErrors(err.response.data, err.response.status));
//         dispatch({
//             type: REGISTER_FAILED
//         })
//     }
// }
