import { axios, base_url, headers } from '../api';

const qs = require('qs');

const addStockItem = async (data) => {
  return await axios.post(`${base_url}/stocks/add-item`, qs.stringify(data), {
    headers: headers()
  });
};

const addStockItemVariation = async (data) => {
  return await axios.post(`${base_url}/stocks/add-item-variation-stock`, qs.stringify(data), {
    headers: headers()
  });
};

const getStock = async (data) => {
  return await axios.post(`${base_url}/stocks/`, qs.stringify(data), {
    headers: headers()
  });
};

const decrementStockItem = async (data) => {
  return await axios.post(`${base_url}/stocks/decrement-item`, qs.stringify(data), {
    headers: headers()
  });
};

const decrementItemVariationStock = async (data) => {
  return await axios.post(`${base_url}/stocks/decrement-item-variation-stock`, qs.stringify(data), {
    headers: headers()
  });
};

const stockTransfer = async (data) => {
  return await axios.post(`${base_url}/stocks/transfer`, qs.stringify(data), {
    headers: headers()
  });
};

const getStockTransfers = async (data) => {
  return await axios.post(`${base_url}/stocks/transfers`, qs.stringify(data), {
    headers: headers()
  });
};

const stockReport = async (data) => {
  return await axios.post(`${base_url}/stocks/report`, qs.stringify(data), {
    headers: headers()
  });
};

const getStockReports = async (data) => {
  return await axios.post(`${base_url}/stocks/reports/${data.type}`, qs.stringify(data), {
    headers: headers()
  });
};

const getProductStockReports = async (data) => {
  return await axios.post(`${base_url}/stocks/reports/product/${data.id}`, qs.stringify(data), {
    headers: headers()
  });
};

const getProductStock = async (id) => {
  return await axios.get(`${base_url}/stocks/product/${id}`, {
    headers: headers()
  });
};

const checkStockAvailability = async (id) => {
  return await axios.get(`${base_url}/stocks/sale/${id}`, {
    headers: headers()
  });
};

export default {
  addStockItem,
  addStockItemVariation,
  getStock,
  decrementItemVariationStock,
  decrementStockItem,
  stockTransfer,
  getStockTransfers,
  stockReport,
  getStockReports,
  getProductStockReports,
  getProductStock,
  checkStockAvailability
};
