import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ breadcrumbs, sectionNames }) => {
  return (
    <section id={'breadcrumb-container'} className={'d-flex justify-content-between'}>
      {breadcrumbs}
      {sectionNames && (
        <div>
          {sectionNames.map((item, index) => (
            <Link
              key={index}
              to={item.link}
              replace={true}
              className={'d-inline-block me-4 text-uppercase mb-0 hover-link'}
            >
              {item.name}
            </Link>
          ))}
        </div>
      )}
    </section>
  );
};

export default Breadcrumb;
