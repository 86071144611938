import {
  CLEAR_EMPLOYEE,
  DEPARTMENT,
  DESIGNATION,
  EMPLOYEE,
  LOADING,
  LOADING_FALSE,
  SALARY_HISTORY,
  SINGLE_EMPLOYEE
} from './types';
import API from '../api';
import { returnErrors } from './messages';
import Swal from 'sweetalert2';

export const addEmployee = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    let currentAddressId = '';
    let permAddressId = '';

    // save permanent address and current address if available
    if (data.cur_division) {
      const currentAddress = {
        address: data.cur_address,
        division: data.cur_division,
        city: data.cur_city,
        area: data.cur_area,
        zip: data.cur_zip
      };

      const res = await API.system.addAddress(currentAddress);
      currentAddressId = res.data.data.id;
    }

    if (data.per_division) {
      const perAddress = {
        address: data.per_address,
        division: data.per_division,
        city: data.per_city,
        area: data.per_area,
        zip: data.per_zip
      };

      const res = await API.system.addAddress(perAddress);
      permAddressId = res.data.data.id;
    }

    const employeeData = {
      ...data,
      permAddressId,
      currentAddressId
    };

    console.log(employeeData);

    const res = await API.employee.addEmployee(employeeData);
    dispatch({ type: LOADING_FALSE });

    const dialogRes = await Swal.fire('Employee Added!', res.data.message, 'success');
    if (dialogRes) {
      window.history.back();
    }
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const updateEmployee = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    // update address if address Id there
    if (data.currentAddressId) {
      const currentAddress = {
        id: data.currentAddressId,
        address: data.cur_address,
        division: data.cur_division,
        city: data.cur_city,
        area: data.cur_area,
        zip: data.cur_zip
      };

      await API.system.updateAddress(currentAddress);
    }

    if (data.permAddressId) {
      const perAddress = {
        id: data.permAddressId,
        address: data.per_address,
        division: data.per_division,
        city: data.per_city,
        area: data.per_area,
        zip: data.per_zip
      };

      await API.system.updateAddress(perAddress);
    }

    const employeeData = {
      ...data
    };

    const res = await API.employee.updateEmployee(employeeData);
    dispatch({ type: LOADING_FALSE });

    const dialogRes = await Swal.fire('Employee Updated!', res.data.message, 'success');
    if (dialogRes) {
      window.history.back();
    }
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getEmployeeList =
  ({ page, size }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.employee.getEmployeeList({ page, size });
      dispatch({ type: LOADING_FALSE });
      dispatch({
        type: EMPLOYEE,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getEmployeeDetails = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.employee.getEmployeeDetails(id);
    dispatch({ type: LOADING_FALSE });
    dispatch({
      type: SINGLE_EMPLOYEE,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const clearEmployee = () => async (dispatch) => {
  dispatch({ type: CLEAR_EMPLOYEE });
};

export const deleteEmployee = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    await API.employee.deleteEmployee(id);
    dispatch({ type: LOADING_FALSE });

    dispatch(getEmployeeList({}));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getDesignations =
  ({ page, size }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.employee.getDesignations({ page, size });
      dispatch({ type: LOADING_FALSE });
      dispatch({
        type: DESIGNATION,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getDepartments =
  ({ page, size }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.employee.getDepartments({ page, size });
      dispatch({ type: LOADING_FALSE });
      dispatch({
        type: DEPARTMENT,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const addDesignation = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.employee.addDesignation(data);
    dispatch({ type: LOADING_FALSE });
    dispatch(getDesignations({}));
    return res.data.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const addDepartment = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.employee.addDepartment(data);
    dispatch({ type: LOADING_FALSE });
    dispatch(getDepartments({}));
    return res.data.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const deleteDesignation = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    await API.employee.deleteDesignation(id);
    dispatch({ type: LOADING_FALSE });
    dispatch(getDesignations({}));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const deleteDepartment = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    await API.employee.deleteDepartment(id);
    dispatch({ type: LOADING_FALSE });
    dispatch(getDepartments({}));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const updateDesignation = (id, data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.employee.updateDesignation(id, data);
    dispatch({ type: LOADING_FALSE });

    const dialogRes = await Swal.fire('Designation Updated!', res.data.message, 'success');
    if (dialogRes) {
      window.history.back();
    }
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const updateDepartment = (id, data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.employee.updateDepartment(id, data);
    dispatch({ type: LOADING_FALSE });

    const dialogRes = await Swal.fire('Department Updated!', res.data.message, 'success');
    if (dialogRes) {
      window.history.back();
    }
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getDepartment = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.employee.getDepartment(id);
    dispatch({ type: LOADING_FALSE });
    return res.data.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getDesignation = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.employee.getDesignation(id);
    dispatch({ type: LOADING_FALSE });
    return res.data.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getSalaryHistory =
  ({ page, size, from_date, to_date }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.employee.getSalaryHistory({ page, size, from_date, to_date });
      dispatch({ type: LOADING_FALSE });
      dispatch({
        type: SALARY_HISTORY,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const addSalary = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.employee.addSalary(data);
    dispatch({ type: LOADING_FALSE });

    const dialogRes = await Swal.fire('Salary added!', res.data.message, 'success');
    if (dialogRes) {
      window.history.back();
    }
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};
