import {
  ACCOUNT_TYPES,
  BRANCH_DETAILS,
  BRANCHES,
  EXPENSE_CATEGORIES,
  GET_COMPANY,
  GET_ROLES,
  INCOME_CATEGORIES,
  INCOME_EXPENSE_CATEGORIES,
  LOCATIONS,
  PAYMENT_METHODS,
  PERMISSIONS,
  SINGLE_PERMISSIONS
} from '../actions/types';

const initialState = {
  roles: [],
  branches: [],
  company: null,
  branchDetails: null,
  accountTypes: [],
  paymentMethods: [],
  incomeExpenseCategories: [],
  incomeCategories: [],
  expenseCategories: [],
  permissions: [],
  singlePermissions: [],
  locations: []
};

export default function (state = initialState, action) {
  const payload = action.payload;
  switch (action.type) {
    case GET_ROLES:
      return {
        ...state,
        roles: payload
      };

    case BRANCHES:
      return {
        ...state,
        branches: payload
      };

    case GET_COMPANY:
      return {
        ...state,
        company: payload
      };

    case ACCOUNT_TYPES:
      return {
        ...state,
        accountTypes: payload
      };
    case PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: payload
      };

    case INCOME_CATEGORIES:
      return {
        ...state,
        incomeCategories: payload
      };

    case INCOME_EXPENSE_CATEGORIES:
      return {
        ...state,
        incomeExpenseCategories: payload
      };

    case BRANCH_DETAILS:
      return {
        ...state,
        branchDetails: payload
      };

    case EXPENSE_CATEGORIES:
      return {
        ...state,
        expenseCategories: payload
      };

    case PERMISSIONS:
      return {
        ...state,
        permissions: payload
      };

    case SINGLE_PERMISSIONS:
      return {
        ...state,
        singlePermissions: payload
      };

    case LOCATIONS:
      return {
        ...state,
        locations: payload
      };

    default:
      return state;
  }
}
