import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getSupplierPayment } from '../../../actions/suppliers';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Pagination } from '@material-ui/lab';
import FilterLayout from '../../layout/FilterLayout';

function SupplierPayments({
  isLoading,
  supplierId,
  getSupplierPayment,
  suppliers: { supplierPayments }
}) {
  const history = useHistory();
  const [from_date, setFromDate] = useState(
    moment(Date()).subtract(30, 'days').format('YYYY-MM-DD')
  );
  const [to_date, setToDate] = useState(moment(Date()).format('YYYY-MM-DD'));

  useEffect(() => {
    getAllSupplierPayment();
  }, []);

  const getAllSupplierPayment = () => {
    if (supplierId) {
      getSupplierPayment({ id: supplierId, from_date, to_date });
    }
  };

  const handleDetails = (id) => {
    history.push({
      pathname: '/suppliers/add-payment',
      search: `?id=${id}`
    });
  };

  const tableHead = [
    { field: 'date', title: 'Date' },
    { field: 'trans_type', title: 'Type' },
    { field: 'supplier.name', title: 'Supplier Name' },
    { field: 'supplier.phone', title: 'Supplier Phone' },
    { field: 'bank_tran.bank_account.account_name', title: 'Account' },
    { field: 'description', title: 'Description' },
    { field: 'reference', title: 'Reference' },
    { field: 'amount', title: 'Amount (৳)' }
  ];

  return (
    <Fragment>
      <div className="container-fluid">
        <div id="card-content">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Add Supplier Payment</h5>
                <p className={'mb-0'}>Add and manage supplier payments</p>
              </div>
              <div>
                <a
                  onClick={() => handleDetails(supplierId)}
                  className="btn btn-primary p-2 rounded text-decoration-none"
                >
                  <i className="far fa-plus-square me-2" /> Add Payment
                </a>
              </div>
            </div>
          </div>
        </div>

        <FilterLayout
          handleActions={getAllSupplierPayment}
          setFromDate={setFromDate}
          setToDate={setToDate}
        />

        {supplierPayments && (
          <div id="card-content" className={'mt-4'}>
            <div className={'overflow-auto'}>
              <MaterialTable
                title={'payments'}
                columns={tableHead}
                data={supplierPayments?.paginatedData}
                isLoading={isLoading}
                totalCount={supplierPayments?.total}
                options={{
                  headerStyle: {
                    backgroundColor: '#F6F9FC',
                    color: '#000'
                  },
                  rowStyle: () => {
                    return {
                      fontSize: '14px',
                      color: '#000'
                    };
                  },
                  padding: 'default',
                  pageSize: 20,
                  actionsColumnIndex: -1,
                  pageSizeOptions: [10, 25, 50],
                  showFirstLastPageButtons: true,
                  exportAllData: true,
                  exportFileName: `Payments - ${moment(new Date()).format('LL')}`,
                  exportButton: true,
                  searchFieldAlignment: 'left',
                  searchFieldStyle: {
                    marginLeft: '3rem'
                  }
                }}
                components={{
                  Pagination: () => (
                    <div className="text-center d-flex justify-content-end pagination mt-2">
                      <Pagination
                        color={'primary'}
                        count={supplierPayments?.lastPage}
                        page={supplierPayments?.currentPage}
                        siblingCount={2}
                        onChange={(e, value) =>
                          getSupplierPayment({
                            id: supplierId,
                            from_date,
                            to_date,
                            page: value
                          })
                        }
                      />
                    </div>
                  )
                }}
              />
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}

SupplierPayments.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  suppliers: PropTypes.object.isRequired,
  getSupplierPayment: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  suppliers: state.suppliers
});

export default connect(mapStateToProps, { getSupplierPayment })(SupplierPayments);
