import {
  DAY_PURCHASE_REPORTS,
  DAY_SALE_REPORTS,
  EXPENSES_REPORTS,
  INCOME_EXPENSE_REPORTS,
  INCOME_EXPENSE_REPORTS_YEARLY,
  INCOME_REPORTS,
  PURCHASE_REPORTS,
  SALE_REPORTS,
  SALES_HISTORY
} from '../actions/types';

const initialState = {
  saleReports: null,
  daySaleReports: null,
  purchaseReports: null,
  dayPurchaseReports: null,
  salesHistory: null,
  expensesReports: [],
  incomeReports: [],
  incomeExpenseReportsYearly: [],
  incomeExpenseReports: null
};

export default function (state = initialState, action) {
  const payload = action.payload;
  switch (action.type) {
    case SALE_REPORTS:
      return {
        ...state,
        saleReports: payload
      };
    case DAY_SALE_REPORTS:
      return {
        ...state,
        daySaleReports: payload
      };
    case DAY_PURCHASE_REPORTS:
      return {
        ...state,
        dayPurchaseReports: payload
      };
    case PURCHASE_REPORTS:
      return {
        ...state,
        purchaseReports: payload
      };
    case SALES_HISTORY:
      return {
        ...state,
        salesHistory: payload
      };
    case EXPENSES_REPORTS:
      return {
        ...state,
        expensesReports: payload
      };
    case INCOME_REPORTS:
      return {
        ...state,
        incomeReports: payload
      };
    case INCOME_EXPENSE_REPORTS:
      return {
        ...state,
        incomeExpenseReports: payload
      };
    case INCOME_EXPENSE_REPORTS_YEARLY:
      return {
        ...state,
        incomeExpenseReportsYearly: payload
      };
    default:
      return state;
  }
}
