import { axios, base_url, headers } from '../api';

const qs = require('qs');

const addSale = async (data) => {
  return await axios.post(`${base_url}/sale/new`, qs.stringify(data), {
    headers: headers()
  });
};

const updateSale = async (saleId, data) => {
  return await axios.put(`${base_url}/sale/${saleId}/update`, qs.stringify(data), {
    headers: headers()
  });
};

const saleExchange = async (saleId, data) => {
  return await axios.post(`${base_url}/sale/${saleId}/exchange`, qs.stringify(data), {
    headers: headers()
  });
};

const getSalesExchangeList = async (data) => {
  return await axios.post(`${base_url}/sale/exchange`, qs.stringify(data), {
    headers: headers()
  });
};

const getSaleExchangeDetails = async (id) => {
  return await axios.get(`${base_url}/sale/exchange/${id}`, {
    headers: headers()
  });
};

const deleteSaleItem = async (saleItemId) => {
  return await axios.delete(`${base_url}/sale/sale-item/${saleItemId}`, {
    headers: headers()
  });
};

const addSaleItem = async (saleId, data) => {
  return await axios.post(`${base_url}/sale/${saleId}/add-item`, qs.stringify(data), {
    headers: headers()
  });
};

const updateSaleItem = async (saleId, data) => {
  return await axios.put(`${base_url}/sale/${saleId}/update-item`, qs.stringify(data), {
    headers: headers()
  });
};

const getSaleList = async (data) => {
  return await axios.post(`${base_url}/sale/`, qs.stringify(data), {
    headers: headers()
  });
};

const getPendingSales = async (data) => {
  return await axios.post(`${base_url}/sale/pending`, qs.stringify(data), {
    headers: headers()
  });
};

const getSaleDetails = async (id) => {
  return await axios.get(`${base_url}/sale/${id}`, {
    headers: headers()
  });
};

const getPackagingDetails = async (id) => {
  return await axios.get(`${base_url}/sale/${id}/packaging`, {
    headers: headers()
  });
};

const getExchangePackagingDetails = async (id) => {
  return await axios.get(`${base_url}/sale/${id}/packaging/exchange`, {
    headers: headers()
  });
};

const cancelSale = async (id, data) => {
  return await axios.post(`${base_url}/sale/${id}/cancel`, qs.stringify(data), {
    headers: headers()
  });
};

const addSaleReturn = async (id, data) => {
  return await axios.post(`${base_url}/sale/${id}/return`, qs.stringify(data), {
    headers: headers()
  });
};

const getSaleReturnList = async (data) => {
  return await axios.post(`${base_url}/sale/return`, qs.stringify(data), {
    headers: headers()
  });
};

const paySaleReturn = async (data) => {
  return await axios.post(`${base_url}/sale/return/${data.id}/pay`, qs.stringify(data), {
    headers: headers()
  });
};

const getSaleReturnsDetails = async (saleId) => {
  return await axios.get(`${base_url}/sale/return/${saleId}`, {
    headers: headers()
  });
};

const clearSaleEntry = async (data) => {
  return await axios.post(`${base_url}/sale/clear-sale-entry`, qs.stringify(data), {
    headers: headers()
  });
};

const addSaleOrderPayment = async (data) => {
  return await axios.post(`${base_url}/sale/${data.id}/pay-sale/`, qs.stringify(data), {
    headers: headers()
  });
};

const addExchangeOrderPayment = async (data) => {
  return await axios.post(`${base_url}/sale/${data.id}/pay-exchange/`, qs.stringify(data), {
    headers: headers()
  });
};

const addQuotation = async (data) => {
  return await axios.post(`${base_url}/sale/quotations/new`, qs.stringify(data), {
    headers: headers()
  });
};

const updateQuotation = async (saleId, data) => {
  return await axios.put(`${base_url}/sale/quotations/${saleId}/update`, qs.stringify(data), {
    headers: headers()
  });
};

const deleteQuotationItem = async (saleItemId) => {
  return await axios.delete(`${base_url}/sale/quotations/items/${saleItemId}`, {
    headers: headers()
  });
};

const addQuotationItem = async (saleId, data) => {
  return await axios.post(`${base_url}/sale/quotations/${saleId}/add-item`, qs.stringify(data), {
    headers: headers()
  });
};

const updateQuotationItem = async (saleId, data) => {
  return await axios.put(`${base_url}/sale/quotations/${saleId}/update-item`, qs.stringify(data), {
    headers: headers()
  });
};

const getQuotationList = async (data) => {
  return await axios.post(`${base_url}/sale/quotations/`, qs.stringify(data), {
    headers: headers()
  });
};

const getQuotationDetails = async (id) => {
  return await axios.get(`${base_url}/sale/quotations/${id}`, {
    headers: headers()
  });
};

const deleteQuotation = async (id) => {
  return await axios.delete(`${base_url}/sale/quotations/${id}`, {
    headers: headers()
  });
};

const addPackaging = async (data) => {
  return await axios.post(`${base_url}/sale/packaging`, qs.stringify(data), {
    headers: headers()
  });
};

const clearPackedItems = async (data) => {
  return await axios.post(`${base_url}/sale/packaging/clear-packaging`, qs.stringify(data), {
    headers: headers()
  });
};

const markDeliver = async (id) => {
  return await axios.post(
    `${base_url}/sale/${id}/mark-deliver`,
    {},
    {
      headers: headers()
    }
  );
};

const markExchangeDeliver = async (id) => {
  return await axios.post(
    `${base_url}/sale/${id}/mark-deliver/exchange`,
    {},
    {
      headers: headers()
    }
  );
};

const getSaleId = async () => {
  return await axios.get(`${base_url}/sale/saleId`, {
    headers: headers()
  });
};

export default {
  addSale,
  addSaleItem,
  getSaleList,
  addSaleReturn,
  getSaleReturnList,
  getSaleDetails,
  clearSaleEntry,
  addSaleOrderPayment,
  updateSaleItem,
  updateSale,
  deleteSaleItem,
  cancelSale,
  getQuotationDetails,
  addQuotation,
  updateQuotation,
  deleteQuotation,
  addQuotationItem,
  updateQuotationItem,
  deleteQuotationItem,
  getQuotationList,
  getPendingSales,
  getPackagingDetails,
  addPackaging,
  clearPackedItems,
  markDeliver,
  paySaleReturn,
  getSaleReturnsDetails,
  saleExchange,
  getSalesExchangeList,
  getSaleExchangeDetails,
  getExchangePackagingDetails,
  markExchangeDeliver,
  addExchangeOrderPayment,
  getSaleId
};
