import React, { Fragment, useState } from 'react';
import moment from 'moment';

function FilterLayout({ handleActions, setFromDate, setToDate, setAllTime }) {
  const [range, setRange] = useState({
    from_date: moment(Date()).subtract(30, 'days').format('YYYY-MM-DD'),
    to_date: moment().format('YYYY-MM-DD')
  });
  const { from_date, to_date } = range;
  const onChange = (e) => setRange({ ...range, [e.target.name]: e.target.value });

  const returnDataToParentCom = (fromDate, toDate) => {
    setFromDate(fromDate);
    setToDate(toDate);
    if (setAllTime) {
      setAllTime(false);
    }
  };

  const returnSetAllTime = () => {
    setAllTime(true);
  };

  const handleDateSearch = (e) => {
    e.preventDefault();
    handleActions();
    returnDataToParentCom(from_date, to_date);
  };

  const handleToday = (e) => {
    e.preventDefault();

    const date = moment(Date()).format('YYYY-MM-DD');
    handleActions();
    returnDataToParentCom(date, date);
  };

  const handleYesterday = (e) => {
    e.preventDefault();

    const date = moment(Date()).subtract(1, 'days').endOf('day').format('YYYY-MM-DD');
    handleActions();
    returnDataToParentCom(date, date);
  };

  const handleThisMonth = (e) => {
    e.preventDefault();

    let firstDayOfThisMonth = moment().startOf('month').format('YYYY-MM-DD');
    let LastDayOfThisMonth = moment().endOf('month').format('YYYY-MM-DD');
    handleActions();
    returnDataToParentCom(firstDayOfThisMonth, LastDayOfThisMonth);
  };

  const handleAllTime = (e) => {
    e.preventDefault();

    handleActions();
    returnSetAllTime();
  };

  return (
    <Fragment>
      <div id="card-content" className={'mt-3'}>
        <div className="card-header">
          <div className="row d-flex align-items-center">
            <div className={setAllTime ? 'col-md-6' : 'col-md-7'}>
              <div className="row d-flex align-items-center">
                <div className="col-md-5">
                  <div className="row d-flex align-items-center">
                    <div className="col-sm-2 pr-0">
                      <label className={'m-md-0 mb-1'} htmlFor={'from'}>
                        From
                      </label>
                    </div>
                    <div className="col-sm-10">
                      <div className="input-group">
                        <input
                          type="date"
                          id={'from_date'}
                          name={'from_date'}
                          value={from_date}
                          className={'form-control date-picker'}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="row d-flex align-items-center">
                    <div className="col-sm-2 pr-0">
                      <label className={'m-md-0 mb-1'} htmlFor={'to'}>
                        To
                      </label>
                    </div>
                    <div className="col-sm-10">
                      <div className="input-group">
                        <input
                          type="date"
                          name={'to_date'}
                          value={to_date}
                          id={'to_date'}
                          className={'form-control date-picker'}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <button
                    className="btn btn-primary btn-block search-button"
                    onClick={handleDateSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>

            <div className={setAllTime ? 'col-md-6' : 'col-md-5'}>
              {setAllTime ? (
                <div className="row">
                  <div className="col-sm-3 mt-2 mt-md-0">
                    <button className="btn btn-outline-primary btn-block" onClick={handleToday}>
                      Today
                    </button>
                  </div>
                  <div className="col-sm-3 mt-2 mt-md-0">
                    <button className="btn btn-outline-primary btn-block" onClick={handleYesterday}>
                      Yesterday
                    </button>
                  </div>
                  <div className="col-sm-3 mt-2 mt-md-0">
                    <button className="btn btn-outline-primary btn-block" onClick={handleThisMonth}>
                      This Month
                    </button>
                  </div>
                  <div className="col-sm-3 mt-2 mt-md-0">
                    <button className="btn btn-outline-primary btn-block" onClick={handleAllTime}>
                      All Time
                    </button>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-sm-3 mt-2 mt-md-0">
                    <button className="btn btn-outline-primary btn-block" onClick={handleToday}>
                      Today
                    </button>
                  </div>
                  <div className="col-sm-4 mt-2 mt-md-0">
                    <button className="btn btn-outline-primary btn-block" onClick={handleYesterday}>
                      Yesterday
                    </button>
                  </div>
                  <div className="col-sm-4 mt-2 mt-md-0">
                    <button className="btn btn-outline-primary btn-block" onClick={handleThisMonth}>
                      This Month
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default FilterLayout;
