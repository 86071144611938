import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import {
  getBankAccounts,
  getBankDepositTransactions,
  getBankExpensesTransactions,
  getBankTransactions,
  getBankTransferTransactions
} from '../../../actions/bankings';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import {
  ACCOUNTS,
  DEPOSITS,
  EXPENSES,
  isNotAccountsHidden,
  TRANSACTIONS,
  TRANSFERS
} from '../../../permissions';
import { ACCOUNTS_SECTIONS } from '../../../utils';

function Accounts({
  isLoading,
  getBankTransactions,
  getBankDepositTransactions,
  getBankAccounts,
  getBankTransferTransactions,
  getBankExpensesTransactions,
  auth: { permissions },
  bankings: {
    bankAccounts,
    transactions,
    depositTransactions,
    transferTransactions,
    expenseTransactions
  }
}) {
  let history = useHistory();

  useEffect(() => {
    if (permissions.length > 0) {
      if (!isNotAccountsHidden(permissions)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getBankAccounts();
    getBankTransactions({ size: 5 });
    getBankDepositTransactions({ size: 5 });
    getBankTransferTransactions({ size: 5 });
    getBankExpensesTransactions({ size: 5 });
  }, []);

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/accounts'}>Accounts</Link>
      </li>
    </ol>
  );
  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={ACCOUNTS_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        {permissions.find((item) => item.name === ACCOUNTS) && (
          <div id="card-content">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5>New Bank Account</h5>
                  <p className={'mb-0'}>Add new bank accounts</p>
                </div>
                <div>
                  <Link
                    to={'/accounts/new'}
                    className="btn btn-primary p-2 rounded text-decoration-none"
                  >
                    <i className="far fa-plus-square me-2" /> Add Account
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row mt-4">
          {permissions.find((item) => item.name === ACCOUNTS) && (
            <div className="col-md-5 mb-4">
              <div id="card-content" className={'h-100'}>
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Bank Accounts</h5>
                      <p>Add & Manage your bank accounts</p>
                    </div>
                    <div>
                      <Link to={'/accounts/new'} className="btn btn-primary me-2">
                        Add
                      </Link>
                      <Link to={'/accounts/all'} className="btn btn-outline-primary">
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body mt-1">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Account Name</TableCell>
                          <TableCell>Account Type</TableCell>
                          <TableCell>Bank Name</TableCell>
                          <TableCell>Balance (৳)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {bankAccounts.slice(0, 5).map((row) => (
                          <TableRow key={row.id}>
                            <TableCell>{row.account_name}</TableCell>
                            <TableCell>{row.bank_account_type.name}</TableCell>
                            <TableCell>{row.bank_name}</TableCell>
                            <TableCell>{row.balance}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}

          {permissions.find((item) => item.name === DEPOSITS) && (
            <div className="col-md-7 mb-4">
              <div id="card-content" className={'h-100'}>
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Bank Account Deposits</h5>
                      <p>Add & Manage your bank deposits</p>
                    </div>
                    <div>
                      <Link to={'/accounts/deposits/new'} className="btn btn-primary me-2">
                        Add
                      </Link>
                      <Link to={'/accounts/deposits/all'} className="btn btn-outline-primary">
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body mt-1">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Account Name</TableCell>
                          <TableCell>Account Number</TableCell>
                          <TableCell>Bank Name</TableCell>
                          <TableCell>Amount (৳)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {depositTransactions &&
                          depositTransactions?.paginatedData.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell>{row.date}</TableCell>
                              <TableCell>{row.bank_account.account_name}</TableCell>
                              <TableCell>{row.bank_account.account_no}</TableCell>
                              <TableCell>{row.bank_account.bank_name}</TableCell>
                              <TableCell>{row.amount}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}

          {permissions.find((item) => item.name === TRANSFERS) && (
            <div className="col-md-7 mb-4">
              <div id="card-content" className={'h-100'}>
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Bank Account Transfers</h5>
                      <p>Add & Manage your bank transfers</p>
                    </div>
                    <div>
                      <Link to={'/accounts/transfers/new'} className="btn btn-primary me-2">
                        Add
                      </Link>
                      <Link to={'/accounts/transfers/all'} className="btn btn-outline-primary">
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body mt-1">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Account Name</TableCell>
                          <TableCell>Account Number</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Amount (৳)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {transferTransactions &&
                          transferTransactions?.paginatedData.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell>{row.date}</TableCell>
                              <TableCell>{row.bank_account.account_name}</TableCell>
                              <TableCell>{row.bank_account.account_no}</TableCell>
                              <TableCell>{row.description}</TableCell>
                              <TableCell>{row.amount}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}

          {permissions.find((item) => item.name === EXPENSES) && (
            <div className="col-md-5 mb-4">
              <div id="card-content" className={'h-100'}>
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Expenses</h5>
                      <p>Add & Manage your expenses</p>
                    </div>
                    <div>
                      <Link to={'accounts/expenses/new'} className="btn btn-primary me-2">
                        Add
                      </Link>
                      <Link to={'/accounts/expenses/all'} className="btn btn-outline-primary">
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body mt-1">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Account Name</TableCell>
                          <TableCell>Account Number</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Amount (৳)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {expenseTransactions &&
                          expenseTransactions?.paginatedData.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell>{row.bank_account.account_name}</TableCell>
                              <TableCell>{row.bank_account.account_no}</TableCell>
                              <TableCell>{row.description}</TableCell>
                              <TableCell>{Math.abs(row.amount)}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}

          {permissions.find((item) => item.name === TRANSACTIONS) && (
            <div className="col-md-12 mb-4">
              <div id="card-content" className={'h-100'}>
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Account Transactions</h5>
                      <p>See all the accounts transactions</p>
                    </div>
                    <div>
                      <Link to={'accounts/transactions'} className="btn btn-primary">
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body mt-1">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <caption>
                        <Link
                          to={'/accounts/transactions'}
                          className={'text-muted text-decoration-none'}
                        >
                          View All
                        </Link>
                      </caption>
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Account Name</TableCell>
                          <TableCell>Account Number</TableCell>
                          <TableCell>Bank Name</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Category</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Amount (৳)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {transactions &&
                          transactions?.paginatedData.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell>{row.date}</TableCell>
                              <TableCell>{row.bank_account.account_name}</TableCell>
                              <TableCell>{row.bank_account.account_no}</TableCell>
                              <TableCell>{row.bank_account.bank_name}</TableCell>
                              <TableCell>{row.trans_type}</TableCell>
                              <TableCell>
                                {row.income_expanse_category
                                  ? row.income_expanse_category.name
                                  : ''}
                              </TableCell>
                              <TableCell>{row.description}</TableCell>
                              <TableCell>{Math.abs(row.amount)}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

Accounts.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getBankAccounts: PropTypes.func.isRequired,
  getBankDepositTransactions: PropTypes.func.isRequired,
  getBankTransferTransactions: PropTypes.func.isRequired,
  getBankExpensesTransactions: PropTypes.func.isRequired,
  getBankTransactions: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  bankings: state.bankings,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getBankAccounts,
  getBankDepositTransactions,
  getBankTransferTransactions,
  getBankExpensesTransactions,
  getBankTransactions
})(Accounts);
