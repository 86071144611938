import React, { useState, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { login } from '../../actions/auth';

import { Button, CircularProgress, TextField } from '@material-ui/core';

const Login = ({ login, isAuthenticated, isLoading }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const { email, password } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };

  if (isAuthenticated) {
    return <Redirect to={'/home'} />;
  }

  return (
    <Fragment>
      <div className="row m-0" id={'login-register'}>
        <div className="col-6 logo-container">
          <div className="overlay" />
        </div>
        <div className="col-lg-6 login-wrapper p-0 bg-white d-flex align-items-center">
          <div className="login-card-wrapper m-auto">
            <React.Fragment>
              <h2>
                <strong>Login</strong>
              </h2>
              <p className={'text-muted'} style={{ fontSize: 14 }}>
                Manage your online and offline business with <strong>ECOM-ERP</strong>
              </p>

              <p className="mt-5 mb-0">
                <strong>Email*</strong>
              </p>

              <TextField
                id="email"
                name={'email'}
                value={email}
                onChange={(e) => onChange(e)}
                margin="normal"
                required
                placeholder="Enter your email"
                type="text"
                fullWidth
                variant={'outlined'}
              />
              <p className="mt-2 mb-0">
                <strong>Password*</strong>
              </p>

              <TextField
                id="password"
                value={password}
                name={'password'}
                onChange={(e) => onChange(e)}
                margin="normal"
                required
                placeholder="Enter password"
                type="password"
                variant={'outlined'}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') onSubmit(e);
                }}
                fullWidth
              />
              <div className="d-flex mt-3">
                {isLoading ? (
                  <CircularProgress color={'primary'} size={26} className="mx-auto" />
                ) : (
                  <Button
                    disabled={email.length === 0 || password.length === 0}
                    onClick={(e) => onSubmit(e)}
                    variant="contained"
                    size="large"
                    style={{
                      margin: 0,
                      backgroundColor: '#0052FF',
                      color: 'white',
                      textTransform: 'none',
                      width: '100%',
                      borderRadius: 4
                    }}
                  >
                    Login
                  </Button>
                )}
              </div>

              {/*<div>*/}
              {/*    <p className="me-2 mt-4 gray-login-text">Don't have an account?</p>*/}
              {/*    <Link to={'/register'}  style={{*/}
              {/*        textDecoration: "none",*/}
              {/*        fontWeight: 600*/}
              {/*    }}>*/}
              {/*        Create an Account*/}
              {/*    </Link>*/}
              {/*</div>*/}

              <p className={'text-muted copyright'} style={{ fontSize: 12 }}>
                ©2021 ECOM-ERP All rights reserved
              </p>
            </React.Fragment>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.auth.isLoading
});

export default connect(mapStateToProps, { login })(Login);
