import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import { getPurchaseDamageReturnList } from '../../../actions/purchase';
import moment from 'moment';
import MaterialTable from 'material-table';
import FilterLayout from '../../layout/FilterLayout';
import { PURCHASE_RETURN } from '../../../permissions';
import { PURCHASE_SECTIONS } from '../../../utils';

function PurchaseDamageReturnList({
  isLoading,

  auth: { permissions },
  getPurchaseDamageReturnList,
  purchase: { purchaseDamageReturns }
}) {
  const history = useHistory();
  const [from_date, setFromDate] = useState(
    moment(Date()).subtract(30, 'days').format('YYYY-MM-DD')
  );
  const [to_date, setToDate] = useState(moment(Date()).format('YYYY-MM-DD'));

  const handleHistory = () => {
    history.push('#');
  };

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === PURCHASE_RETURN)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getAllPurchaseDamageReturnList();
  }, []);

  const getAllPurchaseDamageReturnList = () => {
    getPurchaseDamageReturnList({ from_date, to_date });
  };

  const handlePurchaseDetails = (id) => {
    history.push({
      pathname: '/purchase/details',
      search: `?id=${id}`
    });
  };

  const handleSupplierDetails = (id) => {
    history.push({
      pathname: '/suppliers/details',
      search: `?id=${id}`
    });
  };

  const handlePrintPurchaseReturn = (saleId) => {
    history.push({
      pathname: '/purchase/damage-return-list/print',
      search: `?purchaseId=${saleId}`
    });
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/purchase'}>Purchase</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/purchase/damage-return-list'}>Damage Returns</Link>
      </li>
    </ol>
  );

  const tableHead = [
    {
      field: 'id',
      title: 'Inv.',
      cellStyle: { width: '5%' },
      render: (rowData) => `SRI-${rowData.id}`
    },
    {
      field: 'purchaseOrderId',
      title: 'Sale',
      cellStyle: { width: '5%' },
      render: (rowData) => (
        <span
          className={'font-weight-bold link-primary hover'}
          onClick={() => handlePurchaseDetails(rowData.purchaseOrderId)}
        >{`PO-${rowData.purchaseOrderId}`}</span>
      )
    },
    {
      field: 'createdAt',
      title: 'Date',
      render: (rowData) => {
        return moment(rowData.createdAt).format('YYYY-MM-DD');
      }
    },
    {
      field: 'purchase_order_detail.description',
      title: 'Description',
      cellStyle: { width: '20%' }
    },
    { field: 'reason', title: 'Reason' },
    {
      field: 'sale_order.supplier.name',
      title: 'S. Name',
      render: (rowData) => (
        <span
          className={'font-weight-bold link-primary hover'}
          onClick={() => handleSupplierDetails(rowData.purchase_order.supplierId)}
        >
          {rowData.purchase_order.supplier.name}
        </span>
      )
    },
    { field: 'purchase_order.supplier.phone', title: 'S. Phone' },
    { field: 'qty', title: 'Qty', type: 'numeric', cellStyle: { width: '5%' } },
    { field: 'total', title: 'Total (৳)', type: 'numeric' }
  ];

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={PURCHASE_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Purchase Damage Return List</h5>
                <p className={'mb-0'}>All purchase damage return list</p>
              </div>
            </div>
          </div>
        </div>

        <FilterLayout
          handleActions={getAllPurchaseDamageReturnList}
          setFromDate={setFromDate}
          setToDate={setToDate}
        />

        <div id="card-content" className={'mt-4'}>
          <div className={'overflow-auto'}>
            <MaterialTable
              title={'Damage Returns'}
              columns={tableHead}
              data={purchaseDamageReturns}
              isLoading={isLoading}
              totalCount={purchaseDamageReturns.length}
              options={{
                headerStyle: {
                  backgroundColor: '#F6F9FC',
                  color: '#000'
                },
                rowStyle: (rowData) => {
                  return {
                    fontSize: '14px',
                    color: '#000'
                  };
                },
                padding: 'default',
                pageSize: 20,
                actionsColumnIndex: -1,
                pageSizeOptions: [10, 25, 50],
                showFirstLastPageButtons: true,
                exportAllData: true,
                exportFileName: `Invoices - ${moment(new Date()).format('LL')}`,
                exportButton: true,
                searchFieldAlignment: 'left',
                searchFieldStyle: {
                  marginLeft: '3rem'
                }
              }}
              actions={[
                {
                  icon: 'refresh',
                  onClick: (event, rowData) => {},
                  isFreeAction: true,
                  tooltip: 'refresh'
                },
                (rowData) => ({
                  icon: 'print',
                  onClick: () => handlePrintPurchaseReturn(rowData.purchaseOrderId),
                  tooltip: 'Print All Returns'
                })
              ]}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

PurchaseDamageReturnList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getPurchaseDamageReturnList: PropTypes.func.isRequired,
  purchase: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  purchase: state.purchase,
  auth: state.auth
});

export default connect(mapStateToProps, { getPurchaseDamageReturnList })(PurchaseDamageReturnList);
