import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import {
  getDepartments,
  getDesignations,
  getEmployeeList,
  getSalaryHistory
} from '../../../actions/employee';
import moment from 'moment';
import {
  DEPARTMENTS,
  DESIGNATIONS,
  EMPLOYEE,
  isNotEmployeeHidden,
  SALARY_HISTORY
} from '../../../permissions';
import { EMPLOYEE_SECTIONS } from '../../../utils';

function Employee({
  isLoading,
  getEmployeeList,
  getDesignations,
  getDepartments,
  getSalaryHistory,
  auth: { permissions },
  employee: { employee, designations, departments, salaryHistory }
}) {
  const history = useHistory();

  useEffect(() => {
    if (permissions.length > 0) {
      if (!isNotEmployeeHidden(permissions)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getEmployeeList({ size: 5 });
    getDesignations({ size: 5 });
    getDepartments({ size: 5 });
    getSalaryHistory({ size: 5 });
  }, []);

  const handleEmployeeDetails = (id) => {
    history.push({
      pathname: '/employee/new',
      search: `?id=${id}`
    });
  };

  const handleDesignationDetails = (id) => {
    history.push({
      pathname: '/employee/designation/new',
      search: `?id=${id}`
    });
  };

  const handleDepartmentDetails = (id) => {
    history.push({
      pathname: '/employee/department/new',
      search: `?id=${id}`
    });
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/employee'}>Employee</Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={EMPLOYEE_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        {permissions.find((item) => item.name === EMPLOYEE) && (
          <div id="card-content">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5>New Employee Entry</h5>
                  <p className={'mb-0'}>Add a new employee</p>
                </div>
                <div>
                  <Link
                    to={'/employee/new'}
                    className="btn btn-primary p-2 rounded text-decoration-none"
                  >
                    <i className="far fa-plus-square me-2" /> Add Employee
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row mt-4">
          {permissions.find((item) => item.name === EMPLOYEE) && (
            <div className="col-md-7 mb-4">
              <div id="card-content" className={'h-100'}>
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>New Employees</h5>
                      <p>Recent employees</p>
                    </div>
                    <div>
                      <Link to={'/employee/new'} className="btn btn-primary me-2">
                        Add
                      </Link>
                      <Link to={'/employee/all'} className="btn btn-outline-primary">
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body mt-1">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Phone</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Designation</TableCell>
                          <TableCell>Department</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {employee &&
                          employee.paginatedData.map((row) => (
                            <TableRow
                              className={'hover'}
                              onClick={() => handleEmployeeDetails(row.id)}
                              key={row.id}
                            >
                              <TableCell>{row.name}</TableCell>
                              <TableCell>{row.phone}</TableCell>
                              <TableCell>{row.email}</TableCell>
                              <TableCell>{row.designation.name}</TableCell>
                              <TableCell>{row.department.name}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}

          {permissions.find((item) => item.name === DESIGNATIONS) && (
            <div className="col-md-5 mb-4">
              <div id="card-content" className={'h-100'}>
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Designations</h5>
                      <p>Employee designations</p>
                    </div>
                    <div>
                      <Link to={'/employee/designation/new'} className="btn btn-primary me-2">
                        Add
                      </Link>
                      <Link to={'/employee/designation/all'} className="btn btn-outline-primary">
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body mt-1">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>SL No</TableCell>
                          <TableCell>Name</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {designations &&
                          designations.paginatedData.map((row) => (
                            <TableRow
                              className={'hover'}
                              onClick={() => handleDesignationDetails(row.id)}
                              key={row.id}
                            >
                              <TableCell>{row.id}</TableCell>
                              <TableCell>{row.name}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}

          {permissions.find((item) => item.name === SALARY_HISTORY) && (
            <div className="col-md-7 mb-4">
              <div id="card-content" className={'h-100'}>
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Salary History</h5>
                      <p>Recent employee salary history</p>
                    </div>
                    <div>
                      <Link to={'/employee/salary'} className="btn btn-primary me-2">
                        Add
                      </Link>
                      <Link to={'/employee/salary/all'} className="btn btn-outline-primary">
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body mt-1">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Phone</TableCell>
                          <TableCell>Month</TableCell>
                          <TableCell>Amount</TableCell>
                          <TableCell>By</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {salaryHistory &&
                          salaryHistory.paginatedData.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell>{row.bank_tran.date}</TableCell>
                              <TableCell>{row.employee.name}</TableCell>
                              <TableCell>{row.employee.phone}</TableCell>
                              <TableCell>{moment(row.bank_tran.date).format('MMM')}</TableCell>
                              <TableCell>{row.amount}</TableCell>
                              <TableCell>{row.user.username}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}

          {permissions.find((item) => item.name === DEPARTMENTS) && (
            <div className="col-md-5 mb-4">
              <div id="card-content" className={'h-100'}>
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Departments</h5>
                      <p>Employee departments</p>
                    </div>
                    <div>
                      <Link to={'/employee/department/new'} className="btn btn-primary me-2">
                        Add
                      </Link>
                      <Link to={'/employee/department/all'} className="btn btn-outline-primary">
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body mt-1">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>SL No</TableCell>
                          <TableCell>Name</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {departments &&
                          departments.paginatedData.map((row) => (
                            <TableRow
                              className={'hover'}
                              onClick={() => handleDepartmentDetails(row.id)}
                              key={row.id}
                            >
                              <TableCell>{row.id}</TableCell>
                              <TableCell>{row.name}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

Employee.propTypes = {
  getEmployeeList: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  employee: state.employee,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getEmployeeList,
  getDepartments,
  getDesignations,
  getSalaryHistory
})(Employee);
