import {
  BANK_ACCOUNTS_DASH,
  DASHBOARD,
  INVENTORY_STATISTICS,
  LOADING,
  LOADING_FALSE,
  LOW_STOCK,
  MOST_SOLD,
  POPULAR_CATEGORIES
} from './types';
import API from '../api';
import { returnErrors } from './messages';

export const getDashboardStatistics = () => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.dashboard.getStatistics();
    dispatch({ type: LOADING_FALSE });

    dispatch({
      type: DASHBOARD,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getLowStockProducts =
  ({ size }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.dashboard.getLowStockProducts({ size });
      dispatch({ type: LOADING_FALSE });

      dispatch({
        type: LOW_STOCK,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getMostSoldProducts =
  ({ size }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.dashboard.getMostSoldProducts({ size });
      dispatch({ type: LOADING_FALSE });

      dispatch({
        type: MOST_SOLD,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getBankAccountsDashboard =
  ({ size }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.dashboard.getBankAccounts({ size });
      dispatch({ type: LOADING_FALSE });

      dispatch({
        type: BANK_ACCOUNTS_DASH,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getPopularCategories =
  ({ size }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.dashboard.getPopularCategories({ size });
      dispatch({ type: LOADING_FALSE });

      dispatch({
        type: POPULAR_CATEGORIES,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getInventoryStatistics = () => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.dashboard.getInventoryStatistics();
    dispatch({ type: LOADING_FALSE });

    dispatch({
      type: INVENTORY_STATISTICS,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};
