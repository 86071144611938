import { ALL_CUSTOMER_PAYMENTS, CUSTOMER, CUSTOMER_PAYMENTS, CUSTOMERS } from '../actions/types';

const initialState = {
  customers: null,
  customerPayments: null,
  allCustomerPayments: null,
  customer: null
};

export default function (state = initialState, action) {
  const payload = action.payload;
  switch (action.type) {
    case CUSTOMERS:
      return {
        ...state,
        customers: payload
      };

    case CUSTOMER_PAYMENTS:
      return {
        ...state,
        customerPayments: payload
      };

    case CUSTOMER:
      return {
        ...state,
        customer: payload
      };

    case ALL_CUSTOMER_PAYMENTS:
      return {
        ...state,
        allCustomerPayments: payload
      };
    default:
      return state;
  }
}
