import React, { Fragment } from 'react';

function GeneralSupplierLayout({
  setFormData,
  formData,
  supplierName,
  supplierPhone,
  supplierAddress
}) {
  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  return (
    <Fragment>
      <div className="form-group mt-2">
        <div className="row d-flex align-items-center">
          <div className="col-4 col-lg-3">
            <label className={'m-md-0 mb-1'} htmlFor={'supplierName'}>
              Name
            </label>
          </div>
          <div className="col-8 col-lg-9">
            <input
              type="text"
              name={'supplierName'}
              value={supplierName}
              onChange={(e) => onChange(e)}
              className="form-control input-sm"
              placeholder={'Enter supplier name'}
            />
          </div>
        </div>
      </div>

      <div className="form-group mt-2">
        <div className="row d-flex align-items-center">
          <div className="col-4 col-lg-3">
            <label className={'m-md-0 mb-1'} htmlFor={'supplierPhone'}>
              Phone
            </label>
          </div>
          <div className="col-8 col-lg-9">
            <input
              type="text"
              name={'supplierPhone'}
              value={supplierPhone}
              onChange={(e) => onChange(e)}
              className="form-control input-sm"
              placeholder={'Enter supplier phone'}
            />
          </div>
        </div>
      </div>

      <div className="form-group mt-2">
        <div className="row d-flex align-items-center">
          <div className="col-4 col-lg-3">
            <label className={'m-md-0 mb-1'} htmlFor={'supplierAddress'}>
              Address
            </label>
          </div>
          <div className="col-8 col-lg-9">
            <input
              type="text"
              name={'supplierAddress'}
              value={supplierAddress}
              onChange={(e) => onChange(e)}
              className="form-control input-sm"
              placeholder={'Enter supplier address'}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default GeneralSupplierLayout;
