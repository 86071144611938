import React from 'react';
import MaterialTable from 'material-table';

function CartsTable({ tableHead, cartsItem, deleteItemsFromCart }) {
  return (
    <div id="card-content" className={'mt-3 d-grid'}>
      <div style={{ overflow: 'auto' }} className={'sales-table'}>
        <MaterialTable
          columns={tableHead}
          data={cartsItem}
          totalCount={cartsItem.length}
          options={{
            toolbar: false,
            exportButton: false,
            showTitle: false,
            headerStyle: {
              backgroundColor: '#F5F7FA'
            },
            padding: 'dense',
            pageSize: 8,
            pageSizeOptions: [10, 15, 25, 50, 100],
            showFirstLastPageButtons: true,
            exportAllData: false,
            search: false,
            showEmptyDataSourceMessage: '',
            hideFilterIcons: true,
            actionsColumnIndex: -1
          }}
          actions={[
            {
              icon: 'delete_outline',
              tooltip: 'Delete',
              onClick: (event, oldData) => {
                deleteItemsFromCart(oldData.id, oldData.s_code, oldData.total);
              }
            }
          ]}
        />
      </div>
    </div>
  );
}

export default CartsTable;
