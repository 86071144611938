import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import MaterialTable from 'material-table';
import moment from 'moment';
import { getPendingSales } from '../../../actions/sale';
import FilterLayout from '../../layout/FilterLayout';
import { isNotPackagingHidden } from '../../../permissions';

function Packaging({ isLoading, getPendingSales, sale: { pendingSales }, auth: { permissions } }) {
  const history = useHistory();
  const [from_date, setFromDate] = useState(
    moment(Date()).subtract(30, 'days').format('YYYY-MM-DD')
  );
  const [to_date, setToDate] = useState(moment(Date()).format('YYYY-MM-DD'));

  useEffect(() => {
    getAllPendingSales();
  }, []);

  useEffect(() => {
    if (permissions.length > 0) {
      if (!isNotPackagingHidden(permissions)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  const getAllPendingSales = () => {
    getPendingSales({ from_date, to_date });
  };

  const handleHistory = () => {
    history.push('#');
  };

  const handlePackagingDetails = (id, isExchange = false) => {
    history.push({
      pathname: '/packaging/details',
      search: `?id=${id}&&is_exchange=${isExchange}`
    });
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/packaging'}>Packaging</Link>
      </li>
    </ol>
  );

  const tableHead = [
    {
      field: 'id',
      title: 'Inv. No',
      render: (rowData) => (rowData.saleOrderId ? rowData.saleOrderId : rowData.id)
    },
    {
      field: 'id',
      title: 'Exchange Inv.',
      render: (rowData) => (rowData.saleOrderId ? rowData.id : '-')
    },
    { field: 'date', title: 'Date' },
    { field: 'customer.name', title: 'Customer Name' },
    { field: 'customer.phone', title: 'Phone' },
    { field: 'total', title: 'Total (৳)' },
    {
      field: 'status.name',
      title: 'Status',
      render: (rowData) => (
        <span className={rowData.status.name.toLowerCase()}>{rowData.status.name}</span>
      )
    }
  ];

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} />

      <div className="container-fluid mt-4">
        <FilterLayout
          handleActions={getAllPendingSales}
          setFromDate={setFromDate}
          setToDate={setToDate}
        />

        {pendingSales && (
          <div id="card-content" className={'mt-4'}>
            <div className={'overflow-auto'}>
              <MaterialTable
                title={'Pending & Packed Sales'}
                columns={tableHead}
                data={pendingSales}
                isLoading={isLoading}
                totalCount={pendingSales.length}
                options={{
                  headerStyle: {
                    backgroundColor: '#F6F9FC',
                    color: '#000'
                  },
                  rowStyle: (rowData) => {
                    return {
                      fontSize: '14px',
                      color: '#000'
                    };
                  },
                  padding: 'default',
                  pageSize: 20,
                  actionsColumnIndex: -1,
                  pageSizeOptions: [10, 25, 50],
                  showFirstLastPageButtons: true,
                  exportAllData: true,
                  exportFileName: `Pending sales - ${moment(new Date()).format('LL')}`,
                  exportButton: true,
                  searchFieldAlignment: 'left',
                  searchFieldStyle: {
                    marginLeft: '3rem'
                  }
                }}
                onRowClick={(event, rowData) =>
                  handlePackagingDetails(rowData.id, !!rowData.saleOrderId)
                }
                actions={[
                  {
                    icon: 'refresh',
                    onClick: (event, rowData) => {},
                    isFreeAction: true,
                    tooltip: 'refresh'
                  },
                  (rowData) => ({
                    icon: 'visibility_on',
                    onClick: () => handlePackagingDetails(rowData.id, !!rowData.saleOrderId),
                    isFreeAction: true,
                    tooltip: 'Packaging details'
                  })
                ]}
              />
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}

Packaging.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getPendingSales: PropTypes.func.isRequired,
  sale: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  sale: state.sale,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getPendingSales
})(Packaging);
