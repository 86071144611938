import React, { Fragment } from 'react';

function GeneralCustomerLayout({
  setFormData,
  formData,
  customerName,
  customerPhone,
  customerAddress
}) {
  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  return (
    <Fragment>
      <div className="form-group mt-2">
        <div className="row d-flex align-items-center">
          <div className="col-4 col-lg-3">
            <label className={'m-md-0 mb-1'} htmlFor={'customerName'}>
              Name
            </label>
          </div>
          <div className="col-8 col-lg-9">
            <input
              type="text"
              name={'customerName'}
              value={customerName}
              onChange={(e) => onChange(e)}
              className="form-control input-sm"
              placeholder={'Enter customer name'}
            />
          </div>
        </div>
      </div>

      <div className="form-group mt-2">
        <div className="row d-flex align-items-center">
          <div className="col-4 col-lg-3">
            <label className={'m-md-0 mb-1'} htmlFor={'customerPhone'}>
              Phone
            </label>
          </div>
          <div className="col-8 col-lg-9">
            <input
              type="text"
              name={'customerPhone'}
              value={customerPhone}
              onChange={(e) => onChange(e)}
              className="form-control input-sm"
              placeholder={'Enter customer phone'}
            />
          </div>
        </div>
      </div>

      <div className="form-group mt-2">
        <div className="row d-flex align-items-center">
          <div className="col-4 col-lg-3">
            <label className={'m-md-0 mb-1'} htmlFor={'customerAddress'}>
              Address
            </label>
          </div>
          <div className="col-8 col-lg-9">
            <input
              type="text"
              name={'customerAddress'}
              value={customerAddress}
              onChange={(e) => onChange(e)}
              className="form-control input-sm"
              placeholder={'Enter customer address'}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default GeneralCustomerLayout;
