import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import moment from 'moment';
import { getIncomeReports } from '../../../actions/reports';
import { DatePicker } from '@material-ui/pickers';
import { INCOME_REPORTS } from '../../../permissions';
import { REPORTS_SECTIONS } from '../../../utils';

function IncomeReports({
  isLoading,
  getIncomeReports,
  auth: { permissions },
  reports: { incomeReports }
}) {
  const [year, handleYearChange] = useState(new Date());
  let history = useHistory();

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === INCOME_REPORTS)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getIncomeReports({ year: moment(year).year() });
  }, [year]);

  const componentRef = useRef();

  let temp = '';
  if (!isLoading) {
    for (const incomeReport of incomeReports) {
      temp += `<tr>`;
      temp += `<td>${incomeReport.name}</td>`;

      const allMonths = [];
      for (let i = 1; i <= 12; i++) {
        const newMonth = {
          amount: 0,
          month: i
        };
        allMonths.push(newMonth);
      }

      for (const demoMonth of allMonths) {
        for (const month of incomeReport.months) {
          if (demoMonth.month === month.month) {
            const index = allMonths.indexOf(demoMonth);
            allMonths[index] = month;
          }
        }
      }

      for (const month of allMonths) {
        temp += `<td>${month.amount}</td>`;
      }
    }
  }

  //add table row markup to the tbody
  function createMarkup() {
    return { __html: temp };
  }

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/reports'}>Reports</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/reports/income'}>Income Reports</Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={REPORTS_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            <div>
              <h5>Income Reports</h5>

              <div className={'text-center'}>
                <DatePicker
                  views={['year']}
                  label="Select a Year"
                  value={year}
                  onChange={handleYearChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div id="card-content" className={'mt-4'} ref={componentRef}>
          <div className="card-body">
            <div className={'row'}>
              <div className="col-md-12">
                <table className="table table-bordered table-dark table-striped">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Jan (৳)</th>
                      <th>Fab (৳)</th>
                      <th>Mar (৳)</th>
                      <th>Apr (৳)</th>
                      <th>May (৳)</th>
                      <th>Jun (৳)</th>
                      <th>Jul (৳)</th>
                      <th>Aug (৳)</th>
                      <th>Sep (৳)</th>
                      <th>Oct (৳)</th>
                      <th>Nov (৳)</th>
                      <th>Dec (৳)</th>
                    </tr>
                  </thead>
                  <tbody id={'data'} dangerouslySetInnerHTML={createMarkup()}></tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

IncomeReports.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getIncomeReports: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  reports: state.reports,
  auth: state.auth
});

export default connect(mapStateToProps, { getIncomeReports })(IncomeReports);
