import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import ReactToPrint from 'react-to-print';
import { getSaleDetails } from '../../../actions/sale';
import MaterialTable from 'material-table';
import numWords from 'num-words';
import { getCompany } from '../../../actions/system';
import { getFullAddress, REGEX_STRING, SALE_SECTIONS } from '../../../utils';

import demo from '../../../assets/icons/user.png';
import { base_url_image } from '../../../api';

function PrintSaleInvoice({
  isLoading,
  sale: { saleDetails },
  system: { company },
  getSaleDetails,
  location,
  getCompany
}) {
  const [officeData, setOfficeData] = useState([]);
  const componentRef = useRef();
  const history = useHistory();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const id = query.get('id');

    if (id) {
      getSaleDetails(id);
    }

    getCompany();
  }, []);

  useEffect(() => {
    document.getElementById('print-button')?.click();
  }, [componentRef.current]);

  useEffect(() => {
    getOfficeTableData();
  }, [saleDetails]);

  const goBackButton = (e) => {
    e.preventDefault();

    history.goBack();
  };

  const getOfficeTableData = () => {
    let codes = [];
    let totalQty = 0;
    let discount = 0;
    let total = 0.0;
    let deliveryFee = 0.0;

    if (saleDetails) {
      for (const orderItem of saleDetails.saleOrderItems) {
        codes.push(orderItem.item.code);
        totalQty += parseInt(orderItem.qty);
        total += parseFloat(orderItem.total);
        discount += parseFloat(orderItem.discount);
      }
      deliveryFee = saleDetails.transport_cost;
    }

    setOfficeData([
      {
        codes: codes.join(),
        totalQty,
        discount: discount.toFixed(2),
        total: total.toFixed(2),
        deliveryFee
      }
    ]);
  };

  const tableHead = [
    {
      field: 'tableData.id',
      title: 'SL',
      cellStyle: {
        width: '5%'
      },
      render: (rowData) => {
        return rowData.tableData.id + 1;
      }
    },
    {
      field: 'description',
      title: 'Description',
      cellStyle: {
        width: '35%'
      },
      render: (rowData) => {
        return rowData.description.includes('(') ? (
          <p className={'m-0'}>
            {rowData.description.split('(')[0]} (
            <span className={'font-weight-bold'}>{REGEX_STRING.exec(rowData.description)[1]}</span>)
          </p>
        ) : (
          rowData.description
        );
      }
    },
    { field: 'item.code', title: 'Code' },
    {
      field: 'unit_price',
      title: 'Price'
    },
    {
      field: 'discount',
      title: 'Discount (%)'
    },
    {
      field: 'qty',
      title: 'Qty'
    },
    {
      field: 'total',
      title: 'Total (৳)'
    }
  ];

  const officeTableHead = [
    {
      field: 'tableData.id',
      title: 'SL',
      cellStyle: {
        width: '5%'
      },
      render: (rowData) => {
        return rowData.tableData.id + 1;
      }
    },
    {
      field: 'codes',
      title: 'Product Codes',
      cellStyle: {
        width: '30%'
      }
    },
    { field: 'totalQty', title: 'Total Qty' },
    { field: 'discount', title: 'Discount (%)' },
    { field: 'deliveryFee', title: 'Delivery Fee' },
    { field: 'total', title: 'Total (৳)' }
  ];

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/sale'}>Sale</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/sale/all'}>Invoices</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/sale/print'}>Print</Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={SALE_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      {company && saleDetails && (
        <div className="container-fluid mt-4">
          <div id="card-content">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5>Print Sale Invoice</h5>
                </div>
                <div>
                  <ReactToPrint
                    trigger={() => (
                      <button id={'print-button'} className="btn btn-primary me-2">
                        <i className="fas fa-print me-2" /> Print
                      </button>
                    )}
                    content={() => componentRef.current}
                  />

                  <button className="btn btn-outline-primary" onClick={goBackButton}>
                    Go Back
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div id="card-content" className={'mt-4 container p-5'} ref={componentRef}>
            <div className="customer-copy">
              <div className="d-flex justify-content-between align-items-center">
                <img
                  src={company ? base_url_image + company.logo : demo}
                  className={'invoice-logo'}
                  alt="logo"
                  width={80}
                />

                <div className={'text-end'}>
                  <h5>{company.name}</h5>
                  <p className={'mb-1'}>{getFullAddress(company.address)}</p>
                  <p className={'mb-0'}>
                    <span className={'font-weight-bold'}>Mobile:</span> {company.phone}
                    {company.phone2 && `, ${company.phone2}`}
                  </p>
                </div>
              </div>

              <div className="divider" />

              <div className="d-flex justify-content-between align-items-center mt-3">
                <div>
                  <h6 className={'bg-body ps-2 p-1'}>BILL TO</h6>
                  <div className={'ps-2'}>
                    <p className={'mb-1'}>
                      <span className="font-weight-bold">Name:</span> {saleDetails.customer.name}
                    </p>
                    <p className={'mb-1'}>
                      <span className="font-weight-bold">Mobile:</span> {saleDetails.customer.phone}
                    </p>
                    <p className={'mb-0'}>
                      <span className="font-weight-bold">Address:</span>{' '}
                      {saleDetails.customer.address}
                    </p>
                  </div>
                </div>

                <div className={'text-end'}>
                  <h4>Invoice #INV-{saleDetails.id}</h4>
                  <h6>Date: {saleDetails.date}</h6>
                </div>
              </div>

              <div className={'mt-4 d-grid'}>
                <div style={{ overflow: 'auto' }}>
                  <MaterialTable
                    columns={tableHead}
                    data={saleDetails?.saleOrderItems}
                    totalCount={saleDetails?.saleOrderItems.length}
                    options={{
                      toolbar: false,
                      exportButton: false,
                      showTitle: false,
                      headerStyle: {
                        padding: 4,
                        backgroundColor: '#F5F7FA'
                      },
                      padding: 'default',
                      pageSize: saleDetails?.saleOrderItems.length,
                      paging: false,
                      actionsColumnIndex: -1
                    }}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-end gap-3 mt-4 align-items-center">
                <p>
                  <span className="font-weight-bold">Sub Total:</span>{' '}
                  {saleDetails ? saleDetails.sub_total : 0} Tk
                </p>
                <p>
                  <span className="font-weight-bold">Discount:</span>{' '}
                  {saleDetails ? saleDetails.discount : 0} Tk
                </p>
                <p>
                  <span className="font-weight-bold">Shipping:</span> {saleDetails.transport_cost}{' '}
                  Tk
                </p>
                <p>
                  <span className="font-weight-bold">Total:</span>{' '}
                  {saleDetails ? saleDetails.total : 0} Tk
                </p>
                <p>
                  <span className="font-weight-bold">Paid:</span>{' '}
                  {saleDetails ? saleDetails.paid : 0} Tk
                </p>
                <p style={{ fontSize: '16px' }}>
                  <span className="font-weight-bold">
                    Due: {saleDetails ? saleDetails.due : 0} Tk
                  </span>
                </p>
              </div>

              <div className={'bg-body p-2'}>
                <p className={'mb-0 font-weight-bold'} style={{ fontSize: '16px' }}>
                  <strong>Notes: </strong>
                  {saleDetails ? saleDetails.notes : ''}
                </p>
              </div>
            </div>

            <div className="dotted-divider" />

            <div className="office-copy mt-4">
              <h6 className={'text-center'}>OFFICE COPY</h6>
              <div className="d-flex justify-content-between align-items-center">
                <img
                  src={company ? base_url_image + company.logo : demo}
                  className={'invoice-logo'}
                  alt="logo"
                  width={80}
                />

                <div className={'text-end'}>
                  <h6 className={'bg-body ps-2 p-1'}>BILL TO</h6>
                  <div className={'ps-2'}>
                    <p className={'mb-1'}>
                      <span className="font-weight-bold">Name:</span> {saleDetails.customer.name}
                    </p>
                    <p className={'mb-1'}>
                      <span className="font-weight-bold">Mobile:</span> {saleDetails.customer.phone}
                    </p>
                    <p className={'mb-0'}>
                      <span className="font-weight-bold">Address:</span>{' '}
                      {saleDetails.customer.address}
                    </p>
                  </div>
                </div>
              </div>

              <div className="divider" />

              <div className="d-flex justify-content-between align-items-center mt-3">
                <div className={'ps-2'}>
                  <h5>{company.name}</h5>
                  <p className={'mb-1'}>{getFullAddress(company.address)}</p>
                  <p className={'mb-0'}>
                    <span className={'font-weight-bold'}>Mobile:</span> {company.phone}
                    {company.phone2 && `, ${company.phone2}`}
                  </p>
                </div>

                <div className={'text-end'}>
                  <h4>Invoice #INV-{saleDetails.id}</h4>
                  <h6>Date: {saleDetails.date}</h6>
                </div>
              </div>

              <div className={'mt-4 d-grid'}>
                <div style={{ overflow: 'auto' }}>
                  <MaterialTable
                    columns={officeTableHead}
                    data={officeData}
                    totalCount={officeData.length}
                    options={{
                      toolbar: false,
                      exportButton: false,
                      showTitle: false,
                      headerStyle: {
                        padding: 4,
                        backgroundColor: '#F5F7FA'
                      },
                      padding: 'default',
                      pageSize: officeData.length,
                      paging: false,
                      actionsColumnIndex: -1
                    }}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-end gap-3 mt-4 align-items-center">
                <p>
                  <span className="font-weight-bold">Total:</span>{' '}
                  {saleDetails ? saleDetails.total : 0} Tk
                </p>
                <p>
                  <span className="font-weight-bold">Discount:</span>{' '}
                  {saleDetails ? saleDetails.discount : 0} Tk
                </p>
                <p>
                  <span className="font-weight-bold">Paid:</span>{' '}
                  {saleDetails ? saleDetails.paid : 0} Tk
                </p>
                <p style={{ fontSize: '16px' }}>
                  <span className="font-weight-bold">
                    Due: {saleDetails ? saleDetails.due : 0} Tk
                  </span>
                </p>
              </div>

              <div className={'bg-body p-2'}>
                <p className={'mb-0 font-weight-bold'} style={{ fontSize: '16px' }}>
                  <strong>Notes: </strong>
                  {saleDetails ? saleDetails.notes : ''}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

PrintSaleInvoice.propTypes = {
  isLoading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  sale: state.sale,
  system: state.system
});

export default connect(mapStateToProps, {
  getSaleDetails,
  getCompany
})(PrintSaleInvoice);
