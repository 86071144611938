import React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { GENERAL_SUPPLIER } from '../../const';

function SupplierSelector({
  setFormData,
  formData,
  suppliers,
  setGeneralSupplierSelected,
  supplierId = null
}) {
  const onSupplierSelected = (e, value) => {
    e.preventDefault();

    if (value) {
      if (value.id === GENERAL_SUPPLIER) {
        setGeneralSupplierSelected(true);
      } else {
        setGeneralSupplierSelected(false);
      }

      setFormData({
        ...formData,
        supplierId: value.id,
        supplierPreDue: value.due
      });
    }
  };

  return (
    <Autocomplete
      onChange={(event, value) => onSupplierSelected(event, value)}
      options={suppliers}
      renderOption={(option) => `${option.name} - ${option.phone}`}
      getOptionLabel={(option) => {
        // e.g value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        return `${option.name} - ${option.phone}`;
      }}
      defaultValue={supplierId && suppliers.find((item) => item.id === supplierId)}
      renderInput={(params) => (
        <TextField
          {...params}
          size={'small'}
          placeholder={'Select Supplier'}
          required
          variant="outlined"
        />
      )}
    />
  );
}

export default SupplierSelector;
