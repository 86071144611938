import { axios, base_url, headers } from '../api';

const qs = require('qs');

const addPurchase = async (data) => {
  return await axios.post(`${base_url}/purchase/new`, qs.stringify(data), {
    headers: headers()
  });
};

const updatePurchase = async (purchaseId, data) => {
  return await axios.put(`${base_url}/purchase/${purchaseId}/update`, qs.stringify(data), {
    headers: headers()
  });
};

const deletePurchaseItem = async (purchaseItemId) => {
  return await axios.delete(`${base_url}/purchase/purchase-item/${purchaseItemId}`, {
    headers: headers()
  });
};

const addPurchaseItem = async (purchaseId, data) => {
  return await axios.post(`${base_url}/purchase/${purchaseId}/add-item`, qs.stringify(data), {
    headers: headers()
  });
};

const updatePurchaseItem = async (purchaseId, data) => {
  return await axios.put(`${base_url}/purchase/${purchaseId}/update-item`, qs.stringify(data), {
    headers: headers()
  });
};

const getPurchaseList = async (data) => {
  return await axios.post(`${base_url}/purchase/`, qs.stringify(data), {
    headers: headers()
  });
};

const getPurchaseDetails = async (id) => {
  return await axios.get(`${base_url}/purchase/${id}`, {
    headers: headers()
  });
};

const cancelPurchase = async (id) => {
  return await axios.post(`${base_url}/purchase/${id}/cancel`, {
    headers: headers()
  });
};

const addPurchaseReturn = async (id, data) => {
  return await axios.post(`${base_url}/purchase/${id}/return`, qs.stringify(data), {
    headers: headers()
  });
};

const getPurchaseReturnList = async (data) => {
  return await axios.post(`${base_url}/purchase/return`, qs.stringify(data), {
    headers: headers()
  });
};

const getPurchaseDamageReturnList = async (data) => {
  return await axios.post(`${base_url}/purchase/damage-return`, qs.stringify(data), {
    headers: headers()
  });
};

const getPurchaseReturnsDetails = async (purchaseId) => {
  return await axios.get(`${base_url}/purchase/return/${purchaseId}`, {
    headers: headers()
  });
};

const getPurchaseDamageReturnsDetails = async (purchaseId) => {
  return await axios.get(`${base_url}/purchase/damage-return/${purchaseId}`, {
    headers: headers()
  });
};

const addPurchaseOrderPayment = async (data) => {
  return await axios.post(`${base_url}/purchase/${data.id}/pay-purchase/`, qs.stringify(data), {
    headers: headers()
  });
};

export default {
  addPurchase,
  addPurchaseItem,
  getPurchaseList,
  getPurchaseDetails,
  addPurchaseReturn,
  getPurchaseReturnList,
  addPurchaseOrderPayment,
  deletePurchaseItem,
  updatePurchaseItem,
  updatePurchase,
  cancelPurchase,
  getPurchaseReturnsDetails,
  getPurchaseDamageReturnList,
  getPurchaseDamageReturnsDetails
};
