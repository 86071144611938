import React, { Fragment, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import { getAccounts } from '../../../actions/accounts';
import { Link, useHistory } from 'react-router-dom';
import { getCompany, getRoles, updateCompanyLogo } from '../../../actions/system';

import demo from '../../../assets/icons/user.png';
import {
  COMPANY_INFORMATIONS,
  isNotAdminHidden,
  ROLES,
  USER_ACCESS,
  USERS
} from '../../../permissions';
import { base_url_image } from '../../../api';
import { getLoggedUserPermissions } from '../../../actions/auth';
import { ADMINISTRATION_SECTIONS } from '../../../utils';

function Administration({
  isLoading,
  getAccounts,
  getCompany,
  getRoles,
  updateCompanyLogo,
  getLoggedUserPermissions,
  auth: { permissions, user },
  system: { roles, company },
  accounts: { accounts }
}) {
  const history = useHistory();
  const inputFile = useRef(null);

  useEffect(() => {
    if (permissions.length > 0) {
      if (!isNotAdminHidden(permissions)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getAccounts();
    getCompany();
    getRoles();
  }, []);

  useEffect(() => {
    if (user) {
      getLoggedUserPermissions(user.id);
    }
  }, [user]);

  const handleClickToUploadImage = () => {
    inputFile.current.click();
  };

  const selectCompanyImage = (e) => {
    const imageFile = e.target.files[0];
    if (!imageFile) {
      return;
    }

    if (imageFile.type && imageFile.type.indexOf('image') === -1) {
      console.log('File is not an image.', imageFile.type, imageFile);
      return;
    }

    console.log(company.id);
    updateCompanyLogo(company.id, imageFile);

    // const img = document.getElementById("companyImage");
    // const reader = new FileReader();
    // reader.onload = function (event) {
    //     img.src = event.target.result;
    // }
    // reader.readAsDataURL(imageFile);
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/administration'}>Administration</Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={ADMINISTRATION_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <div className="row">
          {permissions.find((item) => item.name === USERS) ||
          permissions.find((item) => item.name === ROLES) ? (
            <div className="col-md-5">
              {permissions.find((item) => item.name === USERS) && (
                <div id="card-content" className={'mb-4'}>
                  <div className="card-header">
                    <div className="d-flex justify-content-between">
                      <div>
                        <h5>Users</h5>
                        <p>Create and manage users</p>
                      </div>
                      <div>
                        <Link to={'/administration/add-user'} className="btn btn-primary me-2">
                          Add
                        </Link>
                        <Link to={'/administration/users'} className="btn btn-outline-primary">
                          View All
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <ul className="list-group ">
                      {accounts.slice(0, 3).map((account) => (
                        <li className="list-group-item d-flex align-items-center justify-content-between ">
                          <div className={'d-flex'}>
                            <img className={'me-3'} src={demo} width={40} height={40} alt="" />
                            <div>
                              <h6>{account.username}</h6>
                              <p className={'m-0'}>{account.email}</p>
                            </div>
                          </div>
                          {permissions.find((item) => item.name === USER_ACCESS) && (
                            <Link
                              to={{
                                pathname: '/administration/access',
                                search: `?user=${account.id}`
                              }}
                              className="text-decoration-none font-weight-bold"
                            >
                              View Access <i className="ms-1 fas fa-long-arrow-alt-right" />
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              {permissions.find((item) => item.name === ROLES) && (
                <div id="card-content" className={'mb-4'}>
                  <div className="card-header">
                    <div className="d-flex justify-content-between">
                      <div>
                        <h5>Roles</h5>
                        <p>Create and manage roles</p>
                      </div>
                      <div>
                        <Link to={'/administration/add-role'} className="btn btn-primary me-2">
                          Add
                        </Link>
                        <Link to={'/administration/roles'} className="btn btn-outline-primary">
                          View All
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <ul className="list-group ">
                      {roles.slice(0, 3).map((role) => (
                        <li className="list-group-item d-flex align-items-center justify-content-between ">
                          <div>
                            <h6>{role.name}</h6>
                            <p className={'m-0'}>{role.description}</p>
                          </div>
                          {permissions.find((item) => item.name === USER_ACCESS) && (
                            <Link
                              to={{
                                pathname: '/administration/access',
                                search: `?role=${role.id}`
                              }}
                              className="text-decoration-none font-weight-bold"
                            >
                              View Access <i className="ms-1 fas fa-long-arrow-alt-right" />
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          ) : null}

          {permissions.find((item) => item.name === COMPANY_INFORMATIONS) && (
            <div className="col-md-7">
              <div id="card-content">
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Company Information</h5>
                      <p>Manage your company information. Keep your company info up to date.</p>
                    </div>
                    <div>
                      {company ? (
                        <Link to={'/administration/company-profile'} className="btn btn-primary">
                          Update company profile
                        </Link>
                      ) : (
                        <Link to={'/administration/company-profile'} className="btn btn-primary">
                          Create company profile
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card-body mt-4 d-flex justify-content-between">
                  <div>
                    <div className={'company-logo'} onClick={handleClickToUploadImage}>
                      <img
                        src={company ? base_url_image + company.logo : demo}
                        id={'companyImage'}
                        alt=""
                      />

                      <div className={'bottom-logo-section'}>
                        <p className={'mb-0'}>Upload</p>
                      </div>
                    </div>
                    <input
                      ref={inputFile}
                      style={{ display: 'none' }}
                      type="file"
                      id="image"
                      accept="image/jpeg, image/png"
                      onChange={selectCompanyImage}
                    />
                  </div>

                  <div className={'w-100 ms-5'}>
                    {company && (
                      <Fragment>
                        <h5>{company.name}</h5>
                        <p className={'mb-1'}>{company.tagline}</p>
                        <br />
                        <h6>Information</h6>
                        <p className={'mb-1'}>
                          <span style={{ fontWeight: 'bold' }}>Phone: </span>
                          {company.phone}
                        </p>
                        {company.phone2 && (
                          <p className={'mb-1'}>
                            <span style={{ fontWeight: 'bold' }}>Secondary Phone: </span>
                            {company.phone2}
                          </p>
                        )}
                        <p className={'mb-1'}>
                          <span style={{ fontWeight: 'bold' }}>Size: </span>
                          {company.size}
                        </p>

                        <h6 className={'mt-3 mb-1'}>Address</h6>
                        {company.address ? (
                          <p>
                            {company.address.division}, {company.address.city},{' '}
                            {company.address.area}, {company.address.address}-{company.address.zip}
                          </p>
                        ) : null}
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  accounts: state.accounts,
  system: state.system,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getAccounts,
  getRoles,
  updateCompanyLogo,
  getCompany,
  getLoggedUserPermissions
})(Administration);
