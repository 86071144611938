import {
  BRANDS,
  CATEGORIES,
  COLORS,
  LOADING_FALSE,
  PRODUCTS,
  SIZES,
  UNITS,
  LOADING,
  TYPES,
  SEARCH_LOADING,
  SEARCH_LOADING_FALSE,
  ALL_PRODUCTS
} from './types';
import { returnErrors } from './messages';
import API from '../api';
import Swal from 'sweetalert2';

export const getProducts =
  ({ page, size, branch, search }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const res = await API.products.getProducts({ page, size, branch, search });
      dispatch({ type: LOADING_FALSE });
      dispatch({
        type: PRODUCTS,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getAllProducts = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const res = await API.products.getAllProducts();
    dispatch({ type: LOADING_FALSE });
    dispatch({
      type: ALL_PRODUCTS,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getSearchedProducts = (search) => async (dispatch) => {
  dispatch({ type: SEARCH_LOADING });
  try {
    const res = await API.products.getProducts({ search });
    dispatch({ type: SEARCH_LOADING_FALSE });
    dispatch({
      type: PRODUCTS,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const addProduct =
  (productData, categories, sizeData, files, colorData, variations) => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const formData = new FormData();
      formData.append('name', productData.name);
      formData.append('description', productData.description);
      formData.append('code', productData.code);
      formData.append('price', productData.price);
      formData.append('purchase_price', productData.purchase_price);
      formData.append('discount_price', productData.discount_price);
      formData.append('re_order_lvl', productData.re_order_lvl);
      formData.append('brandId', productData.brandId);
      formData.append('unitId', productData.unitId);
      formData.append('typeId', productData.typeId);
      formData.append('categories', categories.map((item) => item.id).join());
      formData.append('youtube_url', productData.youtube_url);
      formData.append('inventory_desc', productData.inventory_desc);
      formData.append('short_desc', productData.short_desc);
      formData.append('rackLocId', productData.rackLocId);
      let count = 1;
      for (const file of files) {
        if (file) {
          let renamed_file = new File([file], '$img_' + count.toString() + '$' + file.name, {
            type: file.type,
            lastModified: file.lastModified
          });
          formData.append('images', renamed_file);
        }
        count++;
      }

      const res = await API.products.addProduct(formData);

      const itemId = res.data.data.id;

      for (const color of colorData) {
        const newForm = new FormData();
        newForm.append('itemId', itemId);
        newForm.append('colorId', color.id);

        if (color.image) {
          let renamed_file = new File([color.image], '$img_col_$' + color.image.name, {
            type: color.image.type,
            lastModified: color.image.lastModified
          });
          newForm.append('images', renamed_file);
        }

        await API.products.addProductColor(newForm);
      }

      for (const size of sizeData) {
        const newForm = new FormData();
        newForm.append('itemId', itemId);
        newForm.append('sizeId', size.id);

        if (size.image) {
          let renamed_file = new File([size.image], '$img_size_$' + size.image.name, {
            type: size.image.type,
            lastModified: size.image.lastModified
          });
          newForm.append('images', renamed_file);
        }

        await API.products.addProductSize(newForm);
      }

      for (const locationData of productData.product_locations) {
        await API.products.addProductLocation(itemId, {
          branchId: locationData.branchId,
          locationId: locationData.locationId
        });
      }

      for (const variation of variations) {
        const data = {
          itemId,
          colorId: variation.colorId,
          sizeId: variation.sizeId,
          price: variation.price,
          purchase_price: variation.purchase_price
        };

        await API.products.addProductVariationPrice(data);
      }

      dispatch({ type: LOADING_FALSE });

      await Swal.fire({
        icon: 'success',
        title: res.data.message,
        text: 'You have successfully created a product.',
        showConfirmButton: false,
        timer: 1500
      });

      return res.data.data;
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const updateProduct =
  (productId, productData, categories, sizeData, files, colorData, removedImageIds, variations) =>
  async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const formData = new FormData();
      formData.append('name', productData.name);
      formData.append('description', productData.description);
      formData.append('code', productData.code);
      formData.append('price', productData.price);
      formData.append('purchase_price', productData.purchase_price);
      formData.append('discount_price', productData.discount_price);
      formData.append('re_order_lvl', productData.re_order_lvl || '');
      formData.append('brandId', productData.brandId);
      formData.append('unitId', productData.unitId);
      formData.append('typeId', productData.typeId);
      formData.append('categories', categories.map((item) => item.id).join());
      formData.append('removedImageIds', removedImageIds.join());
      formData.append('youtube_url', productData.youtube_url || '');
      formData.append('inventory_desc', productData.inventory_desc || '');
      formData.append('short_desc', productData.short_desc || '');
      formData.append('rackLocId', productData.rackLocId || '');
      let count = 1;
      for (const file of files) {
        if (file) {
          let renamed_file = new File([file], '$img_' + count.toString() + '$' + file.name, {
            type: file.type,
            lastModified: file.lastModified
          });
          formData.append('images', renamed_file);
        }
        count++;
      }

      const res = await API.products.updateProduct(formData, productId);

      for (const color of colorData) {
        const newForm = new FormData();
        newForm.append('itemId', productId);
        newForm.append('colorId', color.id);

        if (typeof color.image === 'object') {
          let renamed_file = new File([color.image], '$img_col_$' + color.image.name, {
            type: color.image.type,
            lastModified: color.image.lastModified
          });
          newForm.append('images', renamed_file);
        }

        await API.products.addProductColor(newForm);

        if (color.image === undefined && color.item_colors === undefined) {
          const data = {
            itemId: productId,
            colorId: color.id
          };
          await API.products.deleteProductColorImage(data);
        }
      }

      for (const size of sizeData) {
        const newForm = new FormData();
        newForm.append('itemId', productId);
        newForm.append('sizeId', size.id);

        if (typeof size.image === 'object') {
          let renamed_file = new File([size.image], '$img_size_$' + size.image.name, {
            type: size.image.type,
            lastModified: size.image.lastModified
          });
          newForm.append('images', renamed_file);
        }

        await API.products.addProductSize(newForm);

        if (size.image === undefined && size.item_sizes === undefined) {
          const data = {
            itemId: productId,
            sizeId: size.id
          };
          await API.products.deleteProductSizeImage(data);
        }
      }

      for (const locationData of productData.product_locations) {
        await API.products.addProductLocation(productId, {
          branchId: locationData.branchId,
          locationId: locationData.locationId
        });
      }

      // DELETE all Variation and add updated once
      API.products.deleteProductVariationPrice(productId).then(async () => {
        for (const variation of variations) {
          const data = {
            itemId: productId,
            colorId: variation.colorId,
            sizeId: variation.sizeId,
            price: variation.price,
            purchase_price: variation.purchase_price
          };

          await API.products.addProductVariationPrice(data);
        }
      });

      // DELETE EXTRA COLOR DATA FIELDS
      const colorRes = await API.products.deleteProductColor({
        itemId: productId,
        colors: colorData.map((color) => color.id).join()
      });

      if (!colorRes.data.success) {
        dispatch({ type: LOADING_FALSE });
        await Swal.fire('Color can not be removed!', colorRes.data.message, 'warning');
        return;
      }

      // DELETE EXTRA SIZE DATA FIELDS
      const sizeRes = await API.products.deleteProductSize({
        itemId: productId,
        sizes: sizeData.map((size) => size.id).join()
      });

      if (!sizeRes.data.success) {
        dispatch({ type: LOADING_FALSE });
        await Swal.fire('Size can not be removed!', sizeRes.data.message, 'warning');
        return;
      }

      dispatch({ type: LOADING_FALSE });

      await Swal.fire({
        icon: 'success',
        title: res.data.message,
        text: 'You have successfully updated product data.',
        showConfirmButton: false,
        timer: 1500
      });

      return res.data.data;
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const replaceProductSize = (itemId, data, getProductDetails) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const newForm = new FormData();
    newForm.append('itemId', itemId);
    newForm.append('sizeId', data.oldRef);
    newForm.append('newSizeId', data.data.id);

    if (typeof data.image === 'object') {
      let renamed_file = new File([data.image], '$img_size_$' + data.image.name, {
        type: data.image.type,
        lastModified: data.image.lastModified
      });
      newForm.append('images', renamed_file);
    }

    const res = await API.products.replaceProductSize(newForm);

    dispatch({ type: LOADING_FALSE });

    getProductDetails(itemId);

    await Swal.fire({
      icon: 'success',
      title: res.data.message,
      text: 'You have successfully replaced product size.',
      showConfirmButton: false,
      timer: 1500
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const replaceProductColor = (itemId, data, getProductDetails) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const newForm = new FormData();
    newForm.append('itemId', itemId);
    newForm.append('colorId', data.oldRef);
    newForm.append('newColorId', data.data.id);

    if (typeof data.image === 'object') {
      let renamed_file = new File([data.image], '$img_col_$' + data.image.name, {
        type: data.image.type,
        lastModified: data.image.lastModified
      });
      newForm.append('images', renamed_file);
    }

    const res = await API.products.replaceProductColor(newForm);

    dispatch({ type: LOADING_FALSE });

    getProductDetails(itemId);

    await Swal.fire({
      icon: 'success',
      title: res.data.message,
      text: 'You have successfully replaced product color.',
      showConfirmButton: false,
      timer: 1500
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const deleteProduct = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const res = await API.products.deleteProduct(id);
    dispatch({ type: LOADING_FALSE });
    await Swal.fire(res.data.message, 'You have successfully deleted a product.', 'success');
    dispatch(getProducts({}));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getCategories = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const res = await API.products.getCategories();
    dispatch({ type: LOADING_FALSE });
    dispatch({
      type: CATEGORIES,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const addCategory = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    await API.products.addCategory(data);
    dispatch({ type: LOADING_FALSE });
    dispatch(getCategories());
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const updateCategory = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    await API.products.updateCategory(data);
    dispatch({ type: LOADING_FALSE });
    dispatch(getCategories());
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const deleteCategory = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    await API.products.deleteCategory(id);
    dispatch({ type: LOADING_FALSE });
    dispatch(getCategories());
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getBrands = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const res = await API.products.getBrands();
    dispatch({ type: LOADING_FALSE });
    dispatch({
      type: BRANDS,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const addBrand = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    await API.products.addBrand(data);
    dispatch({ type: LOADING_FALSE });
    dispatch(getBrands());
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const deleteBrand = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    await API.products.deleteBrand(id);
    dispatch({ type: LOADING_FALSE });
    dispatch(getBrands());
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const updateBrand = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    await API.products.updateBrand(data);
    dispatch({ type: LOADING_FALSE });
    dispatch(getBrands());
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getUnits = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const res = await API.products.getUnits();
    dispatch({ type: LOADING_FALSE });
    dispatch({
      type: UNITS,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const addUnit = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    await API.products.addUnit(data);
    dispatch({ type: LOADING_FALSE });
    dispatch(getUnits());
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const deleteUnit = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    await API.products.deleteUnit(id);
    dispatch({ type: LOADING_FALSE });
    dispatch(getUnits());
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const updateUnit = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    await API.products.updateUnit(data);
    dispatch({ type: LOADING_FALSE });
    dispatch(getUnits());
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getColors = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const res = await API.products.getColors();
    dispatch({ type: LOADING_FALSE });
    dispatch({
      type: COLORS,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const addColor = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    await API.products.addColor(data);
    dispatch({ type: LOADING_FALSE });
    dispatch(getColors());
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const deleteColor = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    await API.products.deleteColor(id);
    dispatch({ type: LOADING_FALSE });
    dispatch(getColors());
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const updateColor = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    await API.products.updateColor(data);
    dispatch({ type: LOADING_FALSE });
    dispatch(getColors());
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getSizes = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const res = await API.products.getSizes();
    dispatch({ type: LOADING_FALSE });
    dispatch({
      type: SIZES,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const addSize = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    await API.products.addSize(data);
    dispatch({ type: LOADING_FALSE });
    dispatch(getSizes());
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const deleteSize = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    await API.products.deleteSize(id);
    dispatch({ type: LOADING_FALSE });
    dispatch(getSizes());
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const updateSize = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    await API.products.updateSize(data);
    dispatch({ type: LOADING_FALSE });
    dispatch(getSizes());
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getTypes = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const res = await API.products.getTypes();
    dispatch({ type: LOADING_FALSE });
    dispatch({
      type: TYPES,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const addType = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    await API.products.addType(data);
    dispatch({ type: LOADING_FALSE });
    dispatch(getTypes());
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const deleteType = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    await API.products.deleteType(id);
    dispatch({ type: LOADING_FALSE });
    dispatch(getTypes());
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const updateType = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    await API.products.updateType(data);
    dispatch({ type: LOADING_FALSE });
    dispatch(getTypes());
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};
