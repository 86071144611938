import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../../layout/Breadcrumb';
import LoadingBackdrop from '../../../layout/LoadingBackdrop';
import { getRoles } from '../../../../actions/system';
import moment from 'moment';
import MaterialTable from 'material-table';
import { ROLES } from '../../../../permissions';
import { ADMINISTRATION_SECTIONS } from '../../../../utils';

function Roles({ isLoading, roles, getRoles, auth: { permissions } }) {
  const history = useHistory();

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === ROLES)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getRoles();
  }, []);

  const defaultRoleAccess = (id) => {
    history.push({
      pathname: '/administration/access',
      search: `?role=${id}`
    });
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/administration'}>Administration</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/administration/roles'}>Roles</Link>
      </li>
    </ol>
  );

  const tableHead = [
    { field: 'id', title: 'ID', editable: 'never' },
    { field: 'name', title: 'Name' },
    { field: 'description', title: 'Description' }
  ];

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={ADMINISTRATION_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Create New Role</h5>
                <p className={'mb-0'}>Create and manage roles</p>
              </div>
              <div>
                <Link
                  to={'/administration/add-role'}
                  className="btn btn-primary p-2 rounded text-decoration-none"
                >
                  <i className="far fa-plus-square me-2" /> Add Role
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div id="card-content" className={'mt-4'}>
          <MaterialTable
            title={'All roles'}
            columns={tableHead}
            data={roles}
            isLoading={isLoading}
            totalCount={roles.length}
            options={{
              headerStyle: {
                backgroundColor: '#F6F9FC',
                color: '#000',
                whiteSpace: 'nowrap'
              },
              rowStyle: (rowData) => {
                return {
                  fontSize: '14px',
                  color: '#000'
                };
              },
              padding: 'default',
              pageSize: 20,
              actionsColumnIndex: -1,
              pageSizeOptions: [10, 25, 50],
              showFirstLastPageButtons: true,
              exportAllData: true,
              exportFileName: `Roles - ${moment(new Date()).format('LL')}`,
              exportButton: true,
              searchFieldAlignment: 'left',
              searchFieldStyle: {
                marginLeft: '3rem'
              }
            }}
            actions={[
              {
                hidden: false,
                icon: 'print',
                onClick: (event, rowData) => {},
                isFreeAction: true,
                tooltip: 'Print'
              },
              {
                icon: 'refresh',
                onClick: (event, rowData) => {},
                isFreeAction: true,
                tooltip: 'refresh'
              },
              {
                icon: 'settings',
                onClick: (event, rowData) => defaultRoleAccess(rowData.id),
                isFreeAction: false,
                tooltip: 'Default Access'
              }
            ]}
          />
        </div>
      </div>
    </Fragment>
  );
}

Roles.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  roles: PropTypes.array.isRequired,
  getRoles: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  roles: state.system.roles,
  auth: state.auth
});

export default connect(mapStateToProps, { getRoles })(Roles);
