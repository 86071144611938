import {
  GET_SALE_ID,
  PACKAGING_DETAILS,
  PENDING_SALE,
  QUOTATION,
  QUOTATION_DETAILS,
  SALE,
  SALE_DETAILS,
  SALE_EXCHANGE_DETAILS,
  SALE_EXCHANGES,
  SALE_RETURN,
  SALE_RETURN_DETAILS
} from '../actions/types';

const initialState = {
  sale: null,
  saleDetails: null,
  quotation: null,
  quotationDetails: null,
  saleReturns: [],
  saleReturnsDetails: null,
  pendingSales: null,
  saleExchanges: null,
  saleExchangeDetails: null,
  packagingDetails: null,
  saleId: null
};

export default function (state = initialState, action) {
  const payload = action.payload;
  switch (action.type) {
    case SALE:
      return {
        ...state,
        sale: payload
      };

    case SALE_DETAILS:
      return {
        ...state,
        saleDetails: payload
      };

    case QUOTATION:
      return {
        ...state,
        quotation: payload
      };

    case QUOTATION_DETAILS:
      return {
        ...state,
        quotationDetails: payload
      };

    case SALE_RETURN:
      return {
        ...state,
        saleReturns: payload
      };

    case SALE_RETURN_DETAILS:
      return {
        ...state,
        saleReturnsDetails: payload
      };

    case PENDING_SALE:
      return {
        ...state,
        pendingSales: payload
      };

    case PACKAGING_DETAILS:
      return {
        ...state,
        packagingDetails: payload
      };

    case SALE_EXCHANGES:
      return {
        ...state,
        saleExchanges: payload
      };

    case SALE_EXCHANGE_DETAILS:
      return {
        ...state,
        saleExchangeDetails: payload
      };

    case GET_SALE_ID:
      return {
        ...state,
        saleId: payload
      };

    default:
      return state;
  }
}
