import { ACCOUNTS } from '../actions/types';

const initialState = {
  accounts: []
};

export default function (state = initialState, action) {
  const payload = action.payload;
  switch (action.type) {
    case ACCOUNTS:
      return {
        ...state,
        accounts: payload
      };

    default:
      return state;
  }
}
