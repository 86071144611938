import auth from './api/auth';
import accounts from './api/accounts';
import system from './api/system';
import products from './api/products';
import suppliers from './api/supplier';
import purchase from './api/purchase';
import stocks from './api/stocks';
import customers from './api/customer';
import sale from './api/sale';
import bankings from './api/bankings';
import reports from './api/reports';
import employee from './api/employee';
import dashboard from './api/dashboard';

// export const base_url = "http://localhost:5000/api"
// export const base_url_image = "http://localhost:5000/images/"

// Uncomment it when pushing to digital ocean
export const base_url = "https://delivery.onlineworldbd.com/api"
export const base_url_image = "https://delivery.onlineworldbd.com/images/"

// Uncomment it when pushing to heroku server
// export const base_url = "https://ecom-erp.herokuapp.com/api"
// export const base_url_image = "https://ecom-erp.herokuapp.com/images/"

const axios_package = require('axios');

export const headers = () => {
  return { Authorization: 'Bearer ' + localStorage.getItem('token') };
};

export const multiPartHeaders = (data) => {
  return {
    Authorization: 'Bearer ' + localStorage.getItem('token'),
    'Content-Type': `multipart/form-data; boundary=${data._boundary}`
  };
};

axios_package.interceptors.response.use(undefined, (err) => {
  switch (err.response.status) {
    case 401:
      if (localStorage.getItem('token')) {
        localStorage.removeItem('token');
      }
      break;
    default:
      break;
  }
  return Promise.reject(err);
});

export default {
  auth,
  accounts,
  system,
  products,
  suppliers,
  purchase,
  stocks,
  customers,
  sale,
  bankings,
  reports,
  employee,
  dashboard
};

export const axios = axios_package;
