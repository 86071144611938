import { combineReducers } from 'redux';
import auth from './auth';
import errors from './error';
import accounts from './accounts';
import system from './system';
import products from './products';
import suppliers from './suppliers';
import purchase from './purchase';
import stocks from './stocks';
import customers from './customers';
import sale from './sale';
import bankings from './bankings';
import reports from './reports';
import employee from './employee';
import dashboard from './dashboard';

export default combineReducers({
  auth,
  errors,
  accounts,
  system,
  products,
  suppliers,
  purchase,
  stocks,
  customers,
  sale,
  bankings,
  reports,
  employee,
  dashboard
});
