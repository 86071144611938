import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { base_url_image } from '../../../../api';
import InsertImageIcon from '../../../../assets/icons/photo_main.png';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon color={'primary'} fontSize="small" />;

const ReplaceSizeColor = ({
  replaceDialog,
  setReplaceDialog,
  replaceData,
  setReplaceData,
  data,
  isSize,
  save
}) => {
  const closeReplaceDialog = () => {
    setReplaceDialog(false);
    setReplaceData(null);
  };

  const setReplaceSizeImage = (e) => {
    e.preventDefault();

    if (typeof e.target.files[0] !== 'undefined') {
      let image_file = e.target.files[0];

      const img = document.getElementById(`sizeImage_replace`);
      const reader = new FileReader();
      reader.onload = function (event) {
        img.src = event.target.result;
      };
      reader.readAsDataURL(image_file);

      setReplaceData({
        ...replaceData,
        image: image_file
      });
    }
  };

  const deleteImage = () => {
    const img = document.getElementById(`sizeImage_replace`);
    img.src = InsertImageIcon;

    delete replaceData.image;
    if (replaceData.data.item_sizes) {
      delete replaceData.data.item_sizes;
    }
  };

  const getImageUrl = () => {
    if (isSize) {
      return replaceData.data.item_sizes
        ? replaceData.data.item_sizes.image
          ? base_url_image + replaceData.data.item_sizes.image
          : InsertImageIcon
        : InsertImageIcon;
    } else {
      return replaceData.data.item_colors
        ? replaceData.data.item_colors.image
          ? base_url_image + replaceData.data.item_colors.image
          : InsertImageIcon
        : InsertImageIcon;
    }
  };

  return (
    <Dialog
      open={replaceDialog}
      fullWidth
      maxWidth="sm"
      disableBackdropClick
      onClose={closeReplaceDialog}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="alert-dialog-slide-title">
        Replace Stocked {isSize ? 'Size' : 'Color'}
      </DialogTitle>
      <DialogContent>
        {replaceData && (
          <div className="form-group">
            <label htmlFor={'sizes'}>Size:</label>
            <Autocomplete
              id="checkboxes-tags-demo"
              options={data}
              size={'small'}
              value={replaceData.data}
              onChange={(event, value) => {
                setReplaceData({
                  ...replaceData,
                  data: value
                });
              }}
              disableCloseOnSelect={false}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={option.parentId ? { marginLeft: 16 } : { marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={isSize ? 'Select size' : 'Select Color'}
                />
              )}
            />

            {replaceData.data && (
              <Fragment key={replaceData.data.id}>
                <div className="list-group-item bg-light d-flex flex-column align-items-center mt-3">
                  <div
                    className={'image_area'}
                    style={{
                      position: 'relative',
                      flex: '1',
                      maxHeight: 80,
                      maxWidth: 80
                    }}
                  >
                    <HighlightOffIcon className="delete-image-icon" onClick={deleteImage} />
                    <img
                      id={`sizeImage_replace`}
                      src={getImageUrl()}
                      width={80}
                      height={80}
                      style={{ objectFit: 'scale-down' }}
                      alt="img-input"
                    />
                    <input
                      name="images"
                      accept="image/*"
                      className="add_image_btn"
                      type="file"
                      onChange={setReplaceSizeImage}
                    />
                  </div>
                  <h6 className={'mb-0 mt-2'}>{replaceData.data.name}</h6>
                </div>
              </Fragment>
            )}
          </div>
        )}

        <div className={'mt-3 text-muted'}>
          *Replacing will refresh the page, changed you made may not be saved. Save product details
          before replace.{' '}
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeReplaceDialog}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={save}
          disabled={replaceData.data ? replaceData.oldRef === replaceData.data.id : true}
        >
          Replace
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReplaceSizeColor;
