import { base_url, axios, headers } from '../api';

const qs = require('qs');

const addAccount = async (data) => {
  return await axios.post(`${base_url}/bankings/new/`, qs.stringify(data), {
    headers: headers()
  });
};

const getBankAccounts = async () => {
  return await axios.get(`${base_url}/bankings/`, {
    headers: headers()
  });
};

const addBankTransaction = async (data) => {
  return await axios.post(`${base_url}/bankings/transactions/new/`, qs.stringify(data), {
    headers: headers()
  });
};

const getBankTransactions = async (data) => {
  return await axios.post(`${base_url}/bankings/transactions/`, qs.stringify(data), {
    headers: headers()
  });
};

const getBankDepositTransactions = async (data) => {
  return await axios.post(`${base_url}/bankings/transactions/deposit`, qs.stringify(data), {
    headers: headers()
  });
};

const getBankTransferTransactions = async (data) => {
  return await axios.post(`${base_url}/bankings/transactions/transfers`, qs.stringify(data), {
    headers: headers()
  });
};

const getBankExpensesTransactions = async (data) => {
  return await axios.post(`${base_url}/bankings/transactions/expenses`, qs.stringify(data), {
    headers: headers()
  });
};

export default {
  addAccount,
  getBankAccounts,
  addBankTransaction,
  getBankDepositTransactions,
  getBankTransferTransactions,
  getBankExpensesTransactions,
  getBankTransactions
};
