import {
  ACCOUNT_TYPES,
  BRANCHES,
  EXPENSE_CATEGORIES,
  GET_COMPANY,
  GET_ROLES,
  INCOME_CATEGORIES,
  LOADING_FALSE,
  PAYMENT_METHODS,
  LOADING,
  PERMISSIONS,
  SINGLE_PERMISSIONS,
  BRANCH_DETAILS,
  LOCATIONS,
  INCOME_EXPENSE_CATEGORIES
} from './types';
import { returnErrors } from './messages';
import API from '../api';
import Swal from 'sweetalert2';

export const getRoles = () => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.system.getRoles();
    dispatch({ type: LOADING_FALSE });
    dispatch({
      type: GET_ROLES,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const addRole = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.system.addRole(data);
    dispatch({ type: LOADING_FALSE });
    const dialogRes = await Swal.fire(
      res.data.message,
      'You have successfully created a role.',
      'success'
    );
    if (dialogRes) {
      window.history.back();
    }
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const createCompanyProfile = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const addressRes = await API.system.addAddress(data);
    const addressId = addressRes.data.data.id;

    const newData = {
      name: data.name,
      tagline: data.tagline,
      phone: data.phone,
      phone2: data.phone2,
      size: data.size,
      addressId
    };
    const companyRes = await API.system.createCompanyProfile(newData);
    dispatch({ type: LOADING_FALSE });

    const dialogRes = await Swal.fire(
      companyRes.data.message,
      'You have successfully created company profile.',
      'success'
    );
    if (dialogRes) {
      window.history.back();
    }
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getCompany = () => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.system.getCompany();
    dispatch({ type: LOADING_FALSE });
    dispatch({ type: GET_COMPANY, payload: res.data.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const updateCompanyProfile = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.system.updateCompanyProfile(data);
    dispatch({ type: LOADING_FALSE });

    const dialogRes = await Swal.fire(
      res.data.message,
      'You have successfully updated company profile.',
      'success'
    );
    if (dialogRes) {
      window.history.back();
    }
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const updateCompanyLogo = (companyId, file) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const formData = new FormData();
    formData.append('companyId', companyId);

    let renamed_file = new File([file], file.name, {
      type: file.type,
      lastModified: file.lastModified
    });

    formData.append('images', renamed_file);

    const res = await API.system.updateCompanyLogo(formData);
    dispatch({ type: LOADING_FALSE });

    dispatch(getCompany());

    await Swal.fire(res.data.message, 'You have successfully updated company logo.', 'success');
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const addBranch = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.system.addAddress(data);

    const branchForm = {
      addressId: res.data.data.id,
      statusId: 1, // Pre-Defined status
      name: data.name
    };

    const branchRes = await API.system.addBranch(branchForm);
    dispatch({ type: LOADING_FALSE });

    const dialogRes = await Swal.fire(
      branchRes.data.message,
      'You have successfully created a branch.',
      'success'
    );
    if (dialogRes) {
      window.history.back();
    }
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const updateBranch = (data, id) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    await API.system.updateAddress({
      ...data,
      id: data.addressId
    });

    const res = await API.system.updateBranch(data, id);
    dispatch({ type: LOADING_FALSE });

    const dialogRes = await Swal.fire(
      res.data.message,
      'You have successfully updated branch.',
      'success'
    );
    if (dialogRes) {
      window.history.back();
    }
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getBranchDetails = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.system.getBranchById(id);
    dispatch({ type: BRANCH_DETAILS, payload: res.data.data });
    dispatch({ type: LOADING_FALSE });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const changeBranchStatus = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.system.updateBranchStatus(id);
    dispatch({ type: LOADING_FALSE });

    await Swal.fire(res.data.message, 'You have successfully updated branch status', 'success');

    dispatch(getAllBranches());
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const deleteBranch = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.system.deleteBranch(id);
    dispatch({ type: LOADING_FALSE });

    await Swal.fire(res.data.message, 'You have successfully deleted a branch.', 'success');

    dispatch(getBranches());
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getBranches = () => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.system.getBranches();
    dispatch({ type: LOADING_FALSE });

    dispatch({ type: BRANCHES, payload: res.data.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getAllBranches = () => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.system.getAllBranches();
    dispatch({ type: LOADING_FALSE });

    dispatch({ type: BRANCHES, payload: res.data.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getPaymentMethods = () => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.system.getPaymentMethods();
    dispatch({ type: LOADING_FALSE });

    dispatch({ type: PAYMENT_METHODS, payload: res.data.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getBankAccountTypes = () => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.system.getBankAccountTypes();
    dispatch({ type: LOADING_FALSE });

    dispatch({ type: ACCOUNT_TYPES, payload: res.data.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getIncomeExpenseCategories = () => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.system.getIncomeExpenseCategories();
    dispatch({ type: LOADING_FALSE });

    dispatch({ type: INCOME_EXPENSE_CATEGORIES, payload: res.data.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const addIncomeExpenseCategories = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.system.addIncomeExpenseCategories(data);
    dispatch({ type: LOADING_FALSE });

    dispatch(getIncomeExpenseCategories());

    await Swal.fire({
      icon: 'success',
      title: res.data.message,
      text: 'You have successfully added IET category.',
      showConfirmButton: false,
      timer: 1500
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const updateIncomeExpenseCategories = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    await API.system.updateIncomeExpenseCategories(data);
    dispatch({ type: LOADING_FALSE });
    dispatch(getIncomeExpenseCategories());
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getIncomeCategories = () => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.system.getIncomeCategories();
    dispatch({ type: LOADING_FALSE });

    dispatch({ type: INCOME_CATEGORIES, payload: res.data.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getExpenseCategories = () => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.system.getExpenseCategories();
    dispatch({ type: LOADING_FALSE });

    dispatch({ type: EXPENSE_CATEGORIES, payload: res.data.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getPermissions = () => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.system.getPermissions();
    dispatch({ type: LOADING_FALSE });

    dispatch({ type: PERMISSIONS, payload: res.data.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getRolePermissions = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.system.getRolePermissions(id);
    dispatch({ type: LOADING_FALSE });

    dispatch({ type: SINGLE_PERMISSIONS, payload: res.data.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getUserPermissions = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.system.getUserPermissions(id);
    dispatch({ type: LOADING_FALSE });

    dispatch({ type: SINGLE_PERMISSIONS, payload: res.data.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const defaultPermissionSetup = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    await API.system.defaultPermissionSetup(data);
    dispatch({ type: LOADING_FALSE });

    const dialogRes = await Swal.fire(
      'Default Access Updated!',
      'Default access for this role successfully updated.',
      'success'
    );
    if (dialogRes) {
      window.history.back();
    }
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const customPermissionSetup = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    await API.system.customPermissionSetup(data);
    dispatch({ type: LOADING_FALSE });

    const dialogRes = await Swal.fire(
      'User Access Updated!',
      'User custom access successfully updated.',
      'success'
    );
    if (dialogRes) {
      window.history.back();
    }
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const clearCustomPermissions = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    await API.system.clearCustomPermissions(data);
    dispatch({ type: LOADING_FALSE });

    const dialogRes = await Swal.fire(
      'Custom Access Cleared!',
      'User is updated to default access.',
      'success'
    );
    if (dialogRes) {
      window.history.back();
    }
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const addLocation = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.system.addLocation(data);
    dispatch({ type: LOADING_FALSE });

    dispatch(getLocations());

    await Swal.fire({
      icon: 'success',
      title: res.data.message,
      text: 'You have successfully added a location.',
      showConfirmButton: false,
      timer: 1500
    });

    return res.data.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getLocations = () => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.system.getLocations();
    dispatch({ type: LOADING_FALSE });

    dispatch({ type: LOCATIONS, payload: res.data.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};
