import {
  BANK_ACCOUNTS,
  BANK_DEPOSIT_TRANSACTIONS,
  BANK_EXPENSE_TRANSACTIONS,
  BANK_TRANSACTIONS,
  BANK_TRANSFER_TRANSACTIONS
} from '../actions/types';

const initialState = {
  bankAccounts: [],
  transactions: null,
  depositTransactions: null,
  transferTransactions: null,
  expenseTransactions: null
};

export default function (state = initialState, action) {
  const payload = action.payload;
  switch (action.type) {
    case BANK_ACCOUNTS:
      return {
        ...state,
        bankAccounts: payload
      };

    case BANK_TRANSACTIONS:
      return {
        ...state,
        transactions: payload
      };

    case BANK_DEPOSIT_TRANSACTIONS:
      return {
        ...state,
        depositTransactions: payload
      };

    case BANK_TRANSFER_TRANSACTIONS:
      return {
        ...state,
        transferTransactions: payload
      };

    case BANK_EXPENSE_TRANSACTIONS:
      return {
        ...state,
        expenseTransactions: payload
      };

    default:
      return state;
  }
}
