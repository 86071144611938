import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import moment from 'moment';
import { getStockTransfers, setInventorySearch } from '../../../actions/stocks';
import { Pagination } from '@material-ui/lab';

function AllTransfers({
  isLoading,
  getStockTransfers,
  setInventorySearch,
  stocks: { transfers, searchText }
}) {
  const [from_date, setFromDate] = useState(
    moment(Date()).subtract(30, 'days').format('YYYY-MM-DD')
  );
  const [to_date, setToDate] = useState(moment(Date()).format('YYYY-MM-DD'));

  useEffect(() => {
    getStockTransfers({ from_date, to_date, search: searchText });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      getStockTransfers({ from_date, to_date, search: searchText });
    }, 500);
  }, [searchText]);

  const handleProductSearch = (value) => {
    setInventorySearch(value);
  };

  const tableHead = [
    { field: 'item.code', title: 'Code' },
    { field: 'description', title: 'Description' },
    { field: 'from_branch.name', title: 'From Branch' },
    { field: 'to_branch.name', title: 'To Branch' },
    { field: 'qty', title: 'Qty' }
  ];

  return (
    <Fragment>
      <div className="container-fluid p-0">
        <div id="card-content" className={'shadow-none'}>
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Stock transfers</h5>
                <p className={'mb-0'}>All the stock transfers between branches</p>
              </div>
            </div>
          </div>

          {transfers && (
            <div className="card-body d-grid">
              <div className={'overflow-auto'}>
                <MaterialTable
                  title={'Transfers'}
                  columns={tableHead}
                  data={transfers?.paginatedData}
                  isLoading={isLoading}
                  totalCount={transfers?.total}
                  options={{
                    actionsColumnIndex: -1,
                    headerStyle: {
                      backgroundColor: '#F6F9FC',
                      color: '#000'
                    },
                    rowStyle: (rowData) => {
                      return {
                        fontSize: '14px',
                        color: '#000'
                      };
                    },
                    padding: 'dense',
                    pageSize: 20,
                    pageSizeOptions: [10, 25, 50],
                    showFirstLastPageButtons: true,
                    exportAllData: true,
                    exportFileName: `Stock Transfers - ${moment(new Date()).format('LL')}`,
                    exportButton: true,
                    searchFieldAlignment: 'left',
                    searchFieldStyle: {
                      marginLeft: '3rem'
                    },
                    searchText: searchText
                  }}
                  onSearchChange={handleProductSearch}
                  components={{
                    Pagination: (props) => (
                      <div className="text-center d-flex justify-content-end pagination mt-2">
                        <Pagination
                          color={'primary'}
                          count={transfers?.lastPage}
                          page={transfers?.currentPage}
                          siblingCount={2}
                          onChange={(e, value) =>
                            getStockTransfers({
                              from_date,
                              to_date,
                              page: value
                            })
                          }
                        />
                      </div>
                    )
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

AllTransfers.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getStockTransfers: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  stocks: state.stocks
});

export default connect(mapStateToProps, { getStockTransfers, setInventorySearch })(AllTransfers);
