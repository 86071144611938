import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import { getProducts, getSearchedProducts } from '../../../actions/products';
import {
  getAvailableQtyItem,
  getProductVariationString,
  REGEX_STRING,
  SALE_SECTIONS
} from '../../../utils';
import { getQuotationDetails, updateQuotation } from '../../../actions/sale';
import Swal from 'sweetalert2';
import { DEFAULT_BRANCH, DEFAULT_PAYMENT_METHOD, GENERAL_CUSTOMER } from '../../../const';
import { QUOTATIONS } from '../../../permissions';
import BranchSelector from '../../common/BranchSelector';
import CustomerSelector from '../../common/CustomerSelector';
import GeneralCustomerLayout from '../../common/GeneralCustomerLayout';
import PaymentMethodSelector from '../../common/PaymentMethodSelector';
import AllProductSelector from '../../common/AllProductSelector';
import CartsTable from '../../common/CartsTable';
import { getBranches, getPaymentMethods } from '../../../actions/system';
import { getCustomers } from '../../../actions/customers';

function UpdateQuotation({
  isLoading,
  getProducts,
  getSearchedProducts,
  updateQuotation,
  getCustomers,
  getPaymentMethods,
  getBranches,
  auth: { permissions, user },
  getQuotationDetails,
  system: { branches, paymentMethods },
  customers: { customers },
  products: { products, searchLoading },
  sale: { quotationDetails },
  location
}) {
  const history = useHistory();
  const autocompleteRef = useRef();
  const initState = {
    date: quotationDetails ? quotationDetails.date : '',
    userId: user ? user.id : '', // Logged in user ID
    id: quotationDetails ? quotationDetails.id : '',

    paymentMethodId: quotationDetails ? quotationDetails.paymentMethodId : '', // CASH

    customerName: quotationDetails ? quotationDetails.customer.name : '',
    customerId: quotationDetails ? quotationDetails.customer.id : '',
    customerPhone: quotationDetails ? quotationDetails.customer.phone : '',
    customerAddress: quotationDetails ? quotationDetails.customer.address : '',

    totalAmount: quotationDetails ? quotationDetails.total : '',

    cartsItem: quotationDetails ? quotationDetails.quotationItems : [],
    oldCartsItem: quotationDetails
      ? quotationDetails.quotationItems.map((item) => ({
          colorId: item.colorId,
          createdAt: item.createdAt,
          description: item.description,
          id: item.id,
          itemId: item.itemId,
          qty: item.qty,
          rate: item.rate,
          discount: item.discount,
          s_code: item.s_code,
          quotationId: item.quotationId,
          sizeId: item.sizeId,
          total: item.total,
          unit_price: item.unit_price,
          updatedAt: item.updatedAt,
          branchId: item.branchId
        }))
      : [],

    subTotal: quotationDetails ? quotationDetails.sub_total : 0,
    vat: quotationDetails ? parseInt(quotationDetails.vat) : 0,
    vatValue: quotationDetails ? quotationDetails.vat_value : 0,
    transportCost: quotationDetails ? quotationDetails.transport_cost : 80,
    discount: quotationDetails ? quotationDetails.discount : 0,
    total: quotationDetails ? quotationDetails.total : 0,
    note: quotationDetails ? quotationDetails.notes : ''
  };
  const [formData, setFormData] = useState({
    ...initState,
    branchId: ''
  });

  const {
    branchId,
    customerPhone,
    customerName,
    customerAddress,
    customerId,
    subTotal,
    totalAmount,
    cartsItem,
    note,
    total,
    discount,
    vat,
    vatValue,
    transportCost,
    date,
    paymentMethodId
  } = formData;

  const [generalCustomerSelected, setGeneralCustomerSelected] = useState(false);

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === QUOTATIONS)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const id = query.get('id');

    if (id) {
      getQuotationDetails(id);
    }
    getProducts({ size: 5 });
    getBranches();
    getCustomers({ purchase: true });
    getPaymentMethods();
  }, []);

  useEffect(() => {
    if (user) {
      setFormData({
        ...formData,
        userId: user.id
      });
    }
  }, [user]);

  useEffect(() => {
    setFormData({
      ...formData,
      ...initState
    });
  }, [quotationDetails]);

  useEffect(() => {
    if (paymentMethods.length > 0) {
      setFormData({
        ...formData,
        paymentMethodId: paymentMethods.find((item) => item.name === DEFAULT_PAYMENT_METHOD).id
      });
    }
  }, [paymentMethods]);

  useEffect(() => {
    if (branches.length > 0) {
      setFormData({
        ...formData,
        branchId: branches.find((branch) => branch.name === DEFAULT_BRANCH).id
      });
    }
  }, [branches]);

  const onCancelButtonClicked = (e) => {
    history.goBack();
  };

  const onProductSelected = (e, value) => {
    e.preventDefault();
    setTimeout(
      () =>
        autocompleteRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click(),
      10
    );

    if (!branchId) {
      Swal.fire({
        icon: 'warning',
        title: 'No Branch Selected!',
        text: 'You must choose a branch before add item to cart',
        showConfirmButton: false,
        timer: 1500
      });
      return;
    }

    if (value) {
      console.log(value);
      const availableQty = getAvailableQtyItem(value, branchId);

      if (!availableQty) {
        Swal.fire({
          icon: 'error',
          title: 'Stock empty',
          text: 'Not enough stock, Please choose another product.',
          showConfirmButton: false,
          timer: 1500
        });
        return;
      }

      // Check duplicate
      let duplicateItemIndex = cartsItem.findIndex(
        (item) =>
          (item.createdAt ? item.itemId : item.id) === value.id &&
          item.s_code === value.s_code &&
          item.branchId === branchId
      );
      if (duplicateItemIndex !== -1) {
        if (cartsItem[duplicateItemIndex].qty + 1 <= availableQty.qty) {
          cartsItem[duplicateItemIndex].qty++;
          cartsItem[duplicateItemIndex].total = getItemTotal(
            cartsItem[duplicateItemIndex].qty,
            cartsItem[duplicateItemIndex].rate,
            cartsItem[duplicateItemIndex].discount
          );
          setFormData({
            ...formData,
            cartsItem,
            subTotal: getSubTotal(cartsItem),
            total: getTotal(getSubTotal(cartsItem), totalAmount)
          });
        }
      } else {
        const updatedCarts = cartsItem.concat({
          id: value.id,
          itemId: value.id,
          description: getProductVariationString(value),
          s_code: value.s_code,
          qty: 1,
          rate: value.price,
          discount: value.discount ?? 0,
          total: value.price - (value.discount / 100) * value.price,
          colorId: value.color ? value.color.id : '',
          sizeId: value.size ? value.size.id : '',
          availableQty: availableQty ? availableQty.qty : 0,
          branchId: branchId
        });
        setFormData({
          ...formData,
          cartsItem: updatedCarts,
          subTotal: getSubTotal(updatedCarts),
          total: getTotal(getSubTotal(updatedCarts), totalAmount)
        });
      }
    }
  };

  const getTotal = (subTotalAmount, totalAmount) => {
    return parseFloat(
      (
        parseFloat(
          (
            parseFloat(subTotalAmount) +
            parseFloat(((vat / 100) * (getSubTotal(cartsItem) - totalAmount)).toFixed(2)) +
            parseFloat(transportCost)
          ).toFixed(2)
        ) - parseFloat(discount)
      ).toFixed(2)
    );
  };

  const getItemTotal = (qty, rate, discount) => {
    const discountPrice = (discount / 100) * rate * qty;
    return parseFloat((parseInt(qty) * parseFloat(rate) - discountPrice).toFixed(2));
  };

  const onQuantityChange = (e, id, s_code, value, branchId) => {
    e.preventDefault();
    let index = cartsItem.findIndex(
      (item) => item.id === id && item.s_code === s_code && item.branchId === branchId
    );
    cartsItem[index].qty = parseInt(value);
    cartsItem[index].total = getItemTotal(value, cartsItem[index].rate, cartsItem[index].discount);

    setFormData({
      ...formData,
      cartsItem,
      subTotal: getSubTotal(cartsItem),
      total: getTotal(getSubTotal(cartsItem), totalAmount),
      vatValue: (vat / 100) * (getSubTotal(cartsItem) - totalAmount)
    });
  };

  const onQuotationRateChange = (id, s_code, value, branchId) => {
    let index = cartsItem.findIndex(
      (item) => item.id === id && item.s_code === s_code && item.branchId === branchId
    );
    cartsItem[index].rate = value;
    cartsItem[index].total = getItemTotal(cartsItem[index].qty, value, cartsItem[index].discount);

    setFormData({
      ...formData,
      cartsItem,
      subTotal: getSubTotal(cartsItem),
      total: getTotal(getSubTotal(cartsItem), totalAmount),
      vatValue: (vat / 100) * (getSubTotal(cartsItem) - totalAmount)
    });
  };

  const onQuotationDiscountChange = (id, s_code, value, branchId) => {
    let index = cartsItem.findIndex(
      (item) => item.id === id && item.s_code === s_code && item.branchId === branchId
    );
    cartsItem[index].discount = value;
    cartsItem[index].total = getItemTotal(cartsItem[index].qty, cartsItem[index].rate, value);

    setFormData({
      ...formData,
      cartsItem,
      subTotal: getSubTotal(cartsItem),
      total: getTotal(getSubTotal(cartsItem), totalAmount),
      vatValue: (vat / 100) * (getSubTotal(cartsItem) - totalAmount)
    });
  };

  const deleteItemsFromCart = (id, s_code, totalAmount) => {
    const updatedCarts = cartsItem.filter((item) => item.id !== id || item.s_code !== s_code);
    setFormData({
      ...formData,
      cartsItem: updatedCarts,
      subTotal: getSubTotal(updatedCarts),
      total: getTotal(getSubTotal(updatedCarts), totalAmount),
      vatValue: (vat / 100) * (getSubTotal(updatedCarts) - totalAmount)
    });

    if (cartsItem.length === 1) {
      setFormData({
        ...formData,
        cartsItem: [],
        subTotal: 0,
        vat: 0,
        vatValue: 0,
        discount: 0,
        total: 0
      });
    }
  };

  const getSubTotal = (carts) => {
    if (carts.length > 0) {
      // Get array of all the items total value
      const array = carts.map((singleObject) => parseFloat(singleObject.total));
      return parseFloat(calculateTwoIntegersFromIntegersOfArray(array).toFixed(2));
    }
    return 0;
  };

  const calculateTwoIntegersFromIntegersOfArray = (array) => {
    // Calculate all the array values
    const add = (a, b) => a + b;
    return array.reduce(add);
  };

  const onVatChange = (e) => {
    setFormData({
      ...formData,
      vat: parseFloat(e.target.value),
      vatValue: parseFloat(
        (parseFloat((parseFloat(e.target.value) / 100).toFixed(2)) * subTotal).toFixed(2)
      ),
      total: getTotalAfterVat(
        parseFloat(
          (parseFloat((parseFloat(e.target.value) / 100).toFixed(2)) * subTotal).toFixed(2)
        )
      )
    });
  };

  const getTotalAfterVat = (value) => {
    return parseFloat(
      (
        parseFloat(
          (parseFloat(subTotal) + parseFloat(value) + parseFloat(transportCost)).toFixed(2)
        ) - parseFloat(discount)
      ).toFixed(2)
    ).toFixed(2);
  };

  const onTransportChange = (e) => {
    setFormData({
      ...formData,
      transportCost: parseFloat(e.target.value),
      total: getTotalAfterTransfer(parseFloat(e.target.value))
    });
  };

  const getTotalAfterTransfer = (value) => {
    return parseFloat(
      (
        parseFloat((parseFloat(subTotal) + parseFloat(vatValue) + parseFloat(value)).toFixed(2)) -
        parseFloat(discount)
      ).toFixed(2)
    );
  };

  const onDiscountChange = (e) => {
    setFormData({
      ...formData,
      discount: parseFloat(e.target.value),
      total: getTotalAfterDiscount(parseFloat(e.target.value))
    });
  };

  const getTotalAfterDiscount = (value) => {
    return parseFloat(
      (
        parseFloat(
          (parseFloat(subTotal) + parseFloat(vatValue) + parseFloat(transportCost)).toFixed(2)
        ) - parseFloat(value)
      ).toFixed(2)
    );
  };

  const updateQuotationInvoice = async (e) => {
    e.preventDefault();

    if (!branchId) {
      Swal.fire(
        'No Branch Selected!',
        'You must choose a branch before update any quotation',
        'warning'
      ).then();
      return;
    }

    if (!customerId) {
      Swal.fire(
        'Choose a Customer',
        'You must choose a customer before update any quotation',
        'warning'
      ).then();
      return;
    }

    if (cartsItem.length <= 0) {
      Swal.fire(
        'Choose an Item',
        'You must choose an item before update any quotation',
        'warning'
      ).then();
      return;
    }

    if (customerId === GENERAL_CUSTOMER) {
      if (customerName === '' || customerPhone === '') {
        Swal.fire(
          'Missing Customer Information',
          'Customer information required inorder to update any quotation',
          'warning'
        ).then();
        return;
      }
    }

    if (!paymentMethodId) {
      Swal.fire(
        'Choose a Payment Method',
        'You must choose a payment method before update any quotation',
        'warning'
      ).then();
      return;
    }

    const data = await updateQuotation(formData);
    history.push({
      pathname: '/sale/quotations/details',
      search: `?id=${data}`
    });
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/sale'}>Sale</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/sale/quotations/all'}>Invoices</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={`/sale/quotations/details?id=${quotationDetails && quotationDetails.id}`}>
          Details
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={`/sale/quotations/update?id=${quotationDetails && quotationDetails.id}`}>
          Update Quotation
        </Link>
      </li>
    </ol>
  );

  const tableHead = [
    {
      field: 'tableData.id',
      title: 'SL',
      cellStyle: {
        width: '5%'
      },
      render: (rowData) => {
        return <p className={'mb-0'}>{rowData.tableData.id + 1}</p>;
      }
    },
    {
      field: 'description',
      title: 'Description',
      cellStyle: {
        width: '30%'
      },
      render: (rowData) => {
        return rowData.description.includes('(') ? (
          <p className={'m-0'}>
            {rowData.description.split('(')[0]} (
            <span className={'font-weight-bold'}>{REGEX_STRING.exec(rowData.description)[1]}</span>)
          </p>
        ) : (
          rowData.description
        );
      }
    },
    {
      field: 'qty',
      title: 'Quantity',
      render: (rowData) => (
        <input
          type="number"
          className="form-control"
          value={rowData.qty}
          min={1}
          onChange={(e) =>
            onQuantityChange(e, rowData.id, rowData.s_code, e.target.value, rowData.branchId)
          }
        />
      )
    },
    {
      field: 'rate',
      title: 'Rate (৳)',
      render: (rowData) => (
        <input
          className="form-control"
          type="number"
          min={0}
          value={rowData.rate}
          onChange={(e) =>
            onQuotationRateChange(rowData.id, rowData.s_code, e.target.value, rowData.branchId)
          }
        />
      )
    },
    {
      field: 'discount',
      title: 'Discount (%)',
      render: (rowData) => (
        <input
          className="form-control"
          type="number"
          min={0}
          max={100}
          value={rowData.discount}
          onChange={(e) =>
            onQuotationDiscountChange(rowData.id, rowData.s_code, e.target.value, rowData.branchId)
          }
        />
      )
    },
    {
      field: 'total',
      title: 'Amount (৳)',
      render: (rowData) => (
        <input className="form-control" type="number" disabled value={rowData.total} />
      )
    },
    {
      field: 'branchId',
      title: 'Branch',
      render: (rowData) => (
        <p className="mb-0">{branches.find((branch) => branch.id === rowData.branchId).name}</p>
      )
    }
  ];

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={SALE_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <form onSubmit={(e) => updateQuotationInvoice(e)}>
          <div id="card-content">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5>Update Quotation</h5>
                  <p className={'mb-0'}>Update a quotation</p>
                </div>
                <div>
                  <button
                    type={'submit'}
                    disabled={!customerId || cartsItem.length <= 0 || !paymentMethodId}
                    className="btn btn-primary p-2 me-2"
                  >
                    Update
                  </button>
                  <button
                    onClick={onCancelButtonClicked}
                    type={'button'}
                    className="btn btn-danger p-2 text-light"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>

          {branches.length > 0 && paymentMethods.length > 0 && (
            <div className="row mt-4">
              <div className="col-md-9">
                <div id="card-content">
                  <div className="card-body">
                    <div className="row justify-content-between">
                      <div className="col-md-5">
                        <div className="form-group">
                          <div className="d-flex align-items-center justify-content-between">
                            <BranchSelector
                              formData={formData}
                              setFormData={setFormData}
                              branches={branches}
                            />
                          </div>
                        </div>

                        {customers && (
                          <div className="form-group mt-2">
                            <div className="d-flex align-items-center justify-content-between">
                              {customerId && (
                                <CustomerSelector
                                  formData={formData}
                                  setFormData={setFormData}
                                  setGeneralCustomerSelected={setGeneralCustomerSelected}
                                  customers={customers.paginatedData}
                                  getCustomers={(value) =>
                                    getCustomers({ purchase: true, search: value })
                                  }
                                  customerId={customerId}
                                  update={true}
                                />
                              )}
                            </div>
                          </div>
                        )}

                        {generalCustomerSelected && (
                          <GeneralCustomerLayout
                            setFormData={setFormData}
                            formData={formData}
                            customerName={customerName}
                            customerAddress={customerAddress}
                            customerPhone={customerPhone}
                          />
                        )}
                      </div>

                      <div className="col-md-7">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <div className="d-flex align-items-center justify-content-between">
                                <PaymentMethodSelector
                                  setFormData={setFormData}
                                  formData={formData}
                                  paymentMethods={paymentMethods}
                                  paymentMethodId={paymentMethodId}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <div className="d-flex align-items-center justify-content-between">
                                <input
                                  type="date"
                                  name={'date'}
                                  defaultValue={date}
                                  onChange={(e) => onChange(e)}
                                  className="form-control"
                                  placeholder={'Choose a date'}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {products && branchId && (
                          <div className="form-group mt-2">
                            <div className="d-flex align-items-center justify-content-between">
                              <AllProductSelector
                                autocompleteRef={autocompleteRef}
                                getSearchedProducts={getSearchedProducts}
                                onProductSelected={onProductSelected}
                                searchLoading={searchLoading}
                                products={products}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <CartsTable
                  cartsItem={cartsItem}
                  deleteItemsFromCart={deleteItemsFromCart}
                  tableHead={tableHead}
                />

                <div id="card-content" className="mt-4">
                  <div className="form-group">
                    <textarea
                      name={'note'}
                      value={note}
                      placeholder={'Write notes...'}
                      onChange={(e) => onChange(e)}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div id="card-content">
                  <div className="card-header">
                    <h5>Amount Details</h5>
                    <p className={'mb-0'}>Check the quotation amount details before updating</p>
                  </div>

                  <div className="card-body mt-2">
                    <div className="form-group">
                      <label htmlFor={'subTotal'}>Sub Total *</label>
                      <input
                        type="number"
                        name={'subTotal'}
                        value={subTotal}
                        required
                        min={0}
                        step=".01"
                        disabled={true}
                        placeholder={'Sub Total'}
                        className="form-control"
                      />
                    </div>

                    <div className="row justify-content-start mt-2">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor={'vat'}>Vat(%)</label>
                          <input
                            type="number"
                            name={'vat'}
                            value={vat}
                            min={0}
                            step=".01"
                            onChange={(e) => onVatChange(e)}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor={'vatValue'}>Vat Amount</label>
                          <input
                            type="number"
                            name={'vatValue'}
                            min={0}
                            step=".01"
                            disabled={true}
                            value={vatValue}
                            className="form-control"
                            placeholder={''}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group mt-2">
                      <label htmlFor={'transportCost'}>Shipping Charge</label>
                      <input
                        type="number"
                        name={'transportCost'}
                        value={transportCost}
                        min={0}
                        step=".01"
                        onChange={(e) => onTransportChange(e)}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group mt-2">
                      <label htmlFor={'discount'}>Discount</label>
                      <input
                        type="number"
                        name={'discount'}
                        value={discount}
                        min={0}
                        step=".01"
                        onChange={(e) => onDiscountChange(e)}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group mt-2">
                      <label htmlFor={'total'}>Total *</label>
                      <input
                        type="number"
                        name={'total'}
                        value={total}
                        min={0}
                        step=".01"
                        disabled={true}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </Fragment>
  );
}

UpdateQuotation.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  system: PropTypes.object.isRequired,
  getCustomers: PropTypes.func.isRequired,
  products: PropTypes.object.isRequired,
  getProducts: PropTypes.func.isRequired,
  addQuotation: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  sale: PropTypes.object.isRequired,
  updateQuotation: PropTypes.func.isRequired,
  getQuotationDetails: PropTypes.func.isRequired,
  getSearchedProducts: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  system: state.system,
  customers: state.customers,
  products: state.products,
  auth: state.auth,
  sale: state.sale
});

export default connect(mapStateToProps, {
  updateQuotation,
  getQuotationDetails,
  getSearchedProducts,
  getBranches,
  getProducts,
  getPaymentMethods,
  getCustomers
})(UpdateQuotation);
