import {
  INVENTORY_SEARCH,
  LOADING,
  LOADING_FALSE,
  PRODUCT_STOCK,
  PRODUCT_STOCK_REPORTS,
  STOCK_REPORTS,
  STOCK_TRANSFERS,
  STOCKS
} from './types';
import { returnErrors } from './messages';
import API from '../api';
import Swal from 'sweetalert2';

export const setInventorySearch = (text) => (dispatch) => {
  dispatch({
    type: INVENTORY_SEARCH,
    payload: text
  });
};

export const getStocks =
  ({ page, size, search }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.stocks.getStock({ page, size, search });
      dispatch({ type: LOADING_FALSE });
      dispatch({
        type: STOCKS,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const stockTransfer = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.stocks.stockTransfer(data);
    dispatch({ type: LOADING_FALSE });

    await Swal.fire('Stock Transfer!', res.data.message, 'success');

    dispatch(getStocks({}));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getStockTransfers =
  ({ page, size, from_date, to_date, search }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.stocks.getStockTransfers({ page, size, from_date, to_date, search });
      dispatch({ type: LOADING_FALSE });
      dispatch({
        type: STOCK_TRANSFERS,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getStockReports =
  ({ page, size, from_date, to_date, type, search }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.stocks.getStockReports({
        page,
        size,
        from_date,
        to_date,
        type,
        search
      });
      dispatch({ type: LOADING_FALSE });
      dispatch({
        type: STOCK_REPORTS,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getProductStockReports =
  ({ id, page, size, from_date, to_date, type }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.stocks.getProductStockReports({
        id,
        page,
        size,
        from_date,
        to_date,
        type
      });
      dispatch({ type: LOADING_FALSE });
      dispatch({
        type: PRODUCT_STOCK_REPORTS,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getProductStock = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.stocks.getProductStock(id);
    dispatch({ type: LOADING_FALSE });
    dispatch({
      type: PRODUCT_STOCK,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};
