import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box, Tab, Tabs } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import SupplierPayments from './SupplierPayments';
import UpdateSupplier from './UpdateSupplier';
import { SUPPLIERS, SUPPLIERS_PAYMENTS } from '../../../permissions';
import { SUPPLIER_SECTIONS } from '../../../utils';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#fff'
  }
}));

function SupplierDetails({ isLoading, location, auth: { permissions, user } }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [supplierId, setSupplierId] = React.useState(null);
  let history = useHistory();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === SUPPLIERS)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const id = query.get('id');

    if (id) {
      setSupplierId(id);
    }
  }, []);

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/suppliers'}>Suppliers</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/suppliers/details'}>Details</Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={SUPPLIER_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div id={'card-content'} className={`${classes.root} mt-4 pt-0`}>
        <div className="card-header">
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Supplier Details" {...a11yProps(0)} />
            {permissions.find((item) => item.name === SUPPLIERS_PAYMENTS) && (
              <Tab label="Payments" {...a11yProps(1)} />
            )}
          </Tabs>
        </div>
        <TabPanel value={value} index={0}>
          <UpdateSupplier supplierId={supplierId} />
        </TabPanel>
        {permissions.find((item) => item.name === SUPPLIERS_PAYMENTS) && (
          <TabPanel value={value} index={1}>
            <SupplierPayments supplierId={supplierId} />
          </TabPanel>
        )}
      </div>
    </Fragment>
  );
}

SupplierDetails.propTypes = {
  isLoading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  auth: state.auth
});

export default connect(mapStateToProps, {})(SupplierDetails);
