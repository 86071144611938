import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#1652F0',
      main: '#0052FF'
    },
    secondary: {
      main: '#FF7588'
    }
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif !important;'
  },
  shape: {
    borderRadius: 8
  },
  overrides: {
    MuiTable: {
      root: {
        border: '1px solid #F1F3F5'
      }
    },
    MuiDrawer: {
      root: {
        height: '48px'
      },
      paper: {
        background: '#252525',

        //change icon and nav menu color
        '& *': { color: 'rgba(0, 82, 255, 0.8)' }
      },
      paperAnchorDockedLeft: {
        // borderRight: "none"
      }
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0
      }
    },
    MuiTypography: {
      body1: {
        fontSize: 14
      },
      h6: {
        fontSize: '1rem',
        fontWeight: 700
      }
    },
    MuiTableCell: {
      root: {
        padding: 8,
        fontSize: '14px'
      },
      head: {
        fontWeight: 600,
        backgroundColor: 'rgb(246, 249, 252)'
      }
    },
    MuiPaper: {
      elevation2: {
        boxShadow: 'none'
      },
      rounded: {
        borderRadius: 0
      }
    },
    MuiTableHead: {
      root: {
        // padding: 300
      }
    },
    MuiToolbar: {
      regular: {
        minHeight: '45px !important',
        marginBottom: '10px'
      }
    },
    MuiOutlinedInput: {
      root: {
        fontWeight: 400,
        lineHeight: 1.5,
        color: '#495057',
        borderRadius: 4
      },
      notchedOutline: {
        top: 0
      }
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 10
      }
    },
    MuiAutocomplete: {
      root: {
        width: '100%'
      },
      inputRoot: {
        borderRadius: '0.25rem'
      },
      option: {
        fontSize: '14px'
        // borderBottom: "0.5px solid #cdcdcd"
      }
    },
    MuiListItem: {
      dense: {
        paddingTop: 0,
        paddingBottom: 0
      }
    },
    MuiIconButton: {
      root: {
        padding: 8
      }
    },

    MuiIcon: {
      root: {
        fontSize: 19
      }
    },
    MuiTab: {
      root: {
        textTransform: 'capitalize',
        fontWeight: 600,
        color: '#000 !important'
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: '0.875rem',
        borderBottom: '1px solid #E2E2E2'
      }
    },
    MuiMenu: {
      paper: {
        marginTop: '2rem'
      }
    },
    MuiTabs: {
      indicator: {
        backgroundColor: '#0052FF'
      }
    },
    MuiButton: {
      root: {
        borderRadius: 4,
        fontWeight: 600,
        marginRight: 16,
        textTransform: 'none'
      }
    }
  }
});

export default theme;
