import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getProductStock } from '../../../../actions/stocks';

function ProductStatus({ isLoading, getProductStock, stocks: { productStocks }, location }) {
  const componentRef = useRef();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const id = query.get('id');

    if (id) {
      getProductStock(id);
    }
  }, []);

  let temp = '';
  if (productStocks.length > 0 && !isLoading) {
    let total = 0;

    productStocks.forEach((u) => {
      temp += `<tr>`;
      temp += `<td>${u.branch.name}</td>`;
      temp += `<td>${u.qty}</td>`;

      total += u.qty;
    });

    temp += `<tr style="background:rgba(22, 211, 154, 0.1);color:#000;">`;
    temp += `<td align="right"><strong>Total</strong></td>`;
    temp += `<td ><strong>${total}</strong></td>`;
  }

  //add table row markup to the tbody
  function createMarkup() {
    return { __html: temp };
  }

  return (
    <Fragment>
      <div className="container-fluid mt-4">
        <div className={'row'} ref={componentRef}>
          <div className="col-md-6">
            <div className={'table-responsive print-table'}>
              <table className={'table table-bordered table-striped table-sm table-hover'}>
                <thead className={'thead-light'}>
                  <tr>
                    <th>Branch</th>
                    <th>Available(Qty)</th>
                  </tr>
                </thead>
                <tbody id={'data'} dangerouslySetInnerHTML={createMarkup()}></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

ProductStatus.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getProductStock: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  stocks: state.stocks
});

export default connect(mapStateToProps, { getProductStock })(ProductStatus);
