import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import { changeBranchStatus, deleteBranch, getAllBranches } from '../../../../actions/system';
import Breadcrumb from '../../../layout/Breadcrumb';
import MaterialTable from 'material-table';
import moment from 'moment';
import { BRANCHES } from '../../../../permissions';
import { DEFAULT_BRANCH } from '../../../../const';
import { ADMINISTRATION_SECTIONS } from '../../../../utils';

function Branches({
  isLoading,
  getAllBranches,
  auth: { permissions },
  changeBranchStatus,
  system: { branches }
}) {
  let history = useHistory();

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === BRANCHES)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getAllBranches();
  }, []);

  const handleHistory = () => {
    history.push('#');
  };

  const moveToBranchUpdate = (id) => {
    history.push({
      pathname: '/settings/update-branch',
      search: `?id=${id}`
    });
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/settings'}>Settings</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/settings/branches'}>Branches</Link>
      </li>
    </ol>
  );

  const tableHead = [
    { field: 'id', title: 'ID', editable: 'never' },
    { field: 'name', title: 'Branch Name' },
    {
      title: 'Address',
      field: 'address',
      render: (rowData) => (
        <p className={'mb-0'}>
          {rowData.address &&
            `${rowData.address.address}, ${rowData.address.area}, ${rowData.address.address}`}
        </p>
      )
    },
    {
      title: 'Status',
      field: 'status.name',
      render: (rowData) => (
        <p className={`mb-0 ${rowData.status.name.toLowerCase()}_status`}>{rowData.status.name}</p>
      )
    }
  ];

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={ADMINISTRATION_SECTIONS} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Add New Branch</h5>
                <p className={'mb-0'}>Create and manage branches</p>
              </div>
              <div>
                <Link
                  to={'/settings/add-branch'}
                  className="btn btn-primary p-2 rounded text-decoration-none"
                >
                  <i className="far fa-plus-square me-2" /> Add Branch
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div id="card-content" className={'mt-4'}>
          <div className={'overflow-auto'}>
            <MaterialTable
              title={'All Branches'}
              columns={tableHead}
              data={branches}
              isLoading={isLoading}
              totalCount={branches.length}
              options={{
                headerStyle: {
                  backgroundColor: '#F6F9FC',
                  color: '#000'
                },
                rowStyle: (rowData) => {
                  return {
                    fontSize: '14px',
                    color: '#000'
                  };
                },
                actionsColumnIndex: -1,
                padding: 'default',
                pageSize: 20,
                pageSizeOptions: [10, 25, 50],
                showFirstLastPageButtons: true,
                exportAllData: true,
                exportFileName: `Branches - ${moment(new Date()).format('LL')}`,
                exportButton: true,
                searchFieldAlignment: 'left',
                searchFieldStyle: {
                  marginLeft: '3rem'
                }
              }}
              actions={[
                {
                  hidden: false,
                  icon: 'print',
                  onClick: (event, rowData) => handleHistory(),
                  isFreeAction: true,
                  tooltip: 'Print'
                },
                {
                  icon: 'refresh',
                  onClick: (event, rowData) => {},
                  isFreeAction: true,
                  tooltip: 'refresh'
                },
                {
                  icon: 'edit',
                  onClick: (event, rowData) => moveToBranchUpdate(rowData.id),
                  tooltip: 'Edit Branch'
                },
                (rowData) => ({
                  icon: 'autorenew',
                  onClick: (event, rowData) => changeBranchStatus(rowData.id),
                  tooltip: 'Update Status',
                  disabled: rowData.name === DEFAULT_BRANCH
                })
              ]}

              // editable={{
              //     onRowDelete: oldData =>
              //         new Promise((resolve, reject) => {
              //             setTimeout(() => {
              //                 deleteBranch(oldData.id)
              //
              //                 resolve();
              //             }, 1000);
              //         })
              // }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

Branches.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getAllBranches: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  system: state.system,
  auth: state.auth
});

export default connect(mapStateToProps, { getAllBranches, deleteBranch, changeBranchStatus })(
  Branches
);
