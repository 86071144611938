import { axios, base_url, headers } from '../api';

const qs = require('qs');

const getRoles = async () => {
  return await axios.get(`${base_url}/system/roles/`, {
    headers: headers()
  });
};

const addRole = async (data) => {
  return await axios.post(`${base_url}/system/roles/new`, qs.stringify(data), {
    headers: headers()
  });
};

const addAddress = async (data) => {
  return await axios.post(`${base_url}/system/addresses/new/`, qs.stringify(data), {
    headers: headers()
  });
};

const updateAddress = async (data) => {
  return await axios.put(`${base_url}/system/addresses/${data.id}/`, qs.stringify(data), {
    headers: headers()
  });
};

const deleteAddress = async (id) => {
  return await axios.delete(`${base_url}/system/addresses/${id}/`, {
    headers: headers()
  });
};

const createCompanyProfile = async (data) => {
  return await axios.post(`${base_url}/system/company/new/`, qs.stringify(data), {
    headers: headers()
  });
};

const getCompany = async () => {
  return await axios.get(`${base_url}/system/company-profile`, {
    headers: headers()
  });
};

const updateCompanyProfile = async (data) => {
  return await axios.put(`${base_url}/system/company/update/`, qs.stringify(data), {
    headers: headers()
  });
};

const updateCompanyLogo = async (data) => {
  return await axios.put(`${base_url}/system/company/logo/`, data, {
    headers: headers()
  });
};

const addBranch = async (data) => {
  return await axios.post(`${base_url}/system/branch/new`, qs.stringify(data), {
    headers: headers()
  });
};

const updateBranch = async (data, id) => {
  return await axios.put(`${base_url}/system/branch/${id}`, qs.stringify(data), {
    headers: headers()
  });
};

const deleteBranch = async (id) => {
  return await axios.delete(`${base_url}/system/branch/${id}`, {
    headers: headers()
  });
};

const getBranches = async () => {
  return await axios.get(`${base_url}/system/branch/`, {
    headers: headers()
  });
};

const getAllBranches = async () => {
  return await axios.get(`${base_url}/system/branch/all`, {
    headers: headers()
  });
};

const getBranchById = async (id) => {
  return await axios.get(`${base_url}/system/branch/${id}`, {
    headers: headers()
  });
};

const updateBranchStatus = async (id) => {
  return await axios.put(
    `${base_url}/system/branch/${id}/status`,
    {},
    {
      headers: headers()
    }
  );
};

const getBankAccountTypes = async () => {
  return await axios.get(`${base_url}/system/account-type/`, {
    headers: headers()
  });
};

const getPaymentMethods = async () => {
  return await axios.get(`${base_url}/system/payment-method/`, {
    headers: headers()
  });
};

const getIncomeCategories = async () => {
  return await axios.get(`${base_url}/system/income-categories/`, {
    headers: headers()
  });
};

const getIncomeExpenseCategories = async () => {
  return await axios.get(`${base_url}/system/income-expanse-categories/`, {
    headers: headers()
  });
};

const addIncomeExpenseCategories = async (data) => {
  return await axios.post(`${base_url}/system/income-expanse-categories/new`, qs.stringify(data), {
    headers: headers()
  });
};

const updateIncomeExpenseCategories = async (data) => {
  return await axios.put(
    `${base_url}/system/income-expanse-categories/${data.id}`,
    qs.stringify(data),
    {
      headers: headers()
    }
  );
};

const getExpenseCategories = async () => {
  return await axios.get(`${base_url}/system/expense-categories/`, {
    headers: headers()
  });
};

const getPermissions = async () => {
  return await axios.get(`${base_url}/system/permissions/`, {
    headers: headers()
  });
};

const getRolePermissions = async (id) => {
  return await axios.get(`${base_url}/system/permissions/roles/${id}`, {
    headers: headers()
  });
};

const getUserPermissions = async (id) => {
  return await axios.get(`${base_url}/system/permissions/users/${id}`, {
    headers: headers()
  });
};

const defaultPermissionSetup = async (data) => {
  return await axios.post(`${base_url}/system/permissions/default`, qs.stringify(data), {
    headers: headers()
  });
};

const customPermissionSetup = async (data) => {
  return await axios.post(`${base_url}/system/permissions/custom`, qs.stringify(data), {
    headers: headers()
  });
};

const clearCustomPermissions = async (data) => {
  return await axios.post(`${base_url}/system/permissions/custom/clear`, qs.stringify(data), {
    headers: headers()
  });
};

const addLocation = async (data) => {
  return await axios.post(`${base_url}/system/location/new`, qs.stringify(data), {
    headers: headers()
  });
};

const getLocations = async () => {
  return await axios.get(`${base_url}/system/location`, {
    headers: headers()
  });
};

export default {
  getRoles,
  addRole,
  addAddress,
  createCompanyProfile,
  getCompany,
  updateCompanyProfile,
  addBranch,
  updateBranch,
  deleteBranch,
  getBranches,
  deleteAddress,
  getBankAccountTypes,
  getPaymentMethods,
  getIncomeCategories,
  getExpenseCategories,
  updateAddress,
  getPermissions,
  getRolePermissions,
  defaultPermissionSetup,
  getUserPermissions,
  customPermissionSetup,
  clearCustomPermissions,
  updateCompanyLogo,
  getBranchById,
  updateBranchStatus,
  getAllBranches,
  addLocation,
  getLocations,
  getIncomeExpenseCategories,
  addIncomeExpenseCategories,
  updateIncomeExpenseCategories
};
