import React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

function AllBranchSelector({ branches, setFormData, formData }) {
  const onBranchSelected = (e, value) => {
    e.preventDefault();

    setFormData({
      ...formData,
      branchId: value ? value.id : ''
    });
  };

  return (
    <Autocomplete
      id={'select-branch'}
      onChange={(event, value) => onBranchSelected(event, value)}
      options={branches}
      renderOption={(option) => option.name}
      getOptionLabel={(option) => option.name}
      size={'small'}
      renderInput={(params) => (
        <TextField {...params} placeholder={'Product Location'} required variant="outlined" />
      )}
    />
  );
}

export default AllBranchSelector;
