import { axios, base_url, headers } from '../api';
import qs from 'qs';

const getSaleReports = async (data) => {
  return await axios.post(`${base_url}/reports/sales/`, qs.stringify(data), {
    headers: headers()
  });
};

const getSaleReportsByDate = async (date) => {
  return await axios.get(`${base_url}/reports/sales/${date}`, {
    headers: headers()
  });
};

const getPurchaseReports = async (data) => {
  return await axios.post(`${base_url}/reports/purchase/`, qs.stringify(data), {
    headers: headers()
  });
};

const getPurchaseReportsByDate = async (date) => {
  return await axios.get(`${base_url}/reports/purchase/${date}`, {
    headers: headers()
  });
};

const getSalesHistory = async (data) => {
  return await axios.post(`${base_url}/reports/sales-history/`, qs.stringify(data), {
    headers: headers()
  });
};

const getExpenseReports = async (data) => {
  return await axios.post(`${base_url}/reports/expenses/`, qs.stringify(data), {
    headers: headers()
  });
};

const getIncomeReports = async (data) => {
  return await axios.post(`${base_url}/reports/income/`, qs.stringify(data), {
    headers: headers()
  });
};

const getIncomeExpenseReportsYearly = async (data) => {
  return await axios.post(`${base_url}/reports/income-expense/yearly`, qs.stringify(data), {
    headers: headers()
  });
};

const getIncomeExpenseReports = async (data) => {
  return await axios.post(`${base_url}/reports/income-expense/`, qs.stringify(data), {
    headers: headers()
  });
};

export default {
  getSaleReports,
  getPurchaseReports,
  getSalesHistory,
  getExpenseReports,
  getIncomeReports,
  getIncomeExpenseReports,
  getSaleReportsByDate,
  getPurchaseReportsByDate,
  getIncomeExpenseReportsYearly
};
