import { axios, base_url, headers } from '../api';

const qs = require('qs');

const getCustomers = async (data) => {
  return await axios.post(`${base_url}/customers/`, qs.stringify(data), {
    headers: headers()
  });
};

const addCustomer = async (data) => {
  return await axios.post(`${base_url}/customers/new`, qs.stringify(data), {
    headers: headers()
  });
};

const updateCustomer = async (data) => {
  return await axios.put(`${base_url}/customers/${data.id}`, qs.stringify(data), {
    headers: headers()
  });
};

const addCustomerPayments = async (data) => {
  return await axios.post(`${base_url}/customers/${data.id}/payment`, qs.stringify(data), {
    headers: headers()
  });
};

const getCustomerPayments = async (data) => {
  return await axios.post(`${base_url}/customers/${data.id}/payments`, qs.stringify(data), {
    headers: headers()
  });
};

const getAllCustomerPayments = async (data) => {
  return await axios.post(`${base_url}/customers/payments`, qs.stringify(data), {
    headers: headers()
  });
};

const getCustomer = async (id) => {
  return await axios.get(`${base_url}/customers/${id}/details`, {
    headers: headers()
  });
};

export default {
  getCustomers,
  addCustomer,
  addCustomerPayments,
  getCustomerPayments,
  getCustomer,
  updateCustomer,
  getAllCustomerPayments
};
