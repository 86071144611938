import React from 'react';
import { DEFAULT_BRANCH } from '../../const';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

function BranchSelector({ branches, setFormData, formData, getProducts, pos = false }) {
  const onBranchSelected = (e, value) => {
    e.preventDefault();

    setFormData({
      ...formData,
      branchId: value ? value.id : ''
    });

    if (value && getProducts) {
      getProducts({ size: pos ? 10 : 5, branch: value.id });
    }
  };

  return (
    <Autocomplete
      id={'select-branch'}
      onChange={(event, value) => onBranchSelected(event, value)}
      options={branches}
      renderOption={(option) => option.name}
      getOptionLabel={(option) => option.name}
      defaultValue={branches.find((item) => item.name === DEFAULT_BRANCH)}
      size={'small'}
      renderInput={(params) => (
        <TextField {...params} placeholder={'Product Location'} required variant="outlined" />
      )}
    />
  );
}

export default BranchSelector;
