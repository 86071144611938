import {
  CLEAR_EMPLOYEE,
  DEPARTMENT,
  DESIGNATION,
  EMPLOYEE,
  SALARY_HISTORY,
  SINGLE_EMPLOYEE
} from '../actions/types';

const initialState = {
  employee: null,
  designations: null,
  departments: null,
  employeeDetails: null,
  salaryHistory: null
};

export default function (state = initialState, action) {
  const payload = action.payload;
  switch (action.type) {
    case EMPLOYEE:
      return {
        ...state,
        employee: payload
      };

    case DESIGNATION:
      return {
        ...state,
        designations: payload
      };

    case DEPARTMENT:
      return {
        ...state,
        departments: payload
      };

    case SINGLE_EMPLOYEE:
      return {
        ...state,
        employeeDetails: payload
      };

    case CLEAR_EMPLOYEE:
      return {
        ...state,
        employeeDetails: null
      };

    case SALARY_HISTORY:
      return {
        ...state,
        salaryHistory: payload
      };

    default:
      return state;
  }
}
