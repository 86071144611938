import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import { CircularProgress, TextField } from '@material-ui/core';
import {
  filterProductVariation,
  getAvailableQtyItem,
  getProductVariationString
} from '../../../utils';
import { DEFAULT_BRANCH, DEFAULT_PAYMENT_METHOD, GENERAL_CUSTOMER } from '../../../const';
import Swal from 'sweetalert2';
import moment from 'moment';
import { SALES_ENTRY } from '../../../permissions';
import { getBranches, getPaymentMethods } from '../../../actions/system';
import { addSale } from '../../../actions/sale';
import { getCustomers } from '../../../actions/customers';
import { getProducts, getSearchedProducts } from '../../../actions/products';
import { base_url_image } from '../../../api';
import BranchSelector from '../../common/BranchSelector';
import CustomerSelector from '../../common/CustomerSelector';
import PaymentMethodSelector from '../../common/PaymentMethodSelector';

function Pos({
  isLoading,
  getBranches,
  getCustomers,
  getProducts,
  getSearchedProducts,
  addSale,
  getPaymentMethods,
  system: { branches, paymentMethods },
  customers: { customers },
  products: { products, searchLoading },
  auth: { permissions, user }
}) {
  const history = useHistory();
  const initState = {
    date: moment().format('YYYY-MM-DD'),
    userId: user ? user.id : '', // Logged in user ID
    branchId: '',
    paymentMethodId: '',

    customerName: '',
    customerId: '',
    customerPhone: '',
    customerAddress: '',
    customerPreDue: 0,

    totalAmount: 0,

    cartsItem: [],

    subTotal: 0,
    vat: 0,
    vatValue: 0,
    transportCost: 80,
    discount: 0,
    total: 0,
    due: 0,
    note: '',
    search: ''
  };
  const [formData, setFormData] = useState(initState);
  const [variationProducts, setVariationProducts] = useState([]);

  const {
    branchId,
    customerPhone,
    customerName,
    customerId,
    customerPreDue,
    subTotal,
    totalAmount,
    cartsItem,
    due,
    note,
    total,
    discount,
    vat,
    vatValue,
    transportCost,
    date,
    paymentMethodId,
    customerAddress,
    search
  } = formData;

  const [generalCustomerSelected, setGeneralCustomerSelected] = useState(false);

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === SALES_ENTRY)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getBranches();
    getCustomers({ purchase: true });
    getPaymentMethods();
  }, []);

  useEffect(() => {
    if (user) {
      setFormData({
        ...formData,
        userId: user.id
      });
    }
  }, [user]);

  useEffect(() => {
    if (paymentMethods.length > 0) {
      setFormData({
        ...formData,
        paymentMethodId: paymentMethods.find((item) => item.name === DEFAULT_PAYMENT_METHOD).id
      });
    }
  }, [paymentMethods]);

  useEffect(() => {
    if (branches.length > 0) {
      const branchId = branches.find((branch) => branch.name === DEFAULT_BRANCH).id;
      setFormData({
        ...formData,
        branchId
      });

      getProducts({ size: 15, branch: branchId });
    }
  }, [branches]);

  useEffect(() => {
    if (branchId && products) {
      setVariationProducts(filterProductVariation(products.paginatedData, branchId));
    }
  }, [products]);

  const checkItemHasInCart = (s_code) => {
    let cartItem = cartsItem.find((item) => item.s_code === s_code && item.branchId === branchId);
    return !!cartItem;
  };

  const onProductSelected = (e, value) => {
    e.preventDefault();

    if (!branchId) {
      Swal.fire({
        icon: 'warning',
        title: 'No Branch Selected!',
        text: 'You must choose a branch before add item to cart',
        showConfirmButton: false,
        timer: 1500
      });
      return;
    }

    if (value) {
      const availableQty = getAvailableQtyItem(value, branchId);

      if (!availableQty) {
        Swal.fire({
          icon: 'error',
          title: 'Stock empty',
          text: 'Not enough stock, Please choose another product.',
          showConfirmButton: false,
          timer: 1500
        });
        return;
      }

      // Check duplicate
      let duplicateItemIndex = cartsItem.findIndex(
        (item) => item.id === value.id && item.s_code === value.s_code && item.branchId === branchId
      );
      if (duplicateItemIndex !== -1) {
        if (cartsItem[duplicateItemIndex].qty + 1 <= availableQty.qty) {
          cartsItem[duplicateItemIndex].qty++;
          cartsItem[duplicateItemIndex].total = getItemTotal(
            cartsItem[duplicateItemIndex].qty,
            cartsItem[duplicateItemIndex].rate,
            cartsItem[duplicateItemIndex].discount
          );

          setFormData({
            ...formData,
            cartsItem,
            subTotal: getSubTotal(cartsItem),
            total: getTotal(getSubTotal(cartsItem), totalAmount),
            due: getTotal(getSubTotal(cartsItem), totalAmount)
          });
        }
      } else {
        const updatedCarts = cartsItem.concat({
          id: value.id,
          description: getProductVariationString(value),
          s_code: value.s_code,
          qty: 1,
          rate: value.price,
          discount: value.discount ?? 0,
          total: 1 * value.price,
          colorId: value.color ? value.color.id : '',
          sizeId: value.size ? value.size.id : '',
          availableQty: availableQty ? availableQty.qty : 0,
          branchId: branchId
        });
        setFormData({
          ...formData,
          cartsItem: updatedCarts,
          subTotal: getSubTotal(updatedCarts),
          total: getTotal(getSubTotal(updatedCarts), totalAmount),
          due: getTotal(getSubTotal(updatedCarts), totalAmount)
        });
      }
    }
  };

  const getTotal = (subTotalAmount, totalAmount) => {
    return parseFloat(
      (
        parseFloat(
          (
            parseFloat(subTotalAmount) +
            parseFloat(((vat / 100) * (getSubTotal(cartsItem) - totalAmount)).toFixed(2)) +
            parseFloat(transportCost)
          ).toFixed(2)
        ) - parseFloat(discount)
      ).toFixed(2)
    );
  };

  const getItemTotal = (qty, rate, discount) => {
    return parseFloat((parseInt(qty) * parseFloat(rate) - parseFloat(discount)).toFixed(2));
  };

  const calculateQty = (value, index) => {
    if (parseInt(value) <= cartsItem[index].availableQty) {
      cartsItem[index].qty = parseInt(value);
      cartsItem[index].total = getItemTotal(
        value,
        cartsItem[index].rate,
        cartsItem[index].discount
      );

      setFormData({
        ...formData,
        cartsItem,
        subTotal: getSubTotal(cartsItem),
        total: getTotal(getSubTotal(cartsItem), totalAmount),
        vatValue: (vat / 100) * (getSubTotal(cartsItem) - totalAmount),
        due: getTotal(getSubTotal(cartsItem), totalAmount)
      });
    }
  };

  const onQuantityChange = (e, id, s_code, value, branchId) => {
    e.preventDefault();

    let index = cartsItem.findIndex(
      (item) => item.id === id && item.s_code === s_code && item.branchId === branchId
    );

    // When user enter bigger number than available qty
    calculateQty(value, index);
  };

  const incrementQuantity = (e, id, s_code, branchId) => {
    e.preventDefault();

    let index = cartsItem.findIndex(
      (item) => item.id === id && item.s_code === s_code && item.branchId === branchId
    );

    const newQty = cartsItem[index].qty + 1;
    calculateQty(newQty, index);
  };

  const decrementQuantity = (e, id, s_code, branchId) => {
    e.preventDefault();

    let index = cartsItem.findIndex(
      (item) => item.id === id && item.s_code === s_code && item.branchId === branchId
    );

    const newQty = cartsItem[index].qty - 1;
    calculateQty(newQty, index);
  };

  const onSaleRateChange = (id, s_code, value, branchId) => {
    let index = cartsItem.findIndex(
      (item) => item.id === id && item.s_code === s_code && item.branchId === branchId
    );
    cartsItem[index].rate = value;
    cartsItem[index].total = getItemTotal(cartsItem[index].qty, value, cartsItem[index].discount);

    setFormData({
      ...formData,
      cartsItem,
      subTotal: getSubTotal(cartsItem),
      total: getTotal(getSubTotal(cartsItem), totalAmount),
      vatValue: (vat / 100) * (getSubTotal(cartsItem) - totalAmount),
      due: getTotal(getSubTotal(cartsItem), totalAmount)
    });
  };

  const onSaleDiscountChange = (id, s_code, value, branchId) => {
    let index = cartsItem.findIndex(
      (item) => item.id === id && item.s_code === s_code && item.branchId === branchId
    );
    cartsItem[index].discount = value;
    cartsItem[index].total = getItemTotal(cartsItem[index].qty, cartsItem[index].rate, value);

    setFormData({
      ...formData,
      cartsItem,
      subTotal: getSubTotal(cartsItem),
      total: getTotal(getSubTotal(cartsItem), totalAmount),
      vatValue: (vat / 100) * (getSubTotal(cartsItem) - totalAmount),
      due: getTotal(getSubTotal(cartsItem), totalAmount)
    });
  };

  const deleteItemsFromCart = (id, s_code, totalAmount) => {
    const updatedCarts = cartsItem.filter((item) => item.id !== id || item.s_code !== s_code);
    setFormData({
      ...formData,
      cartsItem: updatedCarts,
      subTotal: getSubTotal(updatedCarts),
      total: getTotal(getSubTotal(updatedCarts), totalAmount),
      vatValue: (vat / 100) * (getSubTotal(updatedCarts) - totalAmount),
      due: getTotal(getSubTotal(updatedCarts), totalAmount)
    });

    if (cartsItem.length === 1) {
      setFormData({
        ...formData,
        cartsItem: [],
        subTotal: 0,
        vat: 0,
        vatValue: 0,
        discount: 0,
        total: 0,
        due: 0
      });
    }
  };

  const getSubTotal = (carts) => {
    if (carts.length > 0) {
      // Get array of all the items total value
      const array = carts.map((singleObject) => parseFloat(singleObject.total));
      return parseFloat(calculateTwoIntegersFromIntegersOfArray(array).toFixed(2));
    }
    return 0;
  };

  const calculateTwoIntegersFromIntegersOfArray = (array) => {
    // Calculate all the array values
    const add = (a, b) => a + b;
    return array.reduce(add);
  };

  const onVatChange = (e) => {
    setFormData({
      ...formData,
      vat: parseFloat(e.target.value),
      vatValue: parseFloat(
        (parseFloat((parseFloat(e.target.value) / 100).toFixed(2)) * subTotal).toFixed(2)
      ),
      total: getTotalAfterVat(
        parseFloat(
          (parseFloat((parseFloat(e.target.value) / 100).toFixed(2)) * subTotal).toFixed(2)
        )
      ),
      due: getTotalAfterVat(
        parseFloat(
          (parseFloat((parseFloat(e.target.value) / 100).toFixed(2)) * subTotal).toFixed(2)
        )
      )
    });
  };

  const getTotalAfterVat = (value) => {
    return parseFloat(
      (
        parseFloat(
          (parseFloat(subTotal) + parseFloat(value) + parseFloat(transportCost)).toFixed(2)
        ) - parseFloat(discount)
      ).toFixed(2)
    );
  };

  const onTransportChange = (e) => {
    setFormData({
      ...formData,
      transportCost: parseFloat(e.target.value),
      total: getTotalAfterTransfer(parseFloat(e.target.value)),
      due: getTotalAfterTransfer(parseFloat(e.target.value))
    });
  };

  const getTotalAfterTransfer = (value) => {
    return parseFloat(
      (
        parseFloat((parseFloat(subTotal) + parseFloat(vatValue) + parseFloat(value)).toFixed(2)) -
        parseFloat(discount)
      ).toFixed(2)
    );
  };

  const onDiscountChange = (e) => {
    setFormData({
      ...formData,
      discount: parseFloat(e.target.value),
      total: getTotalAfterDiscount(parseFloat(e.target.value)),
      due: getTotalAfterDiscount(parseFloat(e.target.value))
    });
  };

  const getTotalAfterDiscount = (value) => {
    return parseFloat(
      (
        parseFloat(
          (parseFloat(subTotal) + parseFloat(vatValue) + parseFloat(transportCost)).toFixed(2)
        ) - parseFloat(value)
      ).toFixed(2)
    );
  };

  const cancelSale = () => {
    Swal.fire({
      title: 'Clear carts items?',
      text: "Are you sure? You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, clear it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setFormData(initState);
        const allActiveProducts = document.querySelectorAll('.active');
        for (const product of allActiveProducts) {
          product.classList.remove('active');
        }
      }
    });
  };

  const searchProduct = (e) => {
    e.preventDefault();

    getSearchedProducts(search);
    setFormData({
      ...formData,
      search: ''
    });
  };

  const saveSale = async (e) => {
    e.preventDefault();

    if (!branchId) {
      Swal.fire(
        'No Branch Selected!',
        'You must choose a branch before made any sale',
        'warning'
      ).then();
      return;
    }

    if (!customerId) {
      Swal.fire(
        'Choose a Customer',
        'You must choose a customer before made any sale',
        'warning'
      ).then();
      return;
    }

    if (cartsItem.length <= 0) {
      Swal.fire('Choose an Item', 'You must choose an item before made any sale', 'warning').then();
      return;
    }

    if (customerId === GENERAL_CUSTOMER) {
      if (customerName === '' || customerPhone === '') {
        Swal.fire(
          'Missing Customer Information',
          'Customer information required inorder to make any sale',
          'warning'
        ).then();
        return;
      }
    }

    if (!paymentMethodId) {
      Swal.fire(
        'Choose a Payment Method',
        'You must choose a payment method before made any sale',
        'warning'
      ).then();
      return;
    }

    const data = await addSale(formData);
    history.push({
      pathname: '/sale/details',
      search: `?id=${data.id}`
    });
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/pos'}>POS Sale</Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            {branches.length > 0 && paymentMethods.length > 0 && (
              <div className="row justify-content-between">
                <div className="col-md-3">
                  <div className="form-group">
                    <div className="d-flex align-items-center justify-content-between">
                      <BranchSelector
                        formData={formData}
                        setFormData={setFormData}
                        branches={branches}
                        getProducts={getProducts}
                        pos={true}
                      />
                    </div>
                  </div>
                </div>

                {customers && (
                  <div className="col-md-3">
                    <div className="form-group">
                      <div className="d-flex align-items-center justify-content-between">
                        <CustomerSelector
                          formData={formData}
                          setFormData={setFormData}
                          setGeneralCustomerSelected={setGeneralCustomerSelected}
                          customers={customers?.paginatedData}
                          getCustomers={(value) => getCustomers({ purchase: true, search: value })}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-md-3">
                  <div className="form-group">
                    <div className="d-flex align-items-center justify-content-between">
                      <PaymentMethodSelector
                        setFormData={setFormData}
                        formData={formData}
                        paymentMethods={paymentMethods}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <div className="d-flex align-items-center justify-content-between">
                      <input
                        type="date"
                        name={'date'}
                        defaultValue={date}
                        onChange={(e) => onChange(e)}
                        className="form-control"
                        placeholder={'Choose a date'}
                      />
                    </div>
                  </div>
                </div>
                {generalCustomerSelected && (
                  <Fragment>
                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row d-flex align-items-center">
                              <div className="col-4 col-lg-2">
                                <label className={'m-md-0 mb-1'} htmlFor={'customerName'}>
                                  Name
                                </label>
                              </div>
                              <div className="col-8 col-lg-10">
                                <input
                                  type="text"
                                  name={'customerName'}
                                  value={customerName}
                                  onChange={(e) => onChange(e)}
                                  className="form-control input-sm"
                                  placeholder={'Enter customer name'}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row d-flex align-items-center">
                              <div className="col-4 col-lg-2">
                                <label className={'m-md-0 mb-1'} htmlFor={'customerPhone'}>
                                  Phone
                                </label>
                              </div>
                              <div className="col-8 col-lg-10">
                                <input
                                  type="text"
                                  name={'customerPhone'}
                                  value={customerPhone}
                                  onChange={(e) => onChange(e)}
                                  className="form-control input-sm"
                                  placeholder={'Enter customer phone'}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row d-flex align-items-center">
                              <div className="col-4 col-lg-2">
                                <label className={'m-md-0 mb-1'} htmlFor={'customerAddress'}>
                                  Address
                                </label>
                              </div>
                              <div className="col-8 col-lg-10">
                                <input
                                  type="text"
                                  name={'customerAddress'}
                                  value={customerAddress}
                                  onChange={(e) => onChange(e)}
                                  className="form-control input-sm"
                                  placeholder={'Enter customer address'}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-6">
            <div id="card-content">
              {products && branchId && (
                <div className="form-group">
                  <form className="d-flex" onSubmit={searchProduct}>
                    <TextField
                      size={'small'}
                      id={'products-search'}
                      value={search}
                      name="search"
                      onChange={(e) => onChange(e)}
                      className={'product-search-input w-100'}
                      placeholder={'Search products by code, name, inventory description'}
                      variant="outlined"
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') searchProduct(e);
                      }}
                    />
                    <button type={'submit'} className="btn btn-primary product-search-btn">
                      <i className="fa fa-search"></i>
                    </button>
                  </form>
                </div>
              )}

              {searchLoading ? (
                <div className={'text-center mt-4'}>
                  <CircularProgress color="primary" size={24} />
                </div>
              ) : null}

              {variationProducts.length > 0 ? (
                <div className="products mt-4">
                  <div className="row gx-3 gy-4">
                    {variationProducts.map((item) => (
                      <div key={item.s_code} className="col-md-3">
                        <div
                          id={item.s_code}
                          className={`product shadow ${
                            checkItemHasInCart(item.s_code) ? 'active' : ''
                          }`}
                          onClick={(event) => onProductSelected(event, item)}
                        >
                          <img
                            src={base_url_image + item.inventory_image ?? item.image_1}
                            alt="product-image"
                          />
                          <div className="content-bottom">
                            <h6>
                              {item.code} - {item.name}
                            </h6>
                            <div className="d-flex justify-content-between">
                              <p>Tk {item.price}</p>
                              <p className={'font-weight-bold'}>
                                Q. {getAvailableQtyItem(item, branchId).qty}
                              </p>
                            </div>
                            {item.size && <span>{item.size.name}</span>}
                            {item.color && <span>{item.color.name}</span>}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="p-4 text-center text-muted">No products found!</div>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="carts">
              <div id="card-content">
                <div className={'table-responsive'}>
                  <table className="table">
                    <thead className={'carts-head'}>
                      <tr>
                        <th scope="col">Product</th>
                        <th scope="col">Rate</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Discount</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartsItem.length > 0 ? (
                        cartsItem.map((item) => (
                          <tr>
                            <td>
                              <div className={'d-flex align-items-center product-name'}>
                                <i
                                  className="fa fa-trash text-danger me-3 hover-link"
                                  onClick={() =>
                                    deleteItemsFromCart(item.id, item.s_code, item.total)
                                  }
                                />
                                {item.description}
                              </div>
                            </td>
                            <td>
                              <input
                                type="number"
                                className="form-control discount-input"
                                value={item.rate}
                                onChange={(e) =>
                                  onSaleRateChange(
                                    item.id,
                                    item.s_code,
                                    e.target.value,
                                    item.branchId
                                  )
                                }
                                min={0}
                              />
                            </td>
                            <td>
                              <div className={'d-flex justify-content-between'}>
                                <button
                                  className={`btn btn-primary ${item.qty === 1 && 'disabled'}`}
                                  onClick={(e) =>
                                    decrementQuantity(e, item.id, item.s_code, item.branchId)
                                  }
                                >
                                  <span>
                                    <i className="fa fa-minus" />
                                  </span>
                                </button>
                                <input
                                  type="number"
                                  className="form-control qty-input text-center"
                                  value={item.qty}
                                  disabled
                                  onChange={(e) =>
                                    onQuantityChange(
                                      e,
                                      item.id,
                                      item.s_code,
                                      e.target.value,
                                      item.branchId
                                    )
                                  }
                                  min={1}
                                />
                                <button
                                  className={`btn btn-primary ${
                                    item.availableQty === item.qty && 'disabled'
                                  }`}
                                  onClick={(e) =>
                                    incrementQuantity(e, item.id, item.s_code, item.branchId)
                                  }
                                >
                                  <span>
                                    <i className="fa fa-plus" />
                                  </span>
                                </button>
                              </div>
                            </td>
                            <td>
                              <input
                                type="number"
                                className="form-control discount-input"
                                max={(parseInt(item.qty) * parseFloat(item.rate)).toFixed(2)}
                                value={item.discount}
                                onChange={(e) =>
                                  onSaleDiscountChange(
                                    item.id,
                                    item.s_code,
                                    e.target.value,
                                    item.branchId
                                  )
                                }
                                min={0}
                              />
                            </td>
                            <td className={'w-100'}>Tk. {item.total}</td>
                          </tr>
                        ))
                      ) : (
                        <div className="p-4 text-center text-muted">No carts found!</div>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="payment-container">
                  <div className="row">
                    <div className="col-md-7">
                      <div className="row justify-content-start">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor={'subtotal'}>Subtotal *</label>
                            <input
                              type="number"
                              name={'subTotal'}
                              value={subTotal}
                              min={0}
                              step=".01"
                              disabled={true}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor={'total'} className={'text-danger'}>
                              Total *
                            </label>
                            <input
                              type="number"
                              name={'total'}
                              value={total}
                              min={0}
                              step=".01"
                              disabled={true}
                              className="form-control text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-start">
                        <div className="col-md-6">
                          <div className="form-group  mt-3">
                            <label htmlFor={'vat'}>Vat(%)</label>
                            <input
                              type="number"
                              name={'vat'}
                              value={vat}
                              min={0}
                              step=".01"
                              onChange={(e) => onVatChange(e)}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mt-3">
                            <label htmlFor={'vatValue'}>Vat Amount</label>
                            <input
                              type="number"
                              name={'vatValue'}
                              min={0}
                              step=".01"
                              disabled={true}
                              value={vatValue}
                              className="form-control"
                              placeholder={''}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-start">
                        <div className="col-md-6">
                          <div className="form-group mt-3">
                            <label htmlFor={'transportCost'}>Shipping Charge</label>
                            <input
                              type="number"
                              name={'transportCost'}
                              value={transportCost}
                              min={0}
                              step=".01"
                              onChange={(e) => onTransportChange(e)}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mt-3">
                            <label htmlFor={'discount'}>Discount</label>
                            <input
                              type="number"
                              name={'discount'}
                              value={discount}
                              min={0}
                              step=".01"
                              onChange={(e) => onDiscountChange(e)}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className={'d-flex justify-content-end mt-4 gap-3'}>
                        <div className={'content cancel'} onClick={cancelSale}>
                          <i className="fa fa-trash" aria-hidden={true}></i>
                          <h5>Cancel</h5>
                        </div>
                        <div className={'content save'} onClick={saveSale}>
                          <i className="fa fa-shopping-cart" aria-hidden={true}></i>
                          <h5>Sale</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

Pos.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getBranches: PropTypes.func.isRequired,
  system: PropTypes.object.isRequired,
  getCustomers: PropTypes.func.isRequired,
  customers: PropTypes.object.isRequired,
  products: PropTypes.object.isRequired,
  getProducts: PropTypes.func.isRequired,
  addSale: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  getSearchedProducts: PropTypes.func.isRequired,
  getPaymentMethods: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  system: state.system,
  customers: state.customers,
  products: state.products,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getBranches,
  addSale,
  getCustomers,
  getProducts,
  getPaymentMethods,
  getSearchedProducts
})(Pos);
