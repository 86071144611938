import {
  INVENTORY_SEARCH,
  PRODUCT_STOCK,
  PRODUCT_STOCK_REPORTS,
  STOCK_REPORTS,
  STOCK_TRANSFERS,
  STOCKS
} from '../actions/types';

const initialState = {
  stocks: null,
  transfers: null,
  reports: null,
  productStockReports: null,
  productStocks: [],
  searchText: ''
};

export default function (state = initialState, action) {
  const payload = action.payload;
  switch (action.type) {
    case STOCKS:
      return {
        ...state,
        stocks: payload
      };

    case STOCK_TRANSFERS:
      return {
        ...state,
        transfers: payload
      };

    case STOCK_REPORTS:
      return {
        ...state,
        reports: payload
      };

    case PRODUCT_STOCK_REPORTS:
      return {
        ...state,
        productStockReports: payload
      };

    case PRODUCT_STOCK:
      return {
        ...state,
        productStocks: payload
      };

    case INVENTORY_SEARCH:
      return {
        ...state,
        searchText: payload
      };

    default:
      return state;
  }
}
