import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import moment from 'moment';
import { areas, districts, divisions, EMPLOYEE_SECTIONS } from '../../../utils';
import Breadcrumb from '../../layout/Breadcrumb';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import {
  addDepartment,
  addDesignation,
  addEmployee,
  clearEmployee,
  getDepartments,
  getDesignations,
  getEmployeeDetails,
  updateEmployee
} from '../../../actions/employee';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { EMPLOYEE } from '../../../permissions';

const filter = createFilterOptions();

function AddEditEmployee({
  isLoading,
  addEmployee,
  getDesignations,
  getDepartments,
  addDesignation,
  clearEmployee,
  getEmployeeDetails,
  updateEmployee,
  addDepartment,
  auth: { permissions },
  employee: { designations, departments, employeeDetails },
  location
}) {
  const history = useHistory();
  const initFormData = {
    id: employeeDetails ? employeeDetails.id : '',
    name: employeeDetails ? employeeDetails.name : '',
    designationId: employeeDetails ? employeeDetails.designationId : '',
    departmentId: employeeDetails ? employeeDetails.departmentId : '',
    phone: employeeDetails ? employeeDetails.phone : '',
    email: employeeDetails ? employeeDetails.email : '',
    join_date: employeeDetails ? employeeDetails.join_date : moment().format('YYYY-MM-DD'),
    salary: employeeDetails ? employeeDetails.salary : '',
    father: employeeDetails ? employeeDetails.father : '',
    mother: employeeDetails ? employeeDetails.mother : '',
    gender: employeeDetails ? employeeDetails.gender : '',
    dob: employeeDetails ? employeeDetails.dob : moment().format('YYYY-MM-DD'),
    marital: employeeDetails ? employeeDetails.marital : '',
    cur_address: employeeDetails
      ? employeeDetails.currentAddress
        ? employeeDetails.currentAddress.address
        : ''
      : '',
    cur_division: employeeDetails
      ? employeeDetails.currentAddress
        ? employeeDetails.currentAddress.division
        : ''
      : '',
    cur_city: employeeDetails
      ? employeeDetails.currentAddress
        ? employeeDetails.currentAddress.city
        : ''
      : '',
    cur_area: employeeDetails
      ? employeeDetails.currentAddress
        ? employeeDetails.currentAddress.area
        : ''
      : '',
    cur_zip: employeeDetails
      ? employeeDetails.currentAddress
        ? employeeDetails.currentAddress.zip
        : ''
      : '',
    per_address: employeeDetails
      ? employeeDetails.currentAddress
        ? employeeDetails.permAddress.address
        : ''
      : '',
    per_division: employeeDetails
      ? employeeDetails.currentAddress
        ? employeeDetails.permAddress.division
        : ''
      : '',
    per_city: employeeDetails
      ? employeeDetails.currentAddress
        ? employeeDetails.permAddress.city
        : ''
      : '',
    per_area: employeeDetails
      ? employeeDetails.currentAddress
        ? employeeDetails.permAddress.area
        : ''
      : '',
    per_zip: employeeDetails
      ? employeeDetails.currentAddress
        ? employeeDetails.permAddress.zip
        : ''
      : '',

    designationText: employeeDetails
      ? employeeDetails.designation
        ? employeeDetails.designation.name
        : ''
      : '',
    departmentText: employeeDetails
      ? employeeDetails.department
        ? employeeDetails.department.name
        : ''
      : '',
    currentAddressId: employeeDetails ? employeeDetails.currentAddressId : '',
    permAddressId: employeeDetails ? employeeDetails.permAddressId : ''
  };
  const [formData, setFormData] = useState(initFormData);

  const {
    name,
    departmentId,
    dob,
    cur_area,
    cur_city,
    cur_division,
    cur_zip,
    cur_address,
    designationId,
    email,
    father,
    gender,
    marital,
    per_address,
    join_date,
    per_area,
    per_city,
    per_division,
    per_zip,
    mother,
    phone,
    salary,
    currentAddressId,
    permAddressId,
    departmentText,
    designationText
  } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === EMPLOYEE)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getDepartments({});
    getDesignations({});

    const query = new URLSearchParams(location.search);
    const id = query.get('id');

    if (id) {
      getEmployeeDetails(id);
    } else {
      clearEmployee();
    }
  }, []);

  useEffect(() => {
    setFormData(initFormData);
  }, [employeeDetails]);

  const onCancelButtonClicked = (e) => {
    history.goBack();
  };

  const saveEmployee = (e) => {
    e.preventDefault();

    employeeDetails ? updateEmployee(formData) : addEmployee(formData);
  };

  const onDivisionSelected = (e, value) => {
    e.preventDefault();
    setFormData({
      ...formData,
      cur_division: value
    });
  };

  const onCitySelected = (e, value) => {
    e.preventDefault();
    setFormData({
      ...formData,
      cur_city: value
    });
  };

  const onAreaSelected = (e, value) => {
    e.preventDefault();
    setFormData({
      ...formData,
      cur_area: value
    });
  };

  const onPermDivisionSelected = (e, value) => {
    e.preventDefault();
    setFormData({
      ...formData,
      per_division: value
    });
  };

  const onPermCitySelected = (e, value) => {
    e.preventDefault();
    setFormData({
      ...formData,
      per_city: value
    });
  };

  const onPermAreaSelected = (e, value) => {
    e.preventDefault();
    setFormData({
      ...formData,
      per_area: value
    });
  };

  const onGenderSelected = (e, value) => {
    e.preventDefault();
    setFormData({
      ...formData,
      gender: value
    });
  };

  const onMaritalStatusSelected = (e, value) => {
    e.preventDefault();
    setFormData({
      ...formData,
      marital: value
    });
  };

  const onDesignationSelected = async (e, value) => {
    e.preventDefault();
    setFormData({
      ...formData,
      designationId: value ? value.id : '',
      designationText: value ? value.name : ''
    });

    if (value && value.inputValue) {
      const data = await addDesignation({ name: value.inputValue });
      setFormData({
        ...formData,
        designationId: data.id,
        designationText: data.name
      });
    }
  };

  const onDepartmentSelected = async (e, value) => {
    e.preventDefault();
    setFormData({
      ...formData,
      departmentId: value ? value.id : '',
      departmentText: value ? value.name : ''
    });

    if (value && value.inputValue) {
      const data = await addDepartment({ name: value.inputValue });
      setFormData({
        ...formData,
        departmentId: data.id,
        departmentText: data.name
      });
    }
  };

  const genderArray = ['Male', 'Female'];
  const maritalStatusArray = ['Married', 'Unmarried'];

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/employee'}>Employee</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/employee/new'}>{employeeDetails ? 'Edit Employee' : 'New Employee'}</Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={EMPLOYEE_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <form id="card-content" onSubmit={(e) => saveEmployee(e)}>
          <div className="card-header">
            <div className="d-flex justify-content-between align-items center">
              <div>
                {employeeDetails ? (
                  <Fragment>
                    <h5>Edit Employee</h5>
                    <p>Edit existing employee details</p>
                  </Fragment>
                ) : (
                  <Fragment>
                    <h5>Add New Employee</h5>
                    <p>Fill employee details and add a new employee</p>
                  </Fragment>
                )}
              </div>
              <div>
                <button
                  type={'submit'}
                  className="btn btn-primary p-2 me-2"
                  disabled={name === '' || designationId === '' || departmentId === '0'}
                >
                  Save
                </button>
                <button
                  onClick={onCancelButtonClicked}
                  type={'button'}
                  className="btn btn-danger p-2 text-light"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <div className="card-body mt-2">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor={'name'}>Name:</label>
                  <input
                    type="text"
                    name={'name'}
                    required
                    value={name}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter employee name'}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor={'designation'}>Designation:</label>
                  <Autocomplete
                    value={designationText}
                    id={'select-designation'}
                    onChange={(event, value) => onDesignationSelected(event, value)}
                    options={designations?.paginatedData}
                    style={{ width: '100%' }}
                    size="small"
                    renderOption={(option) => option.name}
                    getOptionLabel={(option) => {
                      // e.g value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.name;
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      if (params.inputValue !== '') {
                        filtered.push({
                          inputValue: params.inputValue,
                          name: `Add "${params.inputValue}"`
                        });
                      }
                      return filtered;
                    }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            padding: 0,
                            height: '36px',
                            marginBottom: 4
                          }
                        }}
                        placeholder={'Choose designation'}
                        required
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor={'department'}>Department:</label>
                  <Autocomplete
                    id={'select-department'}
                    value={departmentText}
                    onChange={(event, value) => onDepartmentSelected(event, value)}
                    options={departments?.paginatedData}
                    style={{ width: '100%' }}
                    size="small"
                    renderOption={(option) => option.name}
                    getOptionLabel={(option) => {
                      // e.g value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.name;
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      if (params.inputValue !== '') {
                        filtered.push({
                          inputValue: params.inputValue,
                          name: `Add "${params.inputValue}"`
                        });
                      }
                      return filtered;
                    }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            padding: 0,
                            height: '36px',
                            marginBottom: 4
                          }
                        }}
                        placeholder={'Choose department'}
                        required
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div>

              <div className="col-md-4 mt-3">
                <div className="form-group">
                  <label htmlFor={'join_date'}>Join Date:</label>
                  <input
                    type="date"
                    name={'join_date'}
                    value={join_date}
                    required
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter join date'}
                  />
                </div>
              </div>

              <div className="col-md-4 mt-3">
                <div className="form-group">
                  <label htmlFor={'salary'}>Salary:</label>
                  <input
                    type="number"
                    name={'salary'}
                    value={salary}
                    required
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter salary'}
                  />
                </div>
              </div>

              <h5 className={'mt-4'}>Personal Information</h5>

              <div className="col-md-4 mt-2">
                <div className="form-group">
                  <label htmlFor={'phone'}>Phone:</label>
                  <input
                    type="text"
                    name={'phone'}
                    value={phone}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter phone'}
                  />
                </div>
              </div>

              <div className="col-md-4 mt-2">
                <div className="form-group">
                  <label htmlFor={'phone2'}>Email:</label>
                  <input
                    type="text"
                    name={'email'}
                    value={email}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter email address'}
                  />
                </div>
              </div>

              <div className="col-md-4 mt-2">
                <div className="form-group">
                  <label htmlFor={'mother'}>Date of Birth:</label>
                  <input
                    type="date"
                    name={'dob'}
                    value={dob}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter date of birth'}
                  />
                </div>
              </div>

              <div className="col-md-4 mt-3">
                <div className="form-group">
                  <label htmlFor={'father'}>Father&apos;s Name:</label>
                  <input
                    type="text"
                    name={'father'}
                    value={father}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={"Enter father's name"}
                  />
                </div>
              </div>

              <div className="col-md-4 mt-3">
                <div className="form-group">
                  <label htmlFor={'mother'}>Mother&apos;s Name:</label>
                  <input
                    type="text"
                    name={'mother'}
                    value={mother}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={"Enter mother's name"}
                  />
                </div>
              </div>

              <div className="col-md-4 mt-3">
                <div className="form-group">
                  <label htmlFor={'division'}>Gender</label>
                  <Autocomplete
                    id={'select-division'}
                    value={gender}
                    onChange={(event, value) => onGenderSelected(event, value)}
                    options={genderArray}
                    style={{ width: '100%' }}
                    size="small"
                    renderOption={(option) => option}
                    getOptionLabel={(option) => {
                      // e.g value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      return option;
                    }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            padding: 0,
                            height: '36px',
                            marginBottom: 4
                          }
                        }}
                        placeholder={'Select a gender'}
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div>

              <div className="col-md-4 mt-3">
                <div className="form-group">
                  <label htmlFor={'marital'}>Marital Status</label>
                  <Autocomplete
                    id={'select-marital'}
                    value={marital}
                    onChange={(event, value) => onMaritalStatusSelected(event, value)}
                    options={maritalStatusArray}
                    style={{ width: '100%' }}
                    size="small"
                    renderOption={(option) => option}
                    getOptionLabel={(option) => {
                      // e.g value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      return option;
                    }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            padding: 0,
                            height: '36px',
                            marginBottom: 4
                          }
                        }}
                        placeholder={'Select a marital status'}
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div>

              <h5 className={'mt-4'}>Current Address</h5>

              <div className="col-md-4 mt-2">
                <div className="form-group">
                  <label htmlFor={'cur_division'}>Division</label>
                  <Autocomplete
                    id={'select-cur_division'}
                    value={cur_division}
                    onChange={(event, value) => onDivisionSelected(event, value)}
                    options={divisions}
                    style={{ width: '100%' }}
                    size="small"
                    renderOption={(option) => option}
                    getOptionLabel={(option) => {
                      // e.g value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option;
                    }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            padding: 0,
                            height: '36px',
                            marginBottom: 4
                          }
                        }}
                        placeholder={'Select a division'}
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div>

              <div className="col-md-4 mt-2">
                <div className="form-group">
                  <label htmlFor={'cur_city'}>City</label>
                  <Autocomplete
                    id={'select-cur_city'}
                    value={cur_city}
                    onChange={(event, value) => onCitySelected(event, value)}
                    options={
                      cur_division &&
                      districts.filter((item) => item.divName === cur_division)[0].districts
                    }
                    style={{ width: '100%' }}
                    size="small"
                    disabled={!cur_division}
                    renderOption={(option) => option}
                    getOptionLabel={(option) => {
                      // e.g value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option;
                    }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            padding: 0,
                            height: '36px',
                            marginBottom: 4
                          }
                        }}
                        error={!cur_division}
                        placeholder={'Select a city'}
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div>

              <div className="col-md-4 mt-2">
                <div className="form-group">
                  <label htmlFor={'cur_area'}>Area:</label>
                  <Autocomplete
                    id={'select-cur_area'}
                    value={cur_area}
                    onChange={(event, value) => onAreaSelected(event, value)}
                    options={cur_city && areas.filter((item) => item.disName === cur_city)[0].areas}
                    style={{ width: '100%' }}
                    size="small"
                    disabled={!cur_city}
                    renderOption={(option) => option}
                    getOptionLabel={(option) => {
                      // e.g value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option;
                    }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            padding: 0,
                            height: '36px',
                            marginBottom: 4
                          }
                        }}
                        error={!cur_city}
                        placeholder={'Select an area'}
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div>

              <div className="col-md-4 mt-3">
                <div className="form-group">
                  <label htmlFor={'cur_zip'}>Zip Code:</label>
                  <input
                    type="text"
                    name={'cur_zip'}
                    value={cur_zip}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter ZIP'}
                  />
                </div>
              </div>

              <div className="col-md-4 mt-3">
                <div className="form-group">
                  <label htmlFor={'cur_address'}>Address:</label>
                  <input
                    type="text"
                    name={'cur_address'}
                    value={cur_address}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter Address'}
                  />
                </div>
              </div>

              <h5 className={'mt-4'}>Permanent Address</h5>

              <div className="col-md-4 mt-2">
                <div className="form-group">
                  <label htmlFor={'per_division'}>Division</label>
                  <Autocomplete
                    id={'select-per_division'}
                    value={per_division}
                    onChange={(event, value) => onPermDivisionSelected(event, value)}
                    options={divisions}
                    style={{ width: '100%' }}
                    size="small"
                    renderOption={(option) => option}
                    getOptionLabel={(option) => {
                      // e.g value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option;
                    }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            padding: 0,
                            height: '36px',
                            marginBottom: 4
                          }
                        }}
                        placeholder={'Select a division'}
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div>

              <div className="col-md-4 mt-2">
                <div className="form-group">
                  <label htmlFor={'per_city'}>City</label>
                  <Autocomplete
                    id={'select-per_city'}
                    value={per_city}
                    onChange={(event, value) => onPermCitySelected(event, value)}
                    options={
                      per_division &&
                      districts.filter((item) => item.divName === per_division)[0].districts
                    }
                    style={{ width: '100%' }}
                    size="small"
                    disabled={!per_division}
                    renderOption={(option) => option}
                    getOptionLabel={(option) => {
                      // e.g value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option;
                    }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            padding: 0,
                            height: '36px',
                            marginBottom: 4
                          }
                        }}
                        error={!per_division}
                        placeholder={'Select a city'}
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div>

              <div className="col-md-4 mt-2">
                <div className="form-group">
                  <label htmlFor={'per_area'}>Area:</label>
                  <Autocomplete
                    id={'select-per_area'}
                    value={per_area}
                    onChange={(event, value) => onPermAreaSelected(event, value)}
                    options={per_city && areas.filter((item) => item.disName === per_city)[0].areas}
                    style={{ width: '100%' }}
                    size="small"
                    disabled={!per_city}
                    renderOption={(option) => option}
                    getOptionLabel={(option) => {
                      // e.g value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option;
                    }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            padding: 0,
                            height: '36px',
                            marginBottom: 4
                          }
                        }}
                        error={!per_city}
                        placeholder={'Select an area'}
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div>

              <div className="col-md-4 mt-3">
                <div className="form-group">
                  <label htmlFor={'per_zip'}>Zip Code:</label>
                  <input
                    type="text"
                    name={'per_zip'}
                    value={per_zip}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter ZIP'}
                  />
                </div>
              </div>

              <div className="col-md-4 mt-3">
                <div className="form-group">
                  <label htmlFor={'per_address'}>Address:</label>
                  <input
                    type="text"
                    name={'per_address'}
                    value={per_address}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter Address'}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
}

AddEditEmployee.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getDesignations: PropTypes.func.isRequired,
  getDepartments: PropTypes.func.isRequired,
  addDepartment: PropTypes.func.isRequired,
  addDesignation: PropTypes.func.isRequired,
  addEmployee: PropTypes.func.isRequired,
  getEmployeeDetails: PropTypes.func.isRequired,
  clearEmployee: PropTypes.func.isRequired,
  updateEmployee: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  employee: state.employee,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getDesignations,
  getDepartments,
  addDesignation,
  addDepartment,
  addEmployee,
  getEmployeeDetails,
  clearEmployee,
  updateEmployee
})(AddEditEmployee);
