import React from 'react';
import { PURCHASE_ACCOUNT } from '../../const';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

function BankAccountSelector({ setFormData, formData, bankAccounts }) {
  const onBankAccountSelected = (e, value) => {
    e.preventDefault();

    setFormData({
      ...formData,
      bankAccountId: value ? value.id : '',
      availableBalance: value ? value.balance : ''
    });
  };

  return (
    <Autocomplete
      id={'select-branch'}
      onChange={(event, value) => onBankAccountSelected(event, value)}
      options={bankAccounts.filter((item) => item.bank_account_type.name === PURCHASE_ACCOUNT)}
      getOptionLabel={(option) => option.account_name}
      renderOption={(option) => option.account_name}
      defaultValue={bankAccounts.find((item) => item.bank_account_type.name === PURCHASE_ACCOUNT)}
      size={'small'}
      renderInput={(params) => (
        <TextField {...params} placeholder={'Select Account'} required variant="outlined" />
      )}
    />
  );
}

export default BankAccountSelector;
