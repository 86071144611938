import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div className={'not-found d-flex justify-content-center align-items-center'}>
      <div>
        <h1>Oops!</h1>
        <h4 className={'mt-4'}>404 - PAGE NOT FOUND</h4>
        <p className={'m-3'}>
          The page you are looking for might have been removed <br /> had it&apos;s name chained or
          is temporarily unavailable
        </p>
        <Link to={'/home'} className="btn btn-primary mt-2">
          GO TO HOME
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
