import {
  BANK_ACCOUNTS,
  BANK_DEPOSIT_TRANSACTIONS,
  BANK_EXPENSE_TRANSACTIONS,
  BANK_TRANSACTIONS,
  BANK_TRANSFER_TRANSACTIONS,
  LOADING,
  LOADING_FALSE
} from './types';
import { returnErrors } from './messages';
import API from '../api';
import Swal from 'sweetalert2';

export const addBankAccount = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.bankings.addAccount(data);
    dispatch({ type: LOADING_FALSE });

    const dialogRes = await Swal.fire(
      res.data.message,
      'Successfully bank account added',
      'success'
    );
    if (dialogRes) {
      window.history.back();
    }
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getBankAccounts = () => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.bankings.getBankAccounts();
    dispatch({ type: LOADING_FALSE });
    dispatch({
      type: BANK_ACCOUNTS,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const addBankTransaction = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.bankings.addBankTransaction(data);
    dispatch({ type: LOADING_FALSE });

    const dialogRes = await Swal.fire(
      res.data.message,
      `Successfully bank transactions added`,
      'success'
    );
    if (dialogRes) {
      window.history.back();
    }
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const addExpenseTransaction = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const formData = {
      bankAccountId: data.bankAccountId,
      date: data.date,
      description: data.description,
      amount: -data.amount,
      paymentMethodId: data.paymentMethodId,
      reference: data.reference,
      categoryId: data.categoryId,
      trans_type: 'expense'
    };
    const res = await API.bankings.addBankTransaction(formData);
    dispatch({ type: LOADING_FALSE });

    await Swal.fire({
      icon: 'success',
      title: res.data.message,
      text: 'Successfully expense added',
      showConfirmButton: false,
      timer: 1500
    });

    return res.data.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const addBankTransfer = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    // TO ACCOUNT
    const toData = {
      bankAccountId: data.toAccount,
      date: data.date,
      description: data.description,
      amount: data.amount,
      paymentMethodId: data.paymentMethodId,
      reference: data.reference,
      trans_type: 'cash-in-by-transfer'
    };
    await API.bankings.addBankTransaction(toData);

    // FROM ACCOUNT
    const fromData = {
      bankAccountId: data.fromAccount,
      date: data.date,
      description: data.description,
      amount: -data.amount,
      paymentMethodId: data.paymentMethodId,
      reference: data.reference,
      trans_type: 'cash-out-by-transfer'
    };
    const res = await API.bankings.addBankTransaction(fromData);

    dispatch({ type: LOADING_FALSE });
    const dialogRes = await Swal.fire(
      res.data.message,
      `Successfully bank transfer added`,
      'success'
    );
    if (dialogRes) {
      window.history.back();
    }
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getBankTransactions =
  ({ page, size, from_date, to_date, search }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.bankings.getBankTransactions({ page, size, from_date, to_date, search });
      dispatch({ type: LOADING_FALSE });
      dispatch({
        type: BANK_TRANSACTIONS,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getBankDepositTransactions =
  ({ page, size, from_date, to_date, search }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.bankings.getBankDepositTransactions({ page, size, from_date, to_date, search });
      dispatch({ type: LOADING_FALSE });
      dispatch({
        type: BANK_DEPOSIT_TRANSACTIONS,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getBankTransferTransactions =
  ({ page, size, from_date, to_date, search }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.bankings.getBankTransferTransactions({
        page,
        size,
        from_date,
        to_date,
        search
      });
      dispatch({ type: LOADING_FALSE });
      dispatch({
        type: BANK_TRANSFER_TRANSACTIONS,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getBankExpensesTransactions =
  ({ page, size, from_date, to_date, search }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.bankings.getBankExpensesTransactions({
        page,
        size,
        from_date,
        to_date,
        search
      });
      dispatch({ type: LOADING_FALSE });
      dispatch({
        type: BANK_EXPENSE_TRANSACTIONS,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };
