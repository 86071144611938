import React, { useState } from 'react';
import Chart from 'react-apexcharts';

const ExpenseDonut = ({ customer, supplier }) => {
  const [options, setOptions] = useState({
    labels: ['Customer', 'Supplier'],
    chart: {
      type: 'donut'
    },
    plotOptions: {
      pie: {
        donut: {
          size: '75%',
          labels: {
            show: true
          }
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      formatter: function (val, opts) {
        return (
          opts.w.globals.labels[opts.seriesIndex] + ': ৳' + opts.w.globals.series[opts.seriesIndex]
        );
      },
      fontSize: '13px',
      fontWeight: 600
    },
    responsive: [
      {
        breakpoint: 1400,
        options: {
          chart: {
            width: 300
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ]
  });

  const [series, setSeries] = useState([customer, supplier]);

  return (
    <div>
      <Chart options={options} series={series} type="donut" width={'400'} />
    </div>
  );
};

export default ExpenseDonut;
