import {
  PURCHASE,
  PURCHASE_DAMAGE_RETURN,
  PURCHASE_DAMAGE_RETURN_DETAILS,
  PURCHASE_DETAILS,
  PURCHASE_RETURN,
  PURCHASE_RETURN_DETAILS
} from '../actions/types';

const initialState = {
  purchase: null,
  purchaseDetails: null,
  purchaseReturns: [],
  purchaseDamageReturns: [],
  purchaseReturnsDetails: null,
  purchaseDamageReturnDetails: null
};

export default function (state = initialState, action) {
  const payload = action.payload;
  switch (action.type) {
    case PURCHASE:
      return {
        ...state,
        purchase: payload
      };

    case PURCHASE_DETAILS:
      return {
        ...state,
        purchaseDetails: payload
      };
    case PURCHASE_RETURN:
      return {
        ...state,
        purchaseReturns: payload
      };

    case PURCHASE_RETURN_DETAILS:
      return {
        ...state,
        purchaseReturnsDetails: payload
      };

    case PURCHASE_DAMAGE_RETURN:
      return {
        ...state,
        purchaseDamageReturns: payload
      };

    case PURCHASE_DAMAGE_RETURN_DETAILS:
      return {
        ...state,
        purchaseDamageReturnDetails: payload
      };

    default:
      return state;
  }
}
