import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const AuthCredential = ({ auth, children }) =>
  auth.isAuthenticated ? children : <Redirect to={'/login'} />;

AuthCredential.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(AuthCredential);
