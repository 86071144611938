import { axios, base_url, headers } from '../api';

const qs = require('qs');

const getProducts = async (data) => {
  return await axios.post(`${base_url}/products/`, qs.stringify(data), {
    headers: headers()
  });
};

const getAllProducts = async () => {
  return await axios.get(`${base_url}/products/all`, {
    headers: headers()
  });
};

const getProductDetails = async (id) => {
  return await axios.get(`${base_url}/products/${id}/details`, {
    headers: headers()
  });
};

const addProduct = async (data) => {
  return await axios.post(`${base_url}/products/new`, data, {
    headers: headers()
  });
};

const addProductColor = async (data) => {
  return await axios.post(`${base_url}/products/add-color`, data, {
    headers: headers()
  });
};

const addProductSize = async (data) => {
  return await axios.post(`${base_url}/products/add-size`, data, {
    headers: headers()
  });
};

const updateProduct = async (data, id) => {
  return await axios.put(`${base_url}/products/${id}`, data, {
    headers: headers()
  });
};

const deleteProduct = async (id) => {
  return await axios.delete(`${base_url}/products/${id}`, {
    headers: headers()
  });
};

const deleteProductColor = async (data) => {
  return await axios.post(`${base_url}/products/delete-product-color`, qs.stringify(data), {
    headers: headers()
  });
};

const deleteProductColorImage = async (data) => {
  return await axios.post(`${base_url}/products/delete-product-color-image`, qs.stringify(data), {
    headers: headers()
  });
};

const deleteProductSize = async (data) => {
  return await axios.post(`${base_url}/products/delete-product-size`, qs.stringify(data), {
    headers: headers()
  });
};

const deleteProductSizeImage = async (data) => {
  return await axios.post(`${base_url}/products/delete-product-size-image`, qs.stringify(data), {
    headers: headers()
  });
};

const getCategories = async () => {
  return await axios.get(`${base_url}/products/categories/`, {
    headers: headers()
  });
};

const addCategory = async (data) => {
  return await axios.post(`${base_url}/products/categories/new`, qs.stringify(data), {
    headers: headers()
  });
};

const updateCategory = async (data) => {
  return await axios.put(`${base_url}/products/categories/${data.id}`, qs.stringify(data), {
    headers: headers()
  });
};

const deleteCategory = async (id) => {
  return await axios.delete(`${base_url}/products/categories/${id}`, {
    headers: headers()
  });
};

const getBrands = async () => {
  return await axios.get(`${base_url}/products/brands/`, {
    headers: headers()
  });
};

const addBrand = async (data) => {
  return await axios.post(`${base_url}/products/brands/new`, qs.stringify(data), {
    headers: headers()
  });
};

const deleteBrand = async (id) => {
  return await axios.delete(`${base_url}/products/brands/${id}`, {
    headers: headers()
  });
};

const updateBrand = async (data) => {
  return await axios.put(`${base_url}/products/brands/${data.id}`, qs.stringify(data), {
    headers: headers()
  });
};

const getUnits = async () => {
  return await axios.get(`${base_url}/products/units/`, {
    headers: headers()
  });
};

const addUnit = async (data) => {
  return await axios.post(`${base_url}/products/units/new`, qs.stringify(data), {
    headers: headers()
  });
};

const deleteUnit = async (id) => {
  return await axios.delete(`${base_url}/products/units/${id}`, {
    headers: headers()
  });
};

const updateUnit = async (data) => {
  return await axios.put(`${base_url}/products/units/${data.id}`, qs.stringify(data), {
    headers: headers()
  });
};

const getColors = async () => {
  return await axios.get(`${base_url}/products/colors/`, {
    headers: headers()
  });
};

const addColor = async (data) => {
  return await axios.post(`${base_url}/products/colors/new`, qs.stringify(data), {
    headers: headers()
  });
};

const deleteColor = async (id) => {
  return await axios.delete(`${base_url}/products/colors/${id}`, {
    headers: headers()
  });
};

const updateColor = async (data) => {
  return await axios.put(`${base_url}/products/colors/${data.id}`, qs.stringify(data), {
    headers: headers()
  });
};

const getSizes = async () => {
  return await axios.get(`${base_url}/products/sizes/`, {
    headers: headers()
  });
};

const addSize = async (data) => {
  return await axios.post(`${base_url}/products/sizes/new`, qs.stringify(data), {
    headers: headers()
  });
};

const deleteSize = async (id) => {
  return await axios.delete(`${base_url}/products/sizes/${id}`, {
    headers: headers()
  });
};

const updateSize = async (data) => {
  return await axios.put(`${base_url}/products/sizes/${data.id}`, qs.stringify(data), {
    headers: headers()
  });
};

const replaceProductSize = async (data) => {
  return await axios.post(`${base_url}/products/replace-product-size`, data, {
    headers: headers()
  });
};

const replaceProductColor = async (data) => {
  return await axios.post(`${base_url}/products/replace-product-color`, data, {
    headers: headers()
  });
};

const getTypes = async () => {
  return await axios.get(`${base_url}/products/types/`, {
    headers: headers()
  });
};

const addType = async (data) => {
  return await axios.post(`${base_url}/products/types/new`, qs.stringify(data), {
    headers: headers()
  });
};

const deleteType = async (id) => {
  return await axios.delete(`${base_url}/products/types/${id}`, {
    headers: headers()
  });
};

const updateType = async (data) => {
  return await axios.put(`${base_url}/products/types/${data.id}`, qs.stringify(data), {
    headers: headers()
  });
};

const addProductLocation = async (id, data) => {
  return await axios.post(`${base_url}/products/${id}/location`, qs.stringify(data), {
    headers: headers()
  });
};

const addProductVariationPrice = async (data) => {
  return await axios.post(`${base_url}/products/add-item-variation-price`, qs.stringify(data), {
    headers: headers()
  });
};

const deleteProductVariationPrice = async (id) => {
  return await axios.delete(`${base_url}/products/${id}/delete-item-variation-price`, {
    headers: headers()
  });
};

export default {
  getCategories,
  addCategory,
  getBrands,
  addBrand,
  getUnits,
  addUnit,
  getColors,
  getSizes,
  addColor,
  addSize,
  deleteCategory,
  deleteColor,
  deleteBrand,
  deleteSize,
  deleteUnit,
  addProductColor,
  addProduct,
  getProducts,
  getProductDetails,
  updateProduct,
  deleteProductColor,
  deleteProductSize,
  deleteProduct,
  getTypes,
  addType,
  deleteType,
  addProductLocation,
  getAllProducts,
  addProductVariationPrice,
  addProductSize,
  deleteProductVariationPrice,
  deleteProductColorImage,
  deleteProductSizeImage,
  replaceProductSize,
  replaceProductColor,
  updateCategory,
  updateColor,
  updateSize,
  updateUnit,
  updateType,
  updateBrand
};
