import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import MaterialTable from 'material-table';
import moment from 'moment';
import { Pagination } from '@material-ui/lab';
import { getBankTransactions } from '../../../actions/bankings';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import Breadcrumb from '../../layout/Breadcrumb';
import FilterLayout from '../../layout/FilterLayout';
import { TRANSACTIONS } from '../../../permissions';
import { ACCOUNTS_SECTIONS } from '../../../utils';

function Transactions({
  isLoading,
  getBankTransactions,
  auth: { permissions },
  bankings: { transactions }
}) {
  const history = useHistory();
  const [search, setSearch] = useState('')
  const [from_date, setFromDate] = useState(
    moment(Date()).subtract(30, 'days').format('YYYY-MM-DD')
  );
  const [to_date, setToDate] = useState(moment(Date()).format('YYYY-MM-DD'));

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === TRANSACTIONS)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getAllBankTransactions();
  }, []);

  useEffect(() => {
    const delayDebounce = setTimeout(async () => {
      getBankTransactions({ search });
    }, 1000);
    return () => clearTimeout(delayDebounce);
  }, [search]);

  const getAllBankTransactions = () => {
    getBankTransactions({ from_date, to_date });
  };

  const handleSaleSearch = (value) => {
    setSearch(value)
  };

  const tableHead = [
    { field: 'date', title: 'Date' },
    { field: 'bank_account.account_name', title: 'Account Name' },
    { field: 'bank_account.account_no', title: 'Account Number' },
    { field: 'bank_account.bank_name', title: 'Bank Name' },
    { field: 'trans_type', title: 'Trxn Type' },
    { field: 'income_expanse_category.name', title: 'Category' },
    { field: 'description', title: 'Description' },
    { field: 'amount', title: 'Amount (৳)', render: (rowData) => Math.abs(rowData.amount) }
  ];

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/accounts'}>Accounts</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/accounts/transactions'}>Transactions</Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={ACCOUNTS_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <FilterLayout
        handleActions={getAllBankTransactions}
        setFromDate={setFromDate}
        setToDate={setToDate}
      />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className={'overflow-auto'}>
            <MaterialTable
              title={'All Transactions'}
              columns={tableHead}
              data={transactions?.paginatedData}
              isLoading={isLoading}
              totalCount={transactions?.total}
              options={{
                headerStyle: {
                  backgroundColor: '#F6F9FC',
                  color: '#000'
                },
                rowStyle: () => {
                  return {
                    fontSize: '14px',
                    color: '#000'
                  };
                },
                padding: 'default',
                pageSize: 20,
                actionsColumnIndex: -1,
                pageSizeOptions: [10, 25, 50],
                showFirstLastPageButtons: true,
                exportAllData: true,
                exportFileName: `Transactions - ${moment(new Date()).format('LL')}`,
                exportButton: true,
                searchFieldAlignment: 'left',
                searchFieldStyle: {
                  marginLeft: '3rem'
                },
              }}
              onSearchChange={handleSaleSearch}
              components={{
                Pagination: (props) => (
                  <div className="text-center d-flex justify-content-end pagination mt-2">
                    <Pagination
                      color={'primary'}
                      count={transactions?.lastPage}
                      page={transactions?.currentPage}
                      siblingCount={2}
                      onChange={(e, value) =>
                        getBankTransactions({
                          from_date,
                          to_date,
                          page: value,
                          search
                        })
                      }
                    />
                  </div>
                )
              }}
              actions={[
                {
                  icon: 'refresh',
                  onClick: (event, rowData) => {},
                  isFreeAction: true,
                  tooltip: 'refresh'
                }
              ]}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

Transactions.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getBankTransactions: PropTypes.func.isRequired,
  bankings: PropTypes.object
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  bankings: state.bankings,
  auth: state.auth
});

export default connect(mapStateToProps, { getBankTransactions })(Transactions);
