import React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

function FilterTypeSelector({ setFormData, formData, type }) {
  const FILTER_TYPES = [
    { name: 'Daily', value: 'daily' },
    { name: 'Monthly', value: 'monthly' },
    { name: 'Yearly', value: 'yearly' }
  ];

  const value = FILTER_TYPES.find((item) => item.value === type);

  const onFilterSelected = (e, value) => {
    e.preventDefault();

    setFormData({
      ...formData,
      type: value ? value.value : ''
    });
  };

  return (
    <Autocomplete
      value={value}
      onChange={(event, value) => onFilterSelected(event, value)}
      options={FILTER_TYPES}
      getOptionLabel={(option) => option.name}
      renderOption={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          size={'small'}
          placeholder={'Select Type'}
          required
          variant="outlined"
        />
      )}
    />
  );
}

export default FilterTypeSelector;
