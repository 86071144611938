import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../../layout/Breadcrumb';
import LoadingBackdrop from '../../../layout/LoadingBackdrop';
import MaterialTable from 'material-table';
import moment from 'moment';
import { Pagination } from '@material-ui/lab';
import { getBankExpensesTransactions } from '../../../../actions/bankings';
import FilterLayout from '../../../layout/FilterLayout';
import { EXPENSES } from '../../../../permissions';
import { ACCOUNTS_SECTIONS } from '../../../../utils';

function Expenses({
  isLoading,
  getBankExpensesTransactions,
  auth: { permissions },
  bankings: { expenseTransactions }
}) {
  const history = useHistory();
  const [search, setSearch] = useState('')
  const [from_date, setFromDate] = useState(
    moment(Date()).subtract(30, 'days').format('YYYY-MM-DD')
  );
  const [to_date, setToDate] = useState(moment(Date()).format('YYYY-MM-DD'));

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === EXPENSES)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getAllBankExpensesTransactions();
  }, []);

  useEffect(() => {
    const delayDebounce = setTimeout(async () => {
      getBankExpensesTransactions({ search });
    }, 1000);
    return () => clearTimeout(delayDebounce);
  }, [search]);

  const getAllBankExpensesTransactions = () => {
    getBankExpensesTransactions({ from_date, to_date });
  };

  const handleHistory = () => {
    history.push('#');
  };

  const handleSaleSearch = (value) => {
    setSearch(value)
  };

  const tableHead = [
    { field: 'date', title: 'Date' },
    { field: 'bank_account.account_name', title: 'Account Name' },
    { field: 'bank_account.account_no', title: 'Account Number' },
    { field: 'bank_account.bank_name', title: 'Bank Name' },
    { field: 'description', title: 'Description' },
    { field: 'amount', title: 'Amount (৳)', render: (rowData) => Math.abs(rowData.amount) }
  ];

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/accounts'}>Accounts</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/accounts/expenses/all'}>Expenses</Link>
      </li>
    </ol>
  );
  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={ACCOUNTS_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>New Expense Entry</h5>
                <p className={'mb-0'}>Add new expense</p>
              </div>
              <div>
                <Link
                  to={'/accounts/expenses/new'}
                  className="btn btn-primary p-2 rounded text-decoration-none"
                >
                  <i className="far fa-plus-square me-2" /> Add Expense
                </Link>
              </div>
            </div>
          </div>
        </div>

        <FilterLayout
          handleActions={getAllBankExpensesTransactions}
          setFromDate={setFromDate}
          setToDate={setToDate}
        />

        <div id="card-content" className={'mt-4'}>
          <div className={'overflow-auto'}>
            <MaterialTable
              title={'All Expenses'}
              columns={tableHead}
              data={expenseTransactions?.paginatedData}
              isLoading={isLoading}
              totalCount={expenseTransactions?.total}
              options={{
                headerStyle: {
                  backgroundColor: '#F6F9FC',
                  color: '#000'
                },
                rowStyle: (rowData) => {
                  return {
                    fontSize: '14px',
                    color: '#000'
                  };
                },
                padding: 'default',
                pageSize: 20,
                actionsColumnIndex: -1,
                pageSizeOptions: [10, 25, 50],
                showFirstLastPageButtons: true,
                exportAllData: true,
                exportFileName: `Expense transactions - ${moment(new Date()).format('LL')}`,
                exportButton: true,
                searchFieldAlignment: 'left',
                searchFieldStyle: {
                  marginLeft: '3rem'
                }
              }}
              onSearchChange={handleSaleSearch}
              components={{
                Pagination: (props) => (
                  <div className="text-center d-flex justify-content-end pagination mt-2">
                    <Pagination
                      color={'primary'}
                      count={expenseTransactions?.lastPage}
                      page={expenseTransactions?.currentPage}
                      siblingCount={2}
                      onChange={(e, value) =>
                        getBankExpensesTransactions({
                          from_date,
                          to_date,
                          page: value,
                          search
                        })
                      }
                    />
                  </div>
                )
              }}
              actions={[
                {
                  hidden: false,
                  icon: 'print',
                  onClick: (event, rowData) => handleHistory(),
                  isFreeAction: true,
                  tooltip: 'Print'
                },
                {
                  icon: 'refresh',
                  onClick: (event, rowData) => {},
                  isFreeAction: true,
                  tooltip: 'refresh'
                }
              ]}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

Expenses.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  bankings: PropTypes.object
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  bankings: state.bankings,
  auth: state.auth
});

export default connect(mapStateToProps, { getBankExpensesTransactions })(Expenses);
