import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { getIncomeCategories, getPaymentMethods } from '../../../actions/system';
import { getBankAccounts } from '../../../actions/bankings';
import moment from 'moment';
import { addCustomerPayment } from '../../../actions/customers';
import { CUSTOMERS_PAYMENTS } from '../../../permissions';
import { CUSTOMER_SECTIONS } from '../../../utils';

function AddCustomerPayment({
  isLoading,
  addCustomerPayment,
  getIncomeCategories,
  getPaymentMethods,
  getBankAccounts,
  auth: { permissions },
  system: { paymentMethods, incomeCategories },
  bankings: { bankAccounts },
  location
}) {
  let history = useHistory();
  const [formData, setFormData] = useState({
    id: '',
    trans_type: '',
    paymentMethodId: '',
    categoryId: '',
    bankAccountId: '',
    date: moment().format('YYYY-MM-DD'),
    amount: '',
    description: '',
    reference: ''
  });

  const {
    id,
    trans_type,
    paymentMethodId,
    categoryId,
    bankAccountId,
    date,
    amount,
    description,
    reference
  } = formData;
  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === CUSTOMERS_PAYMENTS)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const id = query.get('id');

    if (id) {
      setFormData({
        ...formData,
        id
      });
    }

    getPaymentMethods();
    getIncomeCategories();
    getBankAccounts();
  }, []);

  const onCancelButtonClicked = (e) => {
    history.goBack();
  };

  const transType = [{ title: 'Receive' }, { title: 'Payment' }];

  const onTransTypeChange = (event, value) => {
    setFormData({
      ...formData,
      trans_type: value
    });
  };

  const onAccountChangeHandler = (e, value) => {
    e.preventDefault();

    if (value) {
      setFormData({
        ...formData,
        bankAccountId: value.id
      });
    }
  };

  const onCategoryChangeHandler = (e, value) => {
    e.preventDefault();

    if (value) {
      setFormData({
        ...formData,
        categoryId: value.id
      });
    }
  };

  const saveCustomerPayment = (e) => {
    e.preventDefault();

    addCustomerPayment(formData);
  };

  const onPaymentMethodSelected = (e, value) => {
    e.preventDefault();

    setFormData({
      ...formData,
      paymentMethodId: value ? value.id : ''
    });
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/customers'}>Customers</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/customers/add-payment'}>Add Customer Payment</Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={CUSTOMER_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <form id="card-content" onSubmit={(e) => saveCustomerPayment(e)}>
          <div className="card-header">
            <div className="d-flex justify-content-between align-items center">
              <div>
                <Fragment>
                  <h5>Add Customer Payment</h5>
                  <p>Add and manage customer payments</p>
                </Fragment>
              </div>
              <div>
                <button
                  type={'submit'}
                  className="btn btn-primary p-2 me-2"
                  disabled={
                    trans_type.length === 0 ||
                    paymentMethodId.length === 0 ||
                    categoryId.length === 0 ||
                    bankAccountId.length === 0
                  }
                >
                  Save
                </button>
                <button
                  onClick={onCancelButtonClicked}
                  type={'button'}
                  className="btn btn-danger p-2 text-light"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Date*</label>
                  <input
                    type="date"
                    name={'date'}
                    value={date}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    required
                    placeholder={'Enter date'}
                  />
                </div>

                <div className="form-group mt-3">
                  <label>Account*</label>
                  <Autocomplete
                    onChange={(event, value) => onAccountChangeHandler(event, value)}
                    options={bankAccounts}
                    renderOption={(option) => option.account_name}
                    getOptionLabel={(option) => {
                      // e.g value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      return option.account_name;
                    }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size={'small'}
                        placeholder={'Select Account'}
                        required
                        variant="outlined"
                      />
                    )}
                  />
                </div>

                <div className="form-group mt-3">
                  <label>Transaction Type*</label>
                  <Autocomplete
                    id="combo-box-demo"
                    options={transType.map((option) => option.title)}
                    onChange={onTransTypeChange}
                    freeSolo
                    value={trans_type}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size={'small'}
                        placeholder="Select an option"
                        variant="outlined"
                        required={true}
                      />
                    )}
                  />
                </div>

                <div className="form-group mt-3">
                  <label>Amount*</label>
                  <input
                    type="number"
                    name={'amount'}
                    required
                    value={amount}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Amount'}
                  />
                </div>

                <div className="form-group mt-3">
                  <label>Category*</label>
                  <Autocomplete
                    onChange={(event, value) => onCategoryChangeHandler(event, value)}
                    options={incomeCategories}
                    renderOption={(option) => option.name}
                    getOptionLabel={(option) => {
                      // e.g value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      return option.name;
                    }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size={'small'}
                        placeholder={'Select Category'}
                        required
                        variant="outlined"
                      />
                    )}
                  />
                </div>
                <div className="form-group mt-3">
                  <label>Payment Method*</label>
                  <Autocomplete
                    onChange={(event, value) => onPaymentMethodSelected(event, value)}
                    options={paymentMethods}
                    renderOption={(option) => option.name}
                    getOptionLabel={(option) => {
                      // e.g value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      return option.name;
                    }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size={'small'}
                        placeholder={'Select Payment Method'}
                        required
                        variant="outlined"
                      />
                    )}
                  />
                </div>

                <div className="form-group mt-3">
                  <label>Description*</label>
                  <input
                    type="text"
                    name={'description'}
                    value={description}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    required
                    placeholder={'Enter description'}
                  />
                </div>

                <div className="form-group mt-3">
                  <label>Reference</label>
                  <input
                    type="text"
                    name={'reference'}
                    value={reference}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Reference'}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
}

AddCustomerPayment.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getBankAccounts: PropTypes.func.isRequired,
  getPaymentMethods: PropTypes.func.isRequired,
  getIncomeCategories: PropTypes.func.isRequired,
  addCustomerPayment: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  system: state.system,
  bankings: state.bankings,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getBankAccounts,
  getPaymentMethods,
  getIncomeCategories,
  addCustomerPayment
})(AddCustomerPayment);
