export const SALES_ENTRY = 'Sales Entry';
export const MODIFY_SALES = 'Modify Sales';
export const SALES_INVOICES = 'Sales Invoices';
export const SALES_RETURN = 'Sales Return';
export const SALES_EXCHANGE = 'Sales Exchange';
export const QUOTATIONS = 'Quotation';

export const PURCHASE_ENTRY = 'Purchase Entry';
export const PURCHASE_INVOICES = 'Purchase Invoices';
export const PURCHASE_RETURN = 'Purchase Return';
export const MODIFY_PURCHASE = 'Modify Purchase';

export const PRODUCT_ENTRY = 'Product Entry';
export const MODIFY_PRODUCTS = 'Modify Products';
export const SHOW_PURCHASE_PRICE = 'Show Purchase Price';
export const STOCKS = 'Stocks';
export const STOCKS_TRANSFERS = 'Stocks Transfer';
export const STOCKS_REPORTS = 'Stocks Reports';

export const CUSTOMERS = 'Customers';
export const CUSTOMER_ENTRY = 'Customer Entry';
export const MODIFY_CUSTOMERS = 'Modify Customers';
export const CUSTOMERS_PAYMENTS = 'Customers Payments';
export const CUSTOMERS_DUE = 'Customer Due';

export const SUPPLIERS = 'Suppliers';
export const SUPPLIER_ENTRY = 'Supplier Entry';
export const MODIFY_SUPPLIERS = 'Modify Suppliers';
export const SUPPLIERS_PAYMENTS = 'Suppliers Payments';
export const SUPPLIERS_DUE = 'Supplier Due';

export const ACCOUNTS = 'Accounts';
export const TRANSACTIONS = 'Transactions';
export const DEPOSITS = 'Deposits';
export const EXPENSES = 'Expenses';
export const TRANSFERS = 'Transfers';

export const EMPLOYEE = 'Employee';
export const DESIGNATIONS = 'Designations';
export const DEPARTMENTS = 'Departments';
export const SALARY_HISTORY = 'Salary History';

export const SALES_REPORTS = 'Sales Reports';
export const PURCHASE_REPORTS = 'Purchase Reports';
export const EXPENSE_REPORTS = 'Expense Reports';
export const SALES_HISTORY_REPORTS = 'Sales History Reports';
export const INCOME_EXPENSE_REPORTS = 'Income Expense Reports';
export const INCOME_REPORTS = 'Income Reports';

export const USERS = 'Users';
export const ROLES = 'Roles';
export const USER_ACCESS = 'User Access';
export const COMPANY_INFORMATIONS = 'Company Informations';

export const SETTINGS = 'Settings';
export const BRANCHES = 'Branches';

export const MANAGE_PACKAGING = 'Manage Packaging';

export const isNotSalesHidden = (permissions) => {
  return (
    !!permissions.find((item) => item.name === SALES_ENTRY) ||
    !!permissions.find((item) => item.name === MODIFY_SALES) ||
    !!permissions.find((item) => item.name === SALES_INVOICES) ||
    !!permissions.find((item) => item.name === SALES_RETURN) ||
    !!permissions.find((item) => item.name === QUOTATIONS) ||
    !!permissions.find((item) => item.name === SALES_EXCHANGE)
  );
};

export const isNotPurchaseHidden = (permissions) => {
  return (
    !!permissions.find((item) => item.name === PURCHASE_ENTRY) ||
    !!permissions.find((item) => item.name === PURCHASE_INVOICES) ||
    !!permissions.find((item) => item.name === PURCHASE_RETURN) ||
    !!permissions.find((item) => item.name === MODIFY_PURCHASE)
  );
};

export const isNotInventoryHidden = (permissions) => {
  return (
    !!permissions.find((item) => item.name === PRODUCT_ENTRY) ||
    !!permissions.find((item) => item.name === MODIFY_PRODUCTS) ||
    !!permissions.find((item) => item.name === SHOW_PURCHASE_PRICE) ||
    !!permissions.find((item) => item.name === STOCKS) ||
    !!permissions.find((item) => item.name === STOCKS_TRANSFERS) ||
    !!permissions.find((item) => item.name === STOCKS_REPORTS)
  );
};

export const isNotCustomerHidden = (permissions) => {
  return (
    !!permissions.find((item) => item.name === CUSTOMER_ENTRY) ||
    !!permissions.find((item) => item.name === CUSTOMERS)
  );
};

export const isNotSupplierHidden = (permissions) => {
  return (
    !!permissions.find((item) => item.name === SUPPLIER_ENTRY) ||
    !!permissions.find((item) => item.name === SUPPLIERS)
  );
};

export const isNotAccountsHidden = (permissions) => {
  return (
    !!permissions.find((item) => item.name === ACCOUNTS) ||
    !!permissions.find((item) => item.name === TRANSFERS) ||
    !!permissions.find((item) => item.name === DEPOSITS) ||
    !!permissions.find((item) => item.name === EXPENSES) ||
    !!permissions.find((item) => item.name === TRANSACTIONS)
  );
};

export const isNotEmployeeHidden = (permissions) => {
  return (
    !!permissions.find((item) => item.name === EMPLOYEE) ||
    !!permissions.find((item) => item.name === DESIGNATIONS) ||
    !!permissions.find((item) => item.name === DEPARTMENTS) ||
    !!permissions.find((item) => item.name === SALARY_HISTORY)
  );
};

export const isNotReportsHidden = (permissions) => {
  return (
    !!permissions.find((item) => item.name === SALES_REPORTS) ||
    !!permissions.find((item) => item.name === PURCHASE_REPORTS) ||
    !!permissions.find((item) => item.name === EXPENSE_REPORTS) ||
    !!permissions.find((item) => item.name === SALES_HISTORY_REPORTS) ||
    !!permissions.find((item) => item.name === INCOME_EXPENSE_REPORTS) ||
    !!permissions.find((item) => item.name === INCOME_REPORTS)
  );
};

export const isNotAdminHidden = (permissions) => {
  return (
    !!permissions.find((item) => item.name === USERS) ||
    !!permissions.find((item) => item.name === ROLES) ||
    !!permissions.find((item) => item.name === USER_ACCESS) ||
    !!permissions.find((item) => item.name === COMPANY_INFORMATIONS)
  );
};

export const isNotSettingsHidden = (permissions) => {
  return (
    !!permissions.find((item) => item.name === SETTINGS) ||
    !!permissions.find((item) => item.name === BRANCHES)
  );
};

export const isNotPackagingHidden = (permissions) => {
  return !!permissions.find((item) => item.name === MANAGE_PACKAGING);
};
