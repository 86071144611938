import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAllSupplierPayment } from '../../../actions/suppliers';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Pagination } from '@material-ui/lab';
import FilterLayout from '../../layout/FilterLayout';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import { SUPPLIER_SECTIONS } from '../../../utils';

function AllSupplierPayments({
  isLoading,
  getAllSupplierPayment,
  suppliers: { allSupplierPayments }
}) {
  const history = useHistory();
  const [from_date, setFromDate] = useState(
    moment(Date()).subtract(30, 'days').format('YYYY-MM-DD')
  );
  const [to_date, setToDate] = useState(moment(Date()).format('YYYY-MM-DD'));

  useEffect(() => {
    getSupplierPayment();
  }, []);

  const getSupplierPayment = () => {
    getAllSupplierPayment({ from_date, to_date });
  };

  const handleSaleDetails = (id) => {
    history.push({
      pathname: '/sale/details',
      search: `?id=${id}`
    });
  };

  const handleSupplierDetails = (id) => {
    history.push({
      pathname: '/suppliers/details',
      search: `?id=${id}`
    });
  };

  const tableHead = [
    { field: 'date', title: 'Date' },
    {
      field: 'saleOrderId',
      title: 'Inv. No',
      render: (rowData) => (
        <span
          className={'font-weight-bold link-primary hover'}
          onClick={() => handleSaleDetails(rowData.saleOrderId)}
        >{`INV-${rowData.saleOrderId}`}</span>
      )
    },
    {
      field: 'supplier.name',
      title: 'Supplier Name',
      render: (rowData) => (
        <span
          className={'font-weight-bold link-primary hover'}
          onClick={() => handleSupplierDetails(rowData.supplier.id)}
        >
          {rowData.supplier.name}
        </span>
      )
    },
    { field: 'supplier.phone', title: 'Supplier Phone' },
    { field: 'bank_tran.bank_account.account_name', title: 'Account' },
    { field: 'description', title: 'Description' },
    { field: 'reference', title: 'Reference' },
    { field: 'amount', title: 'Amount (৳)' }
  ];

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/suppliers'}>Supplier</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/suppliers/payments'}>Payments</Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={SUPPLIER_SECTIONS} />

      <div className="container-fluid">
        {/*<div id="card-content">*/}
        {/*    <div className="card-header">*/}
        {/*        <div className="d-flex justify-content-between align-items-center">*/}
        {/*            <div>*/}
        {/*                <h5>All Supplier Payments</h5>*/}
        {/*                <p className={"mb-0"}>Supplier payments of all the invoices</p>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}

        <FilterLayout
          handleActions={getSupplierPayment}
          setFromDate={setFromDate}
          setToDate={setToDate}
        />

        {allSupplierPayments && (
          <div id="card-content" className={'mt-4'}>
            <div className={'overflow-auto'}>
              <MaterialTable
                title={'Supplier Payments'}
                columns={tableHead}
                data={allSupplierPayments?.paginatedData}
                isLoading={isLoading}
                totalCount={allSupplierPayments?.total}
                options={{
                  headerStyle: {
                    backgroundColor: '#F6F9FC',
                    color: '#000'
                  },
                  rowStyle: () => {
                    return {
                      fontSize: '14px',
                      color: '#000'
                    };
                  },
                  padding: 'default',
                  pageSize: 20,
                  actionsColumnIndex: -1,
                  pageSizeOptions: [10, 25, 50],
                  showFirstLastPageButtons: true,
                  exportAllData: true,
                  exportFileName: `All Supplier Payments - ${moment(new Date()).format('LL')}`,
                  exportButton: true,
                  searchFieldAlignment: 'left',
                  searchFieldStyle: {
                    marginLeft: '3rem'
                  }
                }}
                components={{
                  Pagination: () => (
                    <div className="text-center d-flex justify-content-end pagination mt-2">
                      <Pagination
                        color={'primary'}
                        count={allSupplierPayments.lastPage}
                        page={allSupplierPayments.currentPage}
                        siblingCount={2}
                        onChange={(e, value) =>
                          getAllSupplierPayment({
                            from_date,
                            to_date,
                            page: value
                          })
                        }
                      />
                    </div>
                  )
                }}
              />
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}

AllSupplierPayments.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  suppliers: PropTypes.object.isRequired,
  getAllSupplierPayment: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  suppliers: state.suppliers
});

export default connect(mapStateToProps, { getAllSupplierPayment })(AllSupplierPayments);
