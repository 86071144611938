import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import { getAllCustomerPayments, getCustomers } from '../../../actions/customers';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import {
  CUSTOMER_ENTRY,
  CUSTOMERS,
  CUSTOMERS_DUE,
  CUSTOMERS_PAYMENTS,
  isNotCustomerHidden
} from '../../../permissions';
import { CUSTOMER_SECTIONS } from '../../../utils';

function Customer({
  isLoading,
  getAllCustomerPayments,
  auth: { permissions },
  getCustomers,
  customers: { customers, allCustomerPayments }
}) {
  let history = useHistory();

  useEffect(() => {
    if (permissions.length > 0) {
      if (!isNotCustomerHidden(permissions)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getCustomers({ size: 5 });
    getAllCustomerPayments({ size: 5 });
  }, []);

  const handleDetails = (id) => {
    history.push({
      pathname: '/customers/details',
      search: `?id=${id}`
    });
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/customers'}>Customers</Link>
      </li>
    </ol>
  );
  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={CUSTOMER_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        {permissions.find((item) => item.name === CUSTOMER_ENTRY) && (
          <div id="card-content">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5>New Customer</h5>
                  <p className={'mb-0'}>Add a new customer details</p>
                </div>
                <div>
                  <Link
                    to={'/customers/add-customer'}
                    className="btn btn-primary p-2 rounded text-decoration-none"
                  >
                    <i className="far fa-plus-square me-2" /> Add Customer
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row mt-4">
          {permissions.find((item) => item.name === CUSTOMERS) && (
            <div className="col-md-5">
              <div id="card-content" className={'h-100'}>
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Customers</h5>
                      <p>Create and manage customers</p>
                    </div>
                    <div>
                      {permissions.find((item) => item.name === CUSTOMER_ENTRY) && (
                        <Link to={'/customers/add-customer'} className="btn btn-primary me-2">
                          Add
                        </Link>
                      )}
                      <Link to={'/customers/all'} className="btn btn-outline-primary">
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body mt-1">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Customer name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Phone</TableCell>
                          {permissions.find((item) => item.name === CUSTOMERS_DUE) && (
                            <TableCell>Due (৳)</TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {customers &&
                          customers.paginatedData.map((row) => (
                            <TableRow
                              className={'hover'}
                              onClick={() => handleDetails(row.id)}
                              key={row.name}
                            >
                              <TableCell component="th" scope="row">
                                {row.name}
                              </TableCell>
                              <TableCell>{row.email}</TableCell>
                              <TableCell>{row.phone}</TableCell>
                              {permissions.find((item) => item.name === CUSTOMERS_DUE) && (
                                <TableCell>{row.due}</TableCell>
                              )}
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}

          {permissions.find((item) => item.name === CUSTOMERS_PAYMENTS) && (
            <div className="col-md-7">
              <div id="card-content" className={'h-100'}>
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Customer Payments</h5>
                      <p>All customer payments</p>
                    </div>
                    <div>
                      <Link to={'/customers/payments'} className="btn btn-primary">
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body mt-1">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Customer name</TableCell>
                          <TableCell>Inv. No</TableCell>
                          <TableCell>Account</TableCell>
                          <TableCell>Reference</TableCell>
                          <TableCell>Amount (৳)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allCustomerPayments &&
                          allCustomerPayments.paginatedData.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell component="th" scope="row">
                                {row.customer.name}
                              </TableCell>
                              <TableCell>{`INV-${row.saleOrderId}`}</TableCell>
                              <TableCell>{row.bank_tran.bank_account.account_name}</TableCell>
                              <TableCell>{row.reference}</TableCell>
                              <TableCell>{row.amount}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

Customer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  customers: PropTypes.array.isRequired,
  getCustomers: PropTypes.func.isRequired,
  getAllCustomerPayments: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  customers: state.customers,
  auth: state.auth
});

export default connect(mapStateToProps, { getCustomers, getAllCustomerPayments })(Customer);
