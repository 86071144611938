import React from 'react';
import { Link } from 'react-router-dom';

function NoPermission() {
  return (
    <div className={'not-found d-flex justify-content-center align-items-center'}>
      <div>
        <h1>No Access!</h1>
        <h4 className={'mt-4'}>You don&lsquo;t have permission to view this page</h4>
        <p className={'m-3'}>
          You are restricted to visit his page. Please contact admin to know more.
        </p>
        <Link to={'/home'} className="btn btn-primary mt-2">
          GO TO HOME
        </Link>
      </div>
    </div>
  );
}

export default NoPermission;
