import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ADMINISTRATION_SECTIONS, areas, districts, divisions } from '../../../../utils';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../../layout/Breadcrumb';
import LoadingBackdrop from '../../../layout/LoadingBackdrop';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { getBranchDetails, updateBranch } from '../../../../actions/system';
import { BRANCHES } from '../../../../permissions';

function UpdateBranch({
  isLoading,
  updateBranch,
  location,
  getBranchDetails,
  auth: { permissions },
  system: { branchDetails }
}) {
  const history = useHistory();
  const initState = {
    branchId: branchDetails ? branchDetails.id : '',
    name: branchDetails ? branchDetails.name : '',
    addressId: branchDetails ? branchDetails.address.id : '',
    address: branchDetails ? branchDetails.address.address : '',
    division: branchDetails ? branchDetails.address.division : '',
    city: branchDetails ? branchDetails.address.city : '',
    area: branchDetails ? branchDetails.address.area : '',
    zip: branchDetails ? branchDetails.address.zip : ''
  };
  const [formData, setFormData] = useState(initState);
  const { branchId, name, address, division, city, area, zip, addressId } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const id = query.get('id');

    if (id) {
      getBranchDetails(id);
    }
  }, []);

  useEffect(() => {
    setFormData({
      ...formData,
      ...initState
    });
  }, [branchDetails]);

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === BRANCHES)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  const onCancelButtonClicked = (e) => {
    history.goBack();
  };

  const saveBranch = (e) => {
    e.preventDefault();

    updateBranch(formData, branchId);
  };

  const onDivisionSelected = (e, value) => {
    setFormData({
      ...formData,
      division: value
    });
  };

  const onCitySelected = (e, value) => {
    setFormData({
      ...formData,
      city: value
    });
  };

  const onAreaSelected = (e, value) => {
    setFormData({
      ...formData,
      area: value
    });
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/settings'}>Settings</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/branches'}>Branches</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={`/settings/update-branch?id=${branchDetails && branchDetails.id}`}>
          Update Branch
        </Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={ADMINISTRATION_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <form id="card-content" onSubmit={(e) => saveBranch(e)}>
          <div className="card-header">
            <div className="d-flex justify-content-between align-items center">
              <div>
                <h5>Update branch</h5>
                <p>Update and manage branch</p>
              </div>
              <div>
                <button
                  type={'submit'}
                  className="btn btn-primary p-2 me-2"
                  disabled={name.length === 0 || address === 0}
                >
                  Update
                </button>
                <button
                  onClick={onCancelButtonClicked}
                  type={'button'}
                  className="btn btn-danger p-2 text-light"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <div className="card-body mt-2">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor={'name'}>Branch Name:</label>
                  <input
                    type="text"
                    name={'name'}
                    required
                    value={name}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter branch name'}
                  />
                </div>
              </div>

              <h5 className={'mt-4'}>Address</h5>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor={'address'}>Address:</label>
                  <input
                    type="text"
                    name={'address'}
                    value={address}
                    required
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter Address'}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
}

UpdateBranch.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  updateBranch: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  auth: state.auth,
  system: state.system
});

export default connect(mapStateToProps, { updateBranch, getBranchDetails })(UpdateBranch);
