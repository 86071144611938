import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import Typography from '@material-ui/core/Typography';
import { Box, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AllInventory from './AllInventory';
import AllStock from './AllStock';
import AllTransfers from './AllTransfers';
import StockInReport from './reports/StockInReport';
import StockOutReport from './reports/StockOutReport';
import {
  isNotInventoryHidden,
  STOCKS,
  STOCKS_REPORTS,
  STOCKS_TRANSFERS
} from '../../../permissions';
import { INVENTORY_SECTIONS } from '../../../utils';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#fff'
  }
}));

function Inventory({ isLoading, auth: { user, permissions } }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/inventory'}>Inventory</Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={INVENTORY_SECTIONS} />

      {permissions.find((item) => item.name === STOCKS) &&
        permissions.find((item) => item.name === STOCKS_TRANSFERS) &&
        permissions.find((item) => item.name === STOCKS_REPORTS) && (
          <div id={'card-content'} className={`${classes.root} mt-4 pt-0`}>
            <div className="card-header">
              <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="All" {...a11yProps(0)} />
                <Tab label="Stock" {...a11yProps(1)} />
                <Tab label="Purchased" {...a11yProps(2)} />
                <Tab label="Sold" {...a11yProps(3)} />
                <Tab label="Transfers" {...a11yProps(4)} />
              </Tabs>
            </div>
            <TabPanel value={value} index={0}>
              <AllInventory />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <AllStock />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <StockInReport />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <StockOutReport />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <AllTransfers />
            </TabPanel>
          </div>
        )}

      {/*If no permissions given*/}
      {!isNotInventoryHidden(permissions) && (
        <div id={'card-content'} className={`${classes.root} mt-4 pt-0`}>
          <div className="card-header">
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
              <Tab label="All" {...a11yProps(0)} />
            </Tabs>
          </div>
          <TabPanel value={value} index={0}>
            <AllInventory />
          </TabPanel>
        </div>
      )}

      {!permissions.find((item) => item.name === STOCKS) &&
        permissions.find((item) => item.name === STOCKS_TRANSFERS) &&
        !permissions.find((item) => item.name === STOCKS_REPORTS) && (
          <div id={'card-content'} className={`${classes.root} mt-4 pt-0`}>
            <div className="card-header">
              <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="All" {...a11yProps(0)} />
                <Tab label="Transfers" {...a11yProps(1)} />
              </Tabs>
            </div>
            <TabPanel value={value} index={0}>
              <AllInventory />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <AllTransfers />
            </TabPanel>
          </div>
        )}

      {!permissions.find((item) => item.name === STOCKS) &&
        !permissions.find((item) => item.name === STOCKS_TRANSFERS) &&
        permissions.find((item) => item.name === STOCKS_REPORTS) && (
          <div id={'card-content'} className={`${classes.root} mt-4 pt-0`}>
            <div className="card-header">
              <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="All" {...a11yProps(0)} />
                <Tab label="Stock In" {...a11yProps(1)} />
                <Tab label="Stock Out" {...a11yProps(2)} />
              </Tabs>
            </div>
            <TabPanel value={value} index={0}>
              <AllInventory />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <StockInReport />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <StockOutReport />
            </TabPanel>
          </div>
        )}

      {permissions.find((item) => item.name === STOCKS) &&
        !permissions.find((item) => item.name === STOCKS_TRANSFERS) &&
        !permissions.find((item) => item.name === STOCKS_REPORTS) && (
          <div id={'card-content'} className={`${classes.root} mt-4 pt-0`}>
            <div className="card-header">
              <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="All" {...a11yProps(0)} />
                <Tab label="Stock In" {...a11yProps(2)} />
                <Tab label="Stock Out" {...a11yProps(3)} />
              </Tabs>
            </div>
            <TabPanel value={value} index={0}>
              <AllInventory />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <StockInReport />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <StockOutReport />
            </TabPanel>
          </div>
        )}

      {!permissions.find((item) => item.name === STOCKS) &&
        permissions.find((item) => item.name === STOCKS_TRANSFERS) &&
        permissions.find((item) => item.name === STOCKS_REPORTS) && (
          <div id={'card-content'} className={`${classes.root} mt-4 pt-0`}>
            <div className="card-header">
              <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="All" {...a11yProps(0)} />
                <Tab label="Stock In" {...a11yProps(1)} />
                <Tab label="Stock Out" {...a11yProps(2)} />
                <Tab label="Transfers" {...a11yProps(3)} />
              </Tabs>
            </div>
            <TabPanel value={value} index={0}>
              <AllInventory />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <StockInReport />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <StockOutReport />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <AllTransfers />
            </TabPanel>
          </div>
        )}

      {permissions.find((item) => item.name === STOCKS) &&
        !permissions.find((item) => item.name === STOCKS_TRANSFERS) &&
        permissions.find((item) => item.name === STOCKS_REPORTS) && (
          <div id={'card-content'} className={`${classes.root} mt-4 pt-0`}>
            <div className="card-header">
              <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="All" {...a11yProps(0)} />
                <Tab label="Stock" {...a11yProps(1)} />
                <Tab label="Stock In" {...a11yProps(2)} />
                <Tab label="Stock Out" {...a11yProps(3)} />
              </Tabs>
            </div>
            <TabPanel value={value} index={0}>
              <AllInventory />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <AllStock />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <StockInReport />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <StockOutReport />
            </TabPanel>
          </div>
        )}

      {permissions.find((item) => item.name === STOCKS) &&
        permissions.find((item) => item.name === STOCKS_TRANSFERS) &&
        !permissions.find((item) => item.name === STOCKS_REPORTS) && (
          <div id={'card-content'} className={`${classes.root} mt-4 pt-0`}>
            <div className="card-header">
              <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="All" {...a11yProps(0)} />
                <Tab label="Stock" {...a11yProps(1)} />
                <Tab label="Transfers" {...a11yProps(2)} />
              </Tabs>
            </div>
            <TabPanel value={value} index={0}>
              <AllInventory />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <AllStock />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <AllTransfers />
            </TabPanel>
          </div>
        )}
    </Fragment>
  );
}

Inventory.propTypes = {
  isLoading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  auth: state.auth
});

export default connect(mapStateToProps, {})(Inventory);
