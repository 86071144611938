import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import { deleteQuotation, getQuotationDetails } from '../../../actions/sale';
import MaterialTable from 'material-table';
import numWords from 'num-words';
import Swal from 'sweetalert2';
import { QUOTATIONS } from '../../../permissions';
import { REGEX_STRING, SALE_SECTIONS } from '../../../utils';

function QuotationDetails({
  isLoading,
  deleteQuotation,
  auth: { permissions },
  sale: { quotationDetails },
  getQuotationDetails,
  location
}) {
  const history = useHistory();

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === QUOTATIONS)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const id = query.get('id');

    if (id) {
      getQuotationDetails(id);
    }
  }, []);

  const calculateTwoIntegersFromIntegersOfArray = (array) => {
    const add = (a, b) => a + b;
    return array.reduce(add);
  };

  const moveTo = (link, id) => {
    history.push({
      pathname: link,
      search: `?id=${id}`
    });
  };

  const deleteQuotationInvoice = (e) => {
    e.preventDefault();

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteQuotation(quotationDetails.id);
      }
    });
  };

  const goBackButton = () => {
    history.goBack();
  };

  const printQuotation = (id) => {
    history.push({
      pathname: '/sale/quotations/print',
      search: `?id=${id}`
    });
  };

  const tableHead = [
    {
      field: 'tableData.id',
      title: 'SL',
      cellStyle: {
        width: '5%'
      },
      render: (rowData) => {
        return rowData.tableData.id + 1;
      }
    },
    {
      field: 'description',
      title: 'Description',
      cellStyle: {
        width: '30%'
      },
      render: (rowData) => {
        return rowData.description.includes('(') ? (
          <p className={'m-0'}>
            {rowData.description.split('(')[0]} (
            <span className={'font-weight-bold'}>{REGEX_STRING.exec(rowData.description)[1]}</span>)
          </p>
        ) : (
          rowData.description
        );
      }
    },
    { field: 'branch.name', title: 'Branch' },
    { field: 'qty', title: 'Quantity' },
    { field: 'unit_price', title: 'Rate (৳)' },
    { field: 'discount', title: 'Discount (%)' },
    { field: 'total', title: 'Amount (৳)' }
  ];
  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/sale'}>Sale</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/sale/quotations/all'}>Invoices</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={`/sale/quotations/details?id=${quotationDetails && quotationDetails.id}`}>
          Details
        </Link>
      </li>
    </ol>
  );
  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={SALE_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Quotation Details</h5>
                <p className="mb-0">See quotation details, manage returns and edit quotation</p>
              </div>
              <div>
                <button
                  className={'btn btn-primary me-2'}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={(e) =>
                    moveTo('/sale/quotations/update', quotationDetails && quotationDetails.id)
                  }
                >
                  <i className="fas fa-pen me-2" /> Edit Quotation
                </button>

                <button
                  className={'btn btn-primary me-2'}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={(e) => printQuotation(quotationDetails && quotationDetails.id)}
                >
                  <i className="fas fa-print me-2" /> Print
                </button>

                <button
                  className={'btn btn-danger text-white me-2'}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={(e) => deleteQuotationInvoice(e)}
                >
                  <i className="far fa-trash-alt me-2" /> Delete
                </button>
                <button className="btn btn-outline-primary" onClick={goBackButton}>
                  Go Back
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div id="card-content" className={'mt-4'}>
              <div className="card-body">
                <div className="row justify-content-between">
                  <div className="col-md-5">
                    <p className={' mb-1'}>
                      <span className={'font-weight-bold'}>Customer Name: &emsp;</span>{' '}
                      {quotationDetails ? quotationDetails.customer.name : ''}
                    </p>
                    <p className={'mb-1'}>
                      <span className={'font-weight-bold'}>Customer Phone: &emsp;</span>{' '}
                      {quotationDetails ? quotationDetails.customer.phone : ''}
                    </p>
                    {quotationDetails && (
                      <p>
                        <span className={'font-weight-bold'}>Address: &emsp;</span>{' '}
                        {quotationDetails.customer.address}
                      </p>
                    )}
                  </div>
                  <div className="col-md-5">
                    <p className={' mb-1'}>
                      <span className={'font-weight-bold'}>Invoice No: &emsp;</span>{' '}
                      {quotationDetails ? quotationDetails.id : ''}
                    </p>
                    <p className={' mb-1'}>
                      <span className={'font-weight-bold'}>Date: &emsp;</span>{' '}
                      {quotationDetails ? quotationDetails.date : ''}
                    </p>
                  </div>
                </div>

                {quotationDetails && (
                  <div className={'mt-4 d-grid'}>
                    <div style={{ overflow: 'auto' }} className={'sales-table'}>
                      <MaterialTable
                        columns={tableHead}
                        data={quotationDetails?.quotationItems}
                        totalCount={quotationDetails?.quotationItems.length}
                        options={{
                          toolbar: false,
                          exportButton: false,
                          showTitle: false,
                          headerStyle: {
                            padding: 4,
                            backgroundColor: '#F5F7FA'
                          },
                          padding: 'default',
                          pageSize: quotationDetails?.quotationItems.length,
                          paging: false,
                          actionsColumnIndex: -1
                        }}
                      />
                    </div>

                    <div className="row justify-content-end mt-4">
                      <div className="col-2 p-0">
                        <p className={'font-weight-bold'}>Total Qty:</p>
                        <p className={'font-weight-bold'}>Sub Total:</p>
                        <p className={'font-weight-bold'}>vat:</p>
                        <p className={'font-weight-bold'}>Transport Cost:</p>
                        <p className={'font-weight-bold mb-0'}>Discount:</p>

                        <div className="divider" />
                        <p className={'font-weight-bold'}>Total:</p>
                      </div>
                      <div className="col-1 p-0">
                        <p>
                          {quotationDetails
                            ? calculateTwoIntegersFromIntegersOfArray(
                                quotationDetails.quotationItems.map((item) => item.qty)
                              )
                            : 0}
                        </p>
                        <p>{quotationDetails ? quotationDetails.sub_total : 0}</p>
                        <p>{quotationDetails ? quotationDetails.vat + '%' : 0}</p>
                        <p>{quotationDetails ? quotationDetails.transport_cost : 0}</p>
                        <p className={'mb-0'}>{quotationDetails ? quotationDetails.discount : 0}</p>

                        <div className="divider" />
                        <p>{quotationDetails ? quotationDetails.total : 0}</p>
                      </div>
                      <div className="col-1" />
                    </div>

                    <div className={'mt-3'}>
                      {/*<p><strong>Total (in*/}
                      {/*    word): </strong>{`${numWords(parseInt(quotationDetails ? quotationDetails.total : 0)).toUpperCase()} TAKA ONLY`}*/}
                      {/*</p>*/}
                      <p className={'mt-3'}>
                        <strong>Notes: </strong>
                        {quotationDetails ? quotationDetails.notes : ''}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

QuotationDetails.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getQuotationDetails: PropTypes.func.isRequired,
  sale: PropTypes.object.isRequired,
  deleteQuotation: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  sale: state.sale,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getQuotationDetails,
  deleteQuotation
})(QuotationDetails);
