import React from 'react';
import { filterProductVariation, getProductVariationStringForSale } from '../../utils';
import { CircularProgress, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

function FilterProductSelector({
  autocompleteRef,
  onProductSelected,
  products,
  branchId,
  searchLoading,
  getSearchedProducts
}) {
  return (
    <Autocomplete
      ref={autocompleteRef}
      onChange={(event, value) => onProductSelected(event, value)}
      options={filterProductVariation(products?.paginatedData, branchId)}
      getOptionLabel={(option) => getProductVariationStringForSale(option, branchId)}
      loading={searchLoading}
      onInputChange={(event, value, reason) => {
        if (reason !== 'reset' && value !== '') {
          getSearchedProducts(value);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          size={'small'}
          id={'products-autocomplete'}
          placeholder={'Select a product'}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {searchLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  );
}

export default FilterProductSelector;
