import React, { Fragment, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../../layout/Breadcrumb';
import LoadingBackdrop from '../../../layout/LoadingBackdrop';
import { connect } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { getRoles } from '../../../../actions/system';
import { addAccount } from '../../../../actions/accounts';
import { USERS } from '../../../../permissions';
import { ADMINISTRATION_SECTIONS } from '../../../../utils';

function AddUser({ isLoading, roles, auth: { permissions }, getRoles, addAccount }) {
  const history = useHistory();

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === USERS)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirm_password: '',
    roleId: '',
    roleText: ''
  });

  const { email, password, confirm_password, roleId, username, roleText } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    getRoles();
  }, []);

  const onCancelButtonClicked = (e) => {
    history.goBack();
  };

  const onRoleSelected = (e, value) => {
    if (value) {
      setFormData({
        ...formData,
        roleId: value.id,
        roleText: value.name
      });
    }
  };

  const saveNewUser = (e) => {
    e.preventDefault();

    const data = {
      username,
      email,
      password,
      confirm_password,
      roleId
    };
    addAccount(data);
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/administration'}>Administration</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/administration/add-user'}>Add User</Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={ADMINISTRATION_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <form id="card-content" onSubmit={(e) => saveNewUser(e)}>
          <div className="card-header">
            <div className="d-flex justify-content-between align-items center">
              <div>
                <h5>Register new user</h5>
                <p>Create and manage new users</p>
              </div>
              <div>
                <button
                  type={'submit'}
                  className="btn btn-primary p-2 me-2"
                  disabled={username.length === 0 || email.length === 0 || roleId.length === 0}
                >
                  Save
                </button>
                <button
                  onClick={onCancelButtonClicked}
                  type={'button'}
                  className="btn btn-danger p-2 text-light"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor={'username'}>Username:</label>
                  <input
                    type="text"
                    name={'username'}
                    required
                    value={username}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter username'}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor={'email'}>Email:</label>
                  <input
                    type="text"
                    name={'email'}
                    required
                    value={email}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter user email'}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor={'password'}>Password:</label>
                  <input
                    type="password"
                    name={'password'}
                    required
                    value={password}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter password'}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group mt-3">
                  <label htmlFor={'confirm_password'}>Confirm Password:</label>
                  <input
                    type="password"
                    name={'confirm_password'}
                    required
                    value={confirm_password}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter password again'}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group mt-3">
                  <label htmlFor={'role'}>Select Role</label>
                  <Autocomplete
                    id={'select-role'}
                    value={roleText}
                    onChange={(event, value) => onRoleSelected(event, value)}
                    options={roles}
                    style={{ width: '100%' }}
                    size="small"
                    renderOption={(option) => option.name}
                    getOptionLabel={(option) => {
                      // e.g value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.name;
                    }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            padding: 0,
                            height: '36px',
                            marginBottom: 4
                          }
                        }}
                        placeholder={'Select a role'}
                        required
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  roles: state.system.roles,
  auth: state.auth
});

export default connect(mapStateToProps, { getRoles, addAccount })(AddUser);
