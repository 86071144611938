import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../../layout/Breadcrumb';
import LoadingBackdrop from '../../../layout/LoadingBackdrop';
import {
  clearCustomPermissions,
  customPermissionSetup,
  defaultPermissionSetup,
  getPermissions,
  getRolePermissions,
  getUserPermissions
} from '../../../../actions/system';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { USER_ACCESS } from '../../../../permissions';
import { getLoggedUserPermissions } from '../../../../actions/auth';
import { ADMINISTRATION_SECTIONS } from '../../../../utils';

function Access({
  isLoading,
  system: { permissions, singlePermissions },
  defaultPermissionSetup,
  getRolePermissions,
  getPermissions,
  location,
  clearCustomPermissions,
  customPermissionSetup,
  getUserPermissions,
  getLoggedUserPermissions,
  auth: { permissions: userPermissions, user: loggedUser }
}) {
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [userId, setUserId] = useState();
  const [roleId, setRoleId] = useState();
  const history = useHistory();

  useEffect(() => {
    if (userPermissions.length > 0) {
      if (!userPermissions.find((item) => item.name === USER_ACCESS)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getPermissions();

    const query = new URLSearchParams(location.search);
    const role = query.get('role');

    if (role) {
      setRoleId(role);
      // Get role default permissions
      getRolePermissions(role);
    }

    const user = query.get('user');
    if (user) {
      setUserId(user);
      // Get user custom permissions
      getUserPermissions(user);
    }

    getLoggedUserPermissions(loggedUser.id);
  }, []);

  useEffect(() => {
    const roleArray = singlePermissions.map((item) => item.id);
    setSelectedRoles(roleArray);

    if (singlePermissions.length > 0 && singlePermissions.length === permissions.length) {
      setCheckAll(true);
    }
  }, [singlePermissions]);

  const handleCheckboxChange = (e, id) => {
    if (e.target.checked) {
      setSelectedRoles([...selectedRoles, id]);
    } else {
      const newResult = selectedRoles.filter((item) => item !== id);
      setSelectedRoles(newResult);
      setCheckAll(false);
    }
  };

  const handleCheckAll = (e) => {
    if (e.target.checked) {
      setCheckAll(true);
      setSelectedRoles(permissions.map((item) => item.id));
    } else {
      setCheckAll(false);
      setSelectedRoles([]);
    }
  };

  const saveAccess = (e) => {
    e.preventDefault();

    if (roleId) {
      defaultPermissionSetup({ roleId, permissionIds: selectedRoles.toString() });
    }

    if (userId) {
      customPermissionSetup({ userId, permissionIds: selectedRoles.toString() });
    }
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/administration'}>Administration</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/administration/access'}>Access</Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={ADMINISTRATION_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              {userId ? (
                <div>
                  <h5>Set Custom User Access</h5>
                  <p className={'mb-0'}>
                    Custom access will be override default access. Any default access won&apos;t
                    override user&apos;s custom access.
                  </p>
                </div>
              ) : (
                <div>
                  <h5>Set Default Role Access</h5>
                  <p className={'mb-0'}>
                    User that doesn&apos;t have any custom role setup according to there role this
                    access will be given.
                  </p>
                </div>
              )}
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkAll}
                      onChange={(e) => handleCheckAll(e)}
                      name="checkAll"
                      color="primary"
                    />
                  }
                  className={'me-5'}
                  label="Select All"
                />

                <button onClick={saveAccess} className="btn btn-primary p-2">
                  Save Access
                </button>

                {userId && (
                  <button
                    onClick={() => clearCustomPermissions({ userId })}
                    className="btn btn-danger p-2 ms-3 text-light"
                  >
                    Clear Custom Access
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-3">
            <div id="card-content" className={'h-100'}>
              <div className="card-header">
                <h5>Sales Module</h5>
              </div>
              <div className="card-body">
                {permissions
                  .filter((item) => item.type === 'Sales Module')
                  .map((item) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!selectedRoles.find((per) => per === item.id)}
                          onChange={(e) => handleCheckboxChange(e, item.id)}
                          name={item.name}
                          color="primary"
                        />
                      }
                      className={'w-100'}
                      label={item.name}
                    />
                  ))}
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div id="card-content" className={'h-100'}>
              <div className="card-header">
                <h5>Purchase Module</h5>
              </div>
              <div className="card-body">
                {permissions
                  .filter((item) => item.type === 'Purchase Module')
                  .map((item) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!selectedRoles.find((per) => per === item.id)}
                          onChange={(e) => handleCheckboxChange(e, item.id)}
                          name={item.name}
                          color="primary"
                        />
                      }
                      className={'w-100'}
                      label={item.name}
                    />
                  ))}
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div id="card-content" className={'h-100'}>
              <div className="card-header">
                <h5>Inventory</h5>
              </div>
              <div className="card-body">
                {permissions
                  .filter((item) => item.type === 'Inventory')
                  .map((item) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!selectedRoles.find((per) => per === item.id)}
                          onChange={(e) => handleCheckboxChange(e, item.id)}
                          name={item.name}
                          color="primary"
                        />
                      }
                      className={'w-100'}
                      label={item.name}
                    />
                  ))}
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div id="card-content" className={'h-100'}>
              <div className="card-header">
                <h5>Packaging Module</h5>
              </div>
              <div className="card-body">
                {permissions
                  .filter((item) => item.type === 'Packaging Module')
                  .map((item) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!selectedRoles.find((per) => per === item.id)}
                          onChange={(e) => handleCheckboxChange(e, item.id)}
                          name={item.name}
                          color="primary"
                        />
                      }
                      className={'w-100'}
                      label={item.name}
                    />
                  ))}
              </div>
            </div>
          </div>

          <div className="col-md-3  mt-4">
            <div id="card-content" className={'h-100'}>
              <div className="card-header">
                <h5>Customer Module</h5>
              </div>
              <div className="card-body">
                {permissions
                  .filter((item) => item.type === 'Customer Module')
                  .map((item) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!selectedRoles.find((per) => per === item.id)}
                          onChange={(e) => handleCheckboxChange(e, item.id)}
                          name={item.name}
                          color="primary"
                        />
                      }
                      className={'w-100'}
                      label={item.name}
                    />
                  ))}
              </div>
            </div>
          </div>

          <div className="col-md-3 mt-4">
            <div id="card-content" className={'h-100'}>
              <div className="card-header">
                <h5>Supplier Module</h5>
              </div>
              <div className="card-body">
                {permissions
                  .filter((item) => item.type === 'Supplier Module')
                  .map((item) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!selectedRoles.find((per) => per === item.id)}
                          onChange={(e) => handleCheckboxChange(e, item.id)}
                          name={item.name}
                          color="primary"
                        />
                      }
                      className={'w-100'}
                      label={item.name}
                    />
                  ))}
              </div>
            </div>
          </div>

          <div className="col-md-3 mt-4">
            <div id="card-content" className={'h-100'}>
              <div className="card-header">
                <h5>Account Module</h5>
              </div>
              <div className="card-body">
                {permissions
                  .filter((item) => item.type === 'Account Module')
                  .map((item) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!selectedRoles.find((per) => per === item.id)}
                          onChange={(e) => handleCheckboxChange(e, item.id)}
                          name={item.name}
                          color="primary"
                        />
                      }
                      className={'w-100'}
                      label={item.name}
                    />
                  ))}
              </div>
            </div>
          </div>

          <div className="col-md-3 mt-4">
            <div id="card-content" className={'h-100'}>
              <div className="card-header">
                <h5>Employee Module</h5>
              </div>
              <div className="card-body">
                {permissions
                  .filter((item) => item.type === 'Employee Module')
                  .map((item) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!selectedRoles.find((per) => per === item.id)}
                          onChange={(e) => handleCheckboxChange(e, item.id)}
                          name={item.name}
                          color="primary"
                        />
                      }
                      className={'w-100'}
                      label={item.name}
                    />
                  ))}
              </div>
            </div>
          </div>

          <div className="col-md-3 mt-4">
            <div id="card-content" className={'h-100'}>
              <div className="card-header">
                <h5>Report Module</h5>
              </div>
              <div className="card-body">
                {permissions
                  .filter((item) => item.type === 'Report Module')
                  .map((item) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!selectedRoles.find((per) => per === item.id)}
                          onChange={(e) => handleCheckboxChange(e, item.id)}
                          name={item.name}
                          color="primary"
                        />
                      }
                      className={'w-100'}
                      label={item.name}
                    />
                  ))}
              </div>
            </div>
          </div>

          <div className="col-md-3 mt-4">
            <div id="card-content" className={'h-100'}>
              <div className="card-header">
                <h5>Administration</h5>
              </div>
              <div className="card-body">
                {permissions
                  .filter((item) => item.type === 'Administration')
                  .map((item) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!selectedRoles.find((per) => per === item.id)}
                          onChange={(e) => handleCheckboxChange(e, item.id)}
                          name={item.name}
                          color="primary"
                        />
                      }
                      className={'w-100'}
                      label={item.name}
                    />
                  ))}
              </div>
            </div>
          </div>

          <div className="col-md-3 mt-4">
            <div id="card-content" className={'h-100'}>
              <div className="card-header">
                <h5>Settings</h5>
              </div>
              <div className="card-body">
                {permissions
                  .filter((item) => item.type === 'Settings')
                  .map((item) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!selectedRoles.find((per) => per === item.id)}
                          onChange={(e) => handleCheckboxChange(e, item.id)}
                          name={item.name}
                          color="primary"
                        />
                      }
                      className={'w-100'}
                      label={item.name}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

Access.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  system: PropTypes.object.isRequired,
  getPermissions: PropTypes.func.isRequired,
  getRolePermissions: PropTypes.func.isRequired,
  defaultPermissionSetup: PropTypes.func.isRequired,
  getUserPermissions: PropTypes.func.isRequired,
  customPermissionSetup: PropTypes.func.isRequired,
  clearCustomPermissions: PropTypes.func.isRequired,
  getLoggedUserPermissions: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  system: state.system,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getPermissions,
  getRolePermissions,
  defaultPermissionSetup,
  getUserPermissions,
  customPermissionSetup,
  clearCustomPermissions,
  getLoggedUserPermissions
})(Access);
