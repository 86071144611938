import React, { createElement, Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Breadcrumb from '../../layout/Breadcrumb';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import ReactToPrint from 'react-to-print';
import JsBarcode from 'jsbarcode';

function PrintBarcode({ isLoading, location }) {
  const componentRef = useRef();
  const history = useHistory();
  const [formData, setFormData] = useState({
    qty: 20,
    name: '',
    code: ''
  });
  const [barcodes, setBarcodes] = useState([]);
  const { qty, name, code } = formData;
  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const code = query.get('code');
    const name = query.get('name');

    setFormData({
      ...formData,
      name,
      code
    });
  }, []);

  const goBackButton = (e) => {
    e.preventDefault();

    history.goBack();
  };

  useEffect(() => {
    for (const barcode of barcodes) {
      JsBarcode(`#${barcode.props.id}`, code, {
        height: 40,
        format: 'CODE128B',
        fontSize: '14px'
      });
    }
  }, [barcodes]);

  const printBarcodes = (e) => {
    e.preventDefault();

    const newBarcodes = [];
    for (let i = 1; i <= qty; i++) {
      const barcode = createElement('img', {
        id: `barcode_${i}`,
        className: 'ms-4 mb-4 border'
      });
      newBarcodes.push(barcode);
    }
    setBarcodes(newBarcodes);
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/inventory'}>Inventory</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/print-barcodes'}>Print Barcodes</Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Print Product Barcodes</h5>
                <p>Enter barcode quantities, press print to generate barcodes.</p>
              </div>
              <div>
                <ReactToPrint
                  trigger={() => (
                    <button className="btn btn-primary me-2">
                      <i className="fas fa-print me-2" /> Print
                    </button>
                  )}
                  content={() => componentRef.current}
                />

                <button className="btn btn-outline-primary" onClick={goBackButton}>
                  Go Back
                </button>
              </div>
            </div>
          </div>

          <div className="card-body mt-4 d-flex justify-content-center">
            <form onSubmit={printBarcodes} className={'d-flex align-items-end gap-4'}>
              <div className="form-group">
                <label htmlFor={'name'}>Product Name</label>
                <input
                  type="text"
                  name={'name'}
                  disabled
                  value={name}
                  onChange={(e) => onChange(e)}
                  className="form-control input-sm"
                  placeholder={'Product Name'}
                />
              </div>

              <div className="form-group">
                <label htmlFor={'qty'}>Barcode Quantity</label>
                <input
                  type="number"
                  name={'qty'}
                  value={qty}
                  onChange={(e) => onChange(e)}
                  className="form-control input-sm"
                  placeholder={'Enter barcode quantity'}
                />
              </div>

              <button type={'submit'} className="btn btn-primary">
                Submit
              </button>
            </form>
          </div>
        </div>

        <div id="card-content" className={'mt-4 container'} ref={componentRef}>
          {barcodes}
        </div>
      </div>
    </Fragment>
  );
}

PrintBarcode.propTypes = {
  isLoading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading
});

export default connect(mapStateToProps)(PrintBarcode);
