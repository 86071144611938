import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../../layout/Breadcrumb';
import LoadingBackdrop from '../../../layout/LoadingBackdrop';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import {
  ADMINISTRATION_SECTIONS,
  areas,
  companySizes,
  districts,
  divisions
} from '../../../../utils';
import { createCompanyProfile, updateCompanyProfile } from '../../../../actions/system';
import { COMPANY_INFORMATIONS } from '../../../../permissions';

function CompanyProfile({
  isLoading,
  createCompanyProfile,
  updateCompanyProfile,
  company,
  auth: { permissions }
}) {
  const history = useHistory();
  const [formData, setFormData] = useState({
    name: company ? company.name : '',
    tagline: company ? company.tagline : '',
    phone: company ? company.phone : '',
    phone2: company ? company.phone2 : '',
    size: company ? company.size : '',
    logo: company ? company.logo : '',
    address: company ? company.address.address : '',
    division: company ? company.address.division : '',
    city: company ? company.address.city : '',
    area: company ? company.address.area : '',
    zip: company ? company.address.zip : ''
  });

  const { name, tagline, phone, phone2, size, logo, address, division, city, area, zip } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === COMPANY_INFORMATIONS)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  const onCancelButtonClicked = (e) => {
    history.goBack();
  };

  const saveCompanyProfile = (e) => {
    e.preventDefault();

    company
      ? updateCompanyProfile({
          ...formData,
          companyId: company.id,
          addressId: company.address.id
        })
      : createCompanyProfile(formData);
  };

  const onDivisionSelected = (e, value) => {
    setFormData({
      ...formData,
      division: value
    });
  };

  const onCitySelected = (e, value) => {
    setFormData({
      ...formData,
      city: value
    });
  };

  const onAreaSelected = (e, value) => {
    setFormData({
      ...formData,
      area: value
    });
  };

  const onCompanySizeSelected = (e, value) => {
    setFormData({
      ...formData,
      size: value
    });
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/administration'}>Administration</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/administration/company-profile'}>Company Profile</Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={ADMINISTRATION_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <form id="card-content" onSubmit={(e) => saveCompanyProfile(e)}>
          <div className="card-header">
            <div className="d-flex justify-content-between align-items center">
              <div>
                {company ? (
                  <Fragment>
                    <h5>Update company profile</h5>
                    <p>update company informations</p>
                  </Fragment>
                ) : (
                  <Fragment>
                    <h5>Create company profile</h5>
                    <p>Add company informations</p>
                  </Fragment>
                )}
              </div>
              <div>
                <button
                  type={'submit'}
                  className="btn btn-primary p-2 me-2"
                  disabled={name.length === 0 || address.length === 0}
                >
                  Save
                </button>
                <button
                  onClick={onCancelButtonClicked}
                  type={'button'}
                  className="btn btn-danger p-2 text-light"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <div className="card-body mt-2">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor={'name'}>Company Name:</label>
                  <input
                    type="text"
                    name={'name'}
                    required
                    value={name}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter company name'}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor={'tagline'}>Tagline:</label>
                  <input
                    type="text"
                    name={'tagline'}
                    value={tagline}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter tagline'}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor={'phone'}>Phone:</label>
                  <input
                    type="text"
                    name={'phone'}
                    value={phone}
                    required
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter phone'}
                  />
                </div>
              </div>

              <div className="col-md-4 mt-3">
                <div className="form-group">
                  <label htmlFor={'phone2'}>Secondary Phone (Optional):</label>
                  <input
                    type="text"
                    name={'phone2'}
                    value={phone2}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter secondary phone'}
                  />
                </div>
              </div>

              <div className="col-md-4 mt-3">
                <div className="form-group">
                  <label htmlFor={'size'}>Company Size:</label>
                  <Autocomplete
                    id={'select-size'}
                    value={size}
                    onChange={(event, value) => onCompanySizeSelected(event, value)}
                    options={companySizes}
                    style={{ width: '100%' }}
                    size="small"
                    renderOption={(option) => option}
                    getOptionLabel={(option) => {
                      // e.g value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option;
                    }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            padding: 0,
                            height: '36px',
                            marginBottom: 4
                          }
                        }}
                        placeholder={'Select a size'}
                        required
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div>

              <h5 className={'mt-4'}>Address</h5>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor={'address'}>Address:</label>
                  <input
                    type="text"
                    name={'address'}
                    value={address}
                    required
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter Address'}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
}

CompanyProfile.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  createCompanyProfile: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  updateCompanyProfile: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  company: state.system.company,
  auth: state.auth
});

export default connect(mapStateToProps, {
  createCompanyProfile,
  updateCompanyProfile
})(CompanyProfile);
