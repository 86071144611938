import {
  USER_LOADED,
  LOADING,
  AUTH_ERROR,
  REGISTER_SUCCESS,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT_SUCCESS,
  REGISTER_FAILED,
  LOADING_FALSE,
  UPDATE_SUCCESS,
  GET_TOKEN,
  USER_PERMISSIONS,
  USER_DATA
} from '../actions/types';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: !!localStorage.getItem('token'),
  isLoading: false,
  user: null,
  permissions: [],
  userData: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        isLoading: true
      };

    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload
      };

    case UPDATE_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isLoading: false
      };

    case USER_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
        isLoading: false
      };

    case USER_DATA:
      return {
        ...state,
        userData: action.payload,
        isLoading: false
      };

    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false
      };

    case GET_TOKEN:
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        isLoading: false
      };

    case LOADING_FALSE:
      return {
        ...state,
        isLoading: false
      };

    case LOGIN_FAILED:
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
    case REGISTER_FAILED:
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isLoading: false,
        isAuthenticated: false,
        user: null,
        permissions: []
      };

    default:
      return state;
  }
}
