import { axios, base_url, headers } from '../api';

const qs = require('qs');

const getStatistics = async () => {
  return await axios.get(`${base_url}/dashboard/statistics`, {
    headers: headers()
  });
};

const getLowStockProducts = async (data) => {
  return await axios.post(`${base_url}/dashboard/low-stock-products`, qs.stringify(data), {
    headers: headers()
  });
};

const getMostSoldProducts = async (data) => {
  return await axios.post(`${base_url}/dashboard/most-sold-products`, qs.stringify(data), {
    headers: headers()
  });
};

const getBankAccounts = async (data) => {
  return await axios.post(`${base_url}/dashboard/bank-accounts`, qs.stringify(data), {
    headers: headers()
  });
};

const getPopularCategories = async (data) => {
  return await axios.post(`${base_url}/dashboard/popular-categories`, qs.stringify(data), {
    headers: headers()
  });
};

const getInventoryStatistics = async () => {
  return await axios.get(`${base_url}/dashboard/inventory-statistics`, {
    headers: headers()
  });
};

export default {
  getStatistics,
  getLowStockProducts,
  getMostSoldProducts,
  getBankAccounts,
  getPopularCategories,
  getInventoryStatistics
};
