import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import 'jquery';
import '@popperjs/core';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.scss';

import Login from './components/auth/Login';

import store from './store';
import { Provider } from 'react-redux';
import { loadUser } from './actions/auth';
import PrivateRoute from './components/routing/PrivateRoute';
import Dashboard from './components/erp/home/Dashboard';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import Alerts from './components/layout/Alerts';
import Header from './components/layout/Header';
import muiTheme from './theme/muiTheme';
import AuthCredential from './components/routing/AuthCredential';
import Administration from './components/erp/administration/Administration';
import AddUser from './components/erp/administration/user/AddUser';
import Users from './components/erp/administration/user/Users';
import AddRole from './components/erp/administration/role/AddRole';
import Roles from './components/erp/administration/role/Roles';
import CompanyProfile from './components/erp/administration/company/CompanyProfile';
import Inventory from './components/erp/inventory/Inventory';
import AddProduct from './components/erp/inventory/products/AddProduct';
import Branches from './components/erp/administration/branch/Branches';
import AddBranch from './components/erp/administration/branch/AddBranch';
import Supplier from './components/erp/supplier/Supplier';
import AddSupplier from './components/erp/supplier/AddSupplier';
import Suppliers from './components/erp/supplier/Suppliers';
import Purchase from './components/erp/purchase/Purchase';
import AddPurchase from './components/erp/purchase/AddPurchase';
import PurchaseInvoices from './components/erp/purchase/PurchaseInvoices';
import Customer from './components/erp/customer/Customer';
import AddCustomer from './components/erp/customer/AddCustomer';
import Customers from './components/erp/customer/Customers';
import Sale from './components/erp/sale/Sale';
import AddSale from './components/erp/sale/AddSale';
import SaleInvoices from './components/erp/sale/SaleInvoices';
import PurchaseDetails from './components/erp/purchase/PurchaseDetails';
import PurchaseReturnList from './components/erp/purchase/PurchaseReturnList';
import SaleDetails from './components/erp/sale/SaleDetails';
import SaleReturnList from './components/erp/sale/SaleReturnList';
import Accounts from './components/erp/accounts/Accounts';
import AddAccount from './components/erp/accounts/accounts/AddAccount';
import AddDeposit from './components/erp/accounts/deposits/AddDeposit';
import Transfers from './components/erp/accounts/transfers/Transfers';
import AllAccounts from './components/erp/accounts/accounts/AllAccounts';
import AllDeposits from './components/erp/accounts/deposits/AllDeposits';
import AllTransfers from './components/erp/accounts/transfers/AllTransfers';
import AddExpenses from './components/erp/accounts/expanses/AddExpenses';
import Expenses from './components/erp/accounts/expanses/Expenses';
import Transactions from './components/erp/accounts/Transactions';
import UpdateSale from './components/erp/sale/UpdateSale';
import UpdatePurchase from './components/erp/purchase/UpdatePurchase';
import AddCustomerPayment from './components/erp/customer/AddCustomerPayment';
import CustomerDetails from './components/erp/customer/CustomerDetails';
import SupplierDetails from './components/erp/supplier/SupplierDetails';
import AddSupplierPayment from './components/erp/supplier/AddSupplierPayment';
import ProductDetails from './components/erp/inventory/products/ProductDetails';
import Settings from './components/erp/settings/Settings';
import AddQuotation from './components/erp/sale/AddQuotation';
import QuotationDetails from './components/erp/sale/QuotationDetails';
import UpdateQuotation from './components/erp/sale/UpdateQuotation';
import QuotationInvoices from './components/erp/sale/QuotationInvoices';
import Reports from './components/erp/reports/Reports';
import SalesReports from './components/erp/reports/SalesReports';
import PurchaseReports from './components/erp/reports/PurchaseReports';
import SaleHistoryReports from './components/erp/reports/SaleHistoryReports';
import ExpenseReports from './components/erp/reports/ExpenseReports';
import IncomeReports from './components/erp/reports/IncomeReports';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import IncomeVsExpenseReports from './components/erp/reports/IncomeVsExpenseReports';
import Employee from './components/erp/employee/Employee';
import AllEmployee from './components/erp/employee/AllEmployee';
import AddEmployee from './components/erp/employee/AddEditEmployee';
import Designation from './components/erp/employee/designation/Designation';
import Department from './components/erp/employee/department/Department';
import AddEditDesignation from './components/erp/employee/designation/AddEditDesignation';
import AddEditDepartment from './components/erp/employee/department/AddEditDepartment';
import EmployeeSalary from './components/erp/employee/salary/EmployeeSalary';
import AllSalary from './components/erp/employee/salary/AllSalary';
import Access from './components/erp/administration/access/Access';
import NotFound from './components/layout/NotFound';
import Packaging from './components/erp/packaging/Packaging';
import PackagingDetails from './components/erp/packaging/PackagingDetails';
import PrintSalesInvoice from './components/erp/sale/PrintSaleInvoice';
import PrintBarcode from './components/erp/inventory/PrintBarcode';
import PrintPurchaseInvoice from './components/erp/purchase/PrintPurchaseInvoice';
import PrintQuotationInvoice from './components/erp/sale/PrintQuotationInvoice';
import PrintSalesReturnInvoice from './components/erp/sale/PrintSalesReturnInvoice';
import PrintPurchaseReturnInvoice from './components/erp/purchase/PrintPurchaseReturnInvoice';
import SaleExchange from './components/erp/sale/SaleExchange';
import AllExchange from './components/erp/sale/AllExchange';
import PrintSaleExchangeInvoice from './components/erp/sale/PrintSaleExchangeInvoice';
import PurchaseDamageReturnList from './components/erp/purchase/PurchaseDamageReturnList';
import PrintPurchaseDamageReturnInvoice from './components/erp/purchase/PrintPurchaseDamageReturnInvoice';
import AllCustomerPayments from './components/erp/customer/AllCustomerPayments';
import AllSupplierPayments from './components/erp/supplier/AllSupplierPayments';
import Pos from './components/erp/pos/Pos';
import PublicRoute from './components/routing/PublicRoute';
import NoPermission from './components/layout/NoPermission';
import UpdateUser from './components/erp/administration/user/UpdateUser';
import UpdateBranch from './components/erp/administration/branch/UpdateBranch';
import SalesReportsByDate from './components/erp/reports/SalesReportsByDate';
import PurchaseReportsByDate from './components/erp/reports/PurchaseReportsByDate';

// Alert Options
const alertOptions = {
  timeout: 5000,
  position: 'top right'
};

function App() {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <AlertProvider template={AlertTemplate} {...alertOptions}>
          <Router>
            <Fragment>
              <MuiThemeProvider theme={muiTheme}>
                <Alerts />
                <Route exact path="/" render={() => <Redirect to="/home" />} />
                {/*<PublicRoute exact path={"/register"} component={Register}/>*/}
                <PublicRoute exact path={'/login'} component={Login} />
                <AuthCredential>
                  <Header>
                    <Switch>
                      <PrivateRoute exact path={'/home'} component={Dashboard} />

                      <PrivateRoute exact path={'/administration'} component={Administration} />
                      <PrivateRoute exact path={'/administration/add-user'} component={AddUser} />
                      <PrivateRoute
                        exact
                        path={'/administration/update-user'}
                        component={UpdateUser}
                      />
                      <PrivateRoute exact path={'/administration/users'} component={Users} />

                      <PrivateRoute exact path={'/administration/add-role'} component={AddRole} />
                      <PrivateRoute exact path={'/administration/roles'} component={Roles} />
                      <PrivateRoute exact path={'/administration/access'} component={Access} />

                      <PrivateRoute
                        exact
                        path={'/administration/company-profile'}
                        component={CompanyProfile}
                      />

                      <PrivateRoute exact path={'/inventory'} component={Inventory} />
                      <PrivateRoute exact path={'/inventory/product'} component={AddProduct} />
                      <PrivateRoute
                        exact
                        path={'/inventory/productDetails'}
                        component={ProductDetails}
                      />
                      <PrivateRoute
                        exact
                        path={'/inventory/print-barcodes'}
                        component={PrintBarcode}
                      />

                      <PrivateRoute exact path={'/suppliers'} component={Supplier} />
                      <PrivateRoute
                        exact
                        path={'/suppliers/add-supplier'}
                        component={AddSupplier}
                      />
                      <PrivateRoute exact path={'/suppliers/all'} component={Suppliers} />
                      <PrivateRoute exact path={'/suppliers/details'} component={SupplierDetails} />
                      <PrivateRoute
                        exact
                        path={'/suppliers/add-payment'}
                        component={AddSupplierPayment}
                      />
                      <PrivateRoute
                        exact
                        path={'/suppliers/payments'}
                        component={AllSupplierPayments}
                      />

                      <PrivateRoute exact path={'/customers'} component={Customer} />
                      <PrivateRoute
                        exact
                        path={'/customers/add-customer'}
                        component={AddCustomer}
                      />
                      <PrivateRoute exact path={'/customers/all'} component={Customers} />
                      <PrivateRoute exact path={'/customers/details'} component={CustomerDetails} />
                      <PrivateRoute
                        exact
                        path={'/customers/add-payment'}
                        component={AddCustomerPayment}
                      />
                      <PrivateRoute
                        exact
                        path={'/customers/payments'}
                        component={AllCustomerPayments}
                      />

                      <PrivateRoute exact path={'/purchase'} component={Purchase} />
                      <PrivateRoute exact path={'/purchase/details'} component={PurchaseDetails} />
                      <PrivateRoute exact path={'/purchase/update'} component={UpdatePurchase} />
                      <PrivateRoute exact path={'/purchase/new'} component={AddPurchase} />
                      <PrivateRoute exact path={'/purchase/all'} component={PurchaseInvoices} />
                      <PrivateRoute
                        exact
                        path={'/purchase/print'}
                        component={PrintPurchaseInvoice}
                      />
                      <PrivateRoute
                        exact
                        path={'/purchase/return-list'}
                        component={PurchaseReturnList}
                      />
                      <PrivateRoute
                        exact
                        path={'/purchase/return-list/print'}
                        component={PrintPurchaseReturnInvoice}
                      />
                      <PrivateRoute
                        exact
                        path={'/purchase/damage-return-list'}
                        component={PurchaseDamageReturnList}
                      />
                      <PrivateRoute
                        exact
                        path={'/purchase/damage-return-list/print'}
                        component={PrintPurchaseDamageReturnInvoice}
                      />

                      <PrivateRoute exact path={'/sale'} component={Sale} />
                      <PrivateRoute exact path={'/sale/details'} component={SaleDetails} />
                      <PrivateRoute exact path={'/sale/update'} component={UpdateSale} />
                      <PrivateRoute exact path={'/sale/exchange'} component={SaleExchange} />
                      <PrivateRoute exact path={'/sale/exchange/all'} component={AllExchange} />
                      <PrivateRoute
                        exact
                        path={'/sale/exchange/print'}
                        component={PrintSaleExchangeInvoice}
                      />
                      <PrivateRoute exact path={'/sale/new'} component={AddSale} />
                      <PrivateRoute exact path={'/sale/all'} component={SaleInvoices} />
                      <PrivateRoute exact path={'/sale/print'} component={PrintSalesInvoice} />
                      <PrivateRoute exact path={'/sale/return-list'} component={SaleReturnList} />
                      <PrivateRoute
                        exact
                        path={'/sale/return-list/print'}
                        component={PrintSalesReturnInvoice}
                      />
                      <PrivateRoute exact path={'/sale/quotations/new'} component={AddQuotation} />
                      <PrivateRoute
                        exact
                        path={'/sale/quotations/print'}
                        component={PrintQuotationInvoice}
                      />
                      <PrivateRoute
                        exact
                        path={'/sale/quotations/details'}
                        component={QuotationDetails}
                      />
                      <PrivateRoute
                        exact
                        path={'/sale/quotations/update'}
                        component={UpdateQuotation}
                      />
                      <PrivateRoute
                        exact
                        path={'/sale/quotations/all'}
                        component={QuotationInvoices}
                      />

                      <PrivateRoute exact path={'/pos'} component={Pos} />

                      <PrivateRoute exact path={'/accounts'} component={Accounts} />
                      <PrivateRoute exact path={'/accounts/new'} component={AddAccount} />
                      <PrivateRoute exact path={'/accounts/all'} component={AllAccounts} />
                      <PrivateRoute exact path={'/accounts/deposits/new'} component={AddDeposit} />
                      <PrivateRoute exact path={'/accounts/deposits/all'} component={AllDeposits} />
                      <PrivateRoute exact path={'/accounts/transfers/new'} component={Transfers} />
                      <PrivateRoute
                        exact
                        path={'/accounts/transfers/all'}
                        component={AllTransfers}
                      />
                      <PrivateRoute exact path={'/accounts/expenses/new'} component={AddExpenses} />
                      <PrivateRoute exact path={'/accounts/expenses/all'} component={Expenses} />
                      <PrivateRoute
                        exact
                        path={'/accounts/transactions'}
                        component={Transactions}
                      />

                      <PrivateRoute exact path={'/settings'} component={Settings} />
                      <PrivateRoute exact path={'/settings/add-branch'} component={AddBranch} />
                      <PrivateRoute
                        exact
                        path={'/settings/update-branch'}
                        component={UpdateBranch}
                      />
                      <PrivateRoute exact path={'/settings/branches'} component={Branches} />

                      <PrivateRoute exact path={'/reports'} component={Reports} />
                      <PrivateRoute exact path={'/reports/sales'} component={SalesReports} />
                      <PrivateRoute
                        exact
                        path={'/reports/sales/by-date'}
                        component={SalesReportsByDate}
                      />
                      <PrivateRoute exact path={'/reports/purchase'} component={PurchaseReports} />
                      <PrivateRoute
                        exact
                        path={'/reports/purchase/by-date'}
                        component={PurchaseReportsByDate}
                      />
                      <PrivateRoute
                        exact
                        path={'/reports/sales-history'}
                        component={SaleHistoryReports}
                      />
                      <PrivateRoute exact path={'/reports/expense'} component={ExpenseReports} />
                      <PrivateRoute exact path={'/reports/income'} component={IncomeReports} />
                      <PrivateRoute
                        exact
                        path={'/reports/income-expense'}
                        component={IncomeVsExpenseReports}
                      />

                      <PrivateRoute exact path={'/employee'} component={Employee} />
                      <PrivateRoute exact path={'/employee/all'} component={AllEmployee} />
                      <PrivateRoute exact path={'/employee/new'} component={AddEmployee} />
                      <PrivateRoute
                        exact
                        path={'/employee/designation/all'}
                        component={Designation}
                      />
                      <PrivateRoute
                        exact
                        path={'/employee/designation/new'}
                        component={AddEditDesignation}
                      />
                      <PrivateRoute
                        exact
                        path={'/employee/department/all'}
                        component={Department}
                      />
                      <PrivateRoute
                        exact
                        path={'/employee/department/new'}
                        component={AddEditDepartment}
                      />
                      <PrivateRoute exact path={'/employee/salary'} component={EmployeeSalary} />
                      <PrivateRoute exact path={'/employee/salary/all'} component={AllSalary} />

                      <PrivateRoute exact path={'/packaging'} component={Packaging} />
                      <PrivateRoute
                        exact
                        path={'/packaging/details'}
                        component={PackagingDetails}
                      />

                      <PrivateRoute exact path={'/access-denied'} component={NoPermission} />

                      <Route component={NotFound} />
                    </Switch>
                  </Header>
                </AuthCredential>
              </MuiThemeProvider>
            </Fragment>
          </Router>
        </AlertProvider>
      </MuiPickersUtilsProvider>
    </Provider>
  );
}

export default App;
