import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import { addCustomer } from '../../../actions/customers';
import { CUSTOMER_ENTRY } from '../../../permissions';
import { CUSTOMER_SECTIONS } from '../../../utils';

function AddCustomer({ isLoading, auth: { permissions }, addCustomer }) {
  const history = useHistory();
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    address: ''
  });

  const { name, phone, email, address } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === CUSTOMER_ENTRY)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  const onCancelButtonClicked = (e) => {
    history.goBack();
  };

  const saveCustomer = async (e) => {
    e.preventDefault();

    const data = await addCustomer(formData);
    if (data) {
      history.push({
        pathname: '/customers/details',
        search: `?id=${data.id}`
      });
    }
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/customers'}>Customers</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/customers/add-customer'}>Add Customer</Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={CUSTOMER_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <form id="card-content" onSubmit={(e) => saveCustomer(e)}>
          <div className="card-header">
            <div className="d-flex justify-content-between align-items center">
              <div>
                <Fragment>
                  <h5>Add New Customer</h5>
                  <p>Add and manage new customers</p>
                </Fragment>
              </div>
              <div>
                <button
                  type={'submit'}
                  className="btn btn-primary p-2 me-2"
                  disabled={name.length === 0 || address.length === 0 || phone.length === 0}
                >
                  Save
                </button>
                <button
                  onClick={onCancelButtonClicked}
                  type={'button'}
                  className="btn btn-danger p-2 text-light"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <div className="card-body mt-2">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor={'name'}>Customer Name:</label>
                  <input
                    type="text"
                    name={'name'}
                    required
                    value={name}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter customer name'}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor={'email'}>Email:</label>
                  <input
                    type="email"
                    name={'email'}
                    value={email}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter email'}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor={'phone'}>Phone *:</label>
                  <input
                    type="text"
                    name={'phone'}
                    value={phone}
                    required
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter phone'}
                  />
                </div>
              </div>

              <div className="col-md-4 mt-4">
                <div className="form-group">
                  <label htmlFor={'address'}>Address:</label>
                  <input
                    type="text"
                    name={'address'}
                    value={address}
                    required
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter address'}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
}

AddCustomer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  addCustomer: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  auth: state.auth
});

export default connect(mapStateToProps, { addCustomer })(AddCustomer);
