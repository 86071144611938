import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import MaterialTable from 'material-table';
import moment from 'moment';
import { Pagination } from '@material-ui/lab';
import Swal from 'sweetalert2';
import { deleteDepartment, getDepartments } from '../../../../actions/employee';
import Breadcrumb from '../../../layout/Breadcrumb';
import { DEPARTMENTS } from '../../../../permissions';
import { EMPLOYEE_SECTIONS } from '../../../../utils';

function Department({
  isLoading,
  getDepartments,
  deleteDepartment,
  auth: { permissions },
  employee: { departments }
}) {
  const history = useHistory();

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === DEPARTMENTS)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getDepartments({});
  }, []);

  const editDepartment = (e, id) => {
    e.preventDefault();
    history.push({
      pathname: '/employee/department/new',
      search: `?id=${id}`
    });
  };

  const deleteADepartment = (e, id) => {
    e.preventDefault();

    Swal.fire({
      title: 'Are you sure?',
      text: "You can't be able to retrieve it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteDepartment(id);
      }
    });
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/employee'}>Employee</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/employee/department/all'}>Departments</Link>
      </li>
    </ol>
  );

  const tableHead = [
    { field: 'id', title: 'SL' },
    { field: 'name', title: 'Name' }
  ];

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={EMPLOYEE_SECTIONS} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Add new departments</h5>
                <p className={'mb-0'}>Add a new employee</p>
              </div>
              <div>
                <Link
                  to={'/employee/department/new'}
                  className="btn btn-primary p-2 rounded text-decoration-none"
                >
                  <i className="far fa-plus-square me-2" /> Add Department
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div id="card-content" className={'mt-4'}>
          <div className={'overflow-auto'}>
            <MaterialTable
              title={'All employee'}
              columns={tableHead}
              data={departments?.paginatedData}
              isLoading={isLoading}
              totalCount={departments?.total}
              options={{
                headerStyle: {
                  backgroundColor: '#F6F9FC',
                  color: '#000'
                },
                rowStyle: (rowData) => {
                  return {
                    fontSize: '14px',
                    color: '#000'
                  };
                },
                padding: 'default',
                pageSize: 20,
                actionsColumnIndex: -1,
                pageSizeOptions: [10, 25, 50],
                showFirstLastPageButtons: true,
                exportAllData: true,
                exportFileName: `All department - ${moment(new Date()).format('LL')}`,
                exportButton: true,
                searchFieldAlignment: 'left',
                searchFieldStyle: {
                  marginLeft: '3rem'
                }
              }}
              components={{
                Pagination: (props) => (
                  <div className="text-center d-flex justify-content-end pagination mt-2">
                    <Pagination
                      color={'primary'}
                      count={departments?.lastPage}
                      page={departments?.currentPage}
                      siblingCount={2}
                      onChange={(e, value) => getDepartments({ page: value })}
                    />
                  </div>
                )
              }}
              actions={[
                (rowData) => ({
                  icon: 'edit',
                  onClick: (e) => editDepartment(e, rowData.id),
                  tooltip: 'Edit'
                }),
                (rowData) => ({
                  icon: 'delete',
                  onClick: (e) => deleteADepartment(e, rowData.id),
                  tooltip: 'Delete'
                })
              ]}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  employee: state.employee,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getDepartments,
  deleteDepartment
})(Department);
