import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded, Print } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import MaterialTable from 'material-table';
import moment from 'moment';
import { deleteQuotation, getQuotationList } from '../../../actions/sale';
import { Pagination } from '@material-ui/lab';
import { getBankAccounts } from '../../../actions/bankings';
import { getIncomeCategories, getPaymentMethods } from '../../../actions/system';
import Swal from 'sweetalert2';
import FilterLayout from '../../layout/FilterLayout';
import { QUOTATIONS } from '../../../permissions';
import { SALE_SECTIONS } from '../../../utils';

function QuotationInvoices({
  isLoading,
  getBankAccounts,
  getPaymentMethods,
  getIncomeCategories,
  getQuotationList,
  auth: { permissions },
  deleteQuotation,
  sale: { quotation }
}) {
  const history = useHistory();
  const [from_date, setFromDate] = useState(
    moment(Date()).subtract(30, 'days').format('YYYY-MM-DD')
  );
  const [to_date, setToDate] = useState(moment(Date()).format('YYYY-MM-DD'));

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === QUOTATIONS)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getAllQuotationList();
    getPaymentMethods();
    getBankAccounts();
    getIncomeCategories();
  }, []);

  const getAllQuotationList = () => {
    getQuotationList({ from_date, to_date });
  };

  const handleHistory = () => {
    history.push('#');
  };

  const handleQuotationDetails = (id) => {
    history.push({
      pathname: '/sale/quotations/details',
      search: `?id=${id}`
    });
  };

  const deleteQuotationInvoice = (e, id) => {
    e.preventDefault();

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteQuotation(id);
      }
    });
  };
  const moveToQuotationUpdate = (id) => {
    history.push({
      pathname: '/sale/quotations/update',
      search: `?id=${id}`
    });
  };

  const handleCustomerDetails = (id) => {
    history.push({
      pathname: '/customers/details',
      search: `?id=${id}`
    });
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/sale'}>Sale</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/sale/quotations/all'}>Invoices</Link>
      </li>
    </ol>
  );

  const tableHead = [
    {
      field: 'id',
      title: 'Inv. No',
      render: (rowData) => (
        <Link
          className={'font-weight-bold link-primary hover text-decoration-none'}
          to={`/sale/quotations/details?id=${rowData.id}`}
        >{`QO-${rowData.id}`}</Link>
      )
    },
    { field: 'date', title: 'Date' },
    {
      field: 'customer.name',
      title: 'Customer Name',
      render: (rowData) => (
        <Link
          className={'font-weight-bold link-primary hover text-decoration-none'}
          to={`/customers/details?id=${rowData.customer.id}`}
        >
          {rowData.customer.name}
        </Link>
      )
    },
    { field: 'customer.phone', title: 'Phone' },
    { field: 'discount', title: 'Discount (%)' },
    { field: 'vat_value', title: 'Vat Amount (৳)' },
    { field: 'total', title: 'Total (৳)' }
  ];

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={SALE_SECTIONS} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>New Quotation Entry</h5>
                <p className={'mb-0'}>Add new quotation</p>
              </div>
              <div>
                <Link
                  to={'/sale/quotations/new'}
                  className="btn btn-primary p-2 rounded text-decoration-none"
                >
                  <i className="far fa-plus-square me-2" /> Quotation Entry
                </Link>
              </div>
            </div>
          </div>
        </div>

        <FilterLayout
          handleActions={getAllQuotationList}
          setFromDate={setFromDate}
          setToDate={setToDate}
        />

        <div id="card-content" className={'mt-4'}>
          <div className={'overflow-auto'}>
            <MaterialTable
              title={'Invoices'}
              columns={tableHead}
              data={quotation?.paginatedData}
              isLoading={isLoading}
              totalCount={quotation?.total}
              options={{
                headerStyle: {
                  backgroundColor: '#F6F9FC',
                  color: '#000'
                },
                rowStyle: () => {
                  return {
                    fontSize: '14px',
                    color: '#000'
                  };
                },
                padding: 'default',
                pageSize: 20,
                actionsColumnIndex: -1,
                pageSizeOptions: [10, 25, 50],
                showFirstLastPageButtons: true,
                exportAllData: true,
                exportFileName: `Invoices - ${moment(new Date()).format('LL')}`,
                exportButton: true,
                searchFieldAlignment: 'left',
                searchFieldStyle: {
                  marginLeft: '3rem'
                }
              }}
              components={{
                Pagination: (props) => (
                  <div className="text-center d-flex justify-content-end pagination mt-2">
                    <Pagination
                      color={'primary'}
                      count={quotation?.lastPage}
                      page={quotation?.currentPage}
                      siblingCount={2}
                      onChange={(e, value) => getQuotationList({ from_date, to_date, page: value })}
                    />
                  </div>
                )
              }}
              actions={[
                {
                  icon: 'refresh',
                  onClick: (event, rowData) => {},
                  isFreeAction: true,
                  tooltip: 'refresh'
                },
                (rowData) => ({
                  icon: 'visibility_on',
                  onClick: () => handleQuotationDetails(rowData.id),
                  isFreeAction: true,
                  tooltip: 'Quotation details'
                }),
                (rowData) => ({
                  icon: () => (
                    <Link
                      className={'table-icon'}
                      to={`/sale/quotations/print?id=${rowData.id}`}
                      target="_blank"
                    >
                      <Print />
                    </Link>
                  ),
                  onClick: rowData,
                  tooltip: 'Print Invoice'
                }),
                (rowData) => ({
                  icon: 'edit',
                  onClick: (e) => moveToQuotationUpdate(rowData.id),
                  tooltip: 'Edit Invoice'
                }),
                (rowData) => ({
                  icon: 'delete',
                  onClick: (e) => deleteQuotationInvoice(e, rowData.id),
                  tooltip: 'Delete'
                })
              ]}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

QuotationInvoices.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getQuotationList: PropTypes.func.isRequired,
  sale: PropTypes.object.isRequired,
  system: PropTypes.object.isRequired,
  bankings: PropTypes.object.isRequired,
  getBankAccounts: PropTypes.func.isRequired,
  getIncomeCategories: PropTypes.func.isRequired,
  getPaymentMethods: PropTypes.func.isRequired,
  deleteQuotation: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  sale: state.sale,
  bankings: state.bankings,
  system: state.system,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getQuotationList,
  getBankAccounts,
  getIncomeCategories,
  getPaymentMethods,
  deleteQuotation
})(QuotationInvoices);
