import { axios, base_url, headers } from '../api';

const qs = require('qs');

const getEmployeeList = async (data) => {
  return await axios.post(`${base_url}/employees/`, qs.stringify(data), {
    headers: headers()
  });
};

const addEmployee = async (data) => {
  return await axios.post(`${base_url}/employees/new`, qs.stringify(data), {
    headers: headers()
  });
};

const updateEmployee = async (data) => {
  return await axios.put(`${base_url}/employees/${data.id}`, qs.stringify(data), {
    headers: headers()
  });
};

const deleteEmployee = async (id) => {
  return await axios.delete(`${base_url}/employees/${id}`, {
    headers: headers()
  });
};

const getDesignations = async (data) => {
  return await axios.post(`${base_url}/employees/designation/all`, qs.stringify(data), {
    headers: headers()
  });
};

const getDepartments = async (data) => {
  return await axios.post(`${base_url}/employees/department/all`, qs.stringify(data), {
    headers: headers()
  });
};

const getEmployeeDetails = async (id) => {
  return await axios.get(`${base_url}/employees/${id}/details`, {
    headers: headers()
  });
};

const addDesignation = async (data) => {
  return await axios.post(`${base_url}/employees/designation`, qs.stringify(data), {
    headers: headers()
  });
};

const addDepartment = async (data) => {
  return await axios.post(`${base_url}/employees/department`, qs.stringify(data), {
    headers: headers()
  });
};

const deleteDesignation = async (id) => {
  return await axios.delete(`${base_url}/employees/designation/${id}`, {
    headers: headers()
  });
};

const deleteDepartment = async (id) => {
  return await axios.delete(`${base_url}/employees/department/${id}`, {
    headers: headers()
  });
};

const updateDesignation = async (id, data) => {
  return await axios.put(`${base_url}/employees/designation/${id}`, qs.stringify(data), {
    headers: headers()
  });
};

const updateDepartment = async (id, data) => {
  return await axios.put(`${base_url}/employees/department/${id}`, qs.stringify(data), {
    headers: headers()
  });
};

const getDesignation = async (id) => {
  return await axios.get(`${base_url}/employees/designation/${id}`, {
    headers: headers()
  });
};

const getDepartment = async (id) => {
  return await axios.get(`${base_url}/employees/department/${id}`, {
    headers: headers()
  });
};

const getSalaryHistory = async (data) => {
  return await axios.post(`${base_url}/employees/salary-history/`, qs.stringify(data), {
    headers: headers()
  });
};

const addSalary = async (data) => {
  return await axios.post(`${base_url}/employees/salary/`, qs.stringify(data), {
    headers: headers()
  });
};

export default {
  getEmployeeList,
  deleteEmployee,
  getDesignations,
  getDepartments,
  addDepartment,
  addDesignation,
  addEmployee,
  getEmployeeDetails,
  updateEmployee,
  deleteDesignation,
  deleteDepartment,
  updateDesignation,
  updateDepartment,
  getDepartment,
  getDesignation,
  getSalaryHistory,
  addSalary
};
