import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import MaterialTable from 'material-table';
import moment from 'moment';
import { getCustomers } from '../../../actions/customers';
import { CUSTOMER_ENTRY, CUSTOMERS, CUSTOMERS_DUE, MODIFY_CUSTOMERS } from '../../../permissions';
import { Pagination } from '@material-ui/lab';
import { CUSTOMER_SECTIONS } from '../../../utils';

function Customers({ isLoading, auth: { permissions }, getCustomers, customers }) {
  let history = useHistory();
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === CUSTOMERS)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getCustomers({});
  }, []);

  const handleHistory = () => {
    history.push('#');
  };

  const handleDetails = (e, id) => {
    e.preventDefault();

    history.push({
      pathname: '/customers/details',
      search: `?id=${id}`
    });
  };

  const handleSearch = (value) => {
    setSearchText(value);
    getCustomers({ search: value });
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/customers'}>Customers</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/customers/all'}>All Customer</Link>
      </li>
    </ol>
  );

  const tableHead = [
    { field: 'id', title: 'ID', editable: 'never' },
    { field: 'name', title: 'Customer Name' },
    { field: 'email', title: 'Email' },
    { field: 'phone', title: 'Phone' },
    { title: 'address', field: 'address' }
  ];

  if (permissions.find((item) => item.name === CUSTOMERS_DUE)) {
    tableHead.push({ field: 'due', title: 'Due (৳)' });
  }

  tableHead.push({
    title: 'Status',
    field: 'status.name',
    render: (rowData) => (
      <p className={`mb-0 ${rowData.status.name.toLowerCase()}_status`}>{rowData.status.name}</p>
    )
  });

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={CUSTOMER_SECTIONS} />

      {customers && (
        <div className="container-fluid mt-4">
          <div id="card-content">
            <div className="card-header">
              {permissions.find((item) => item.name === CUSTOMER_ENTRY) && (
                <div className="d-flex mb-4 justify-content-between align-items-center">
                  <div>
                    <h5>Add New Customer</h5>
                    <p className={'mb-0'}>Create and manage customers</p>
                  </div>
                  <div>
                    <Link
                      to={'/customers/add-customer'}
                      className="btn btn-primary p-2 rounded text-decoration-none"
                    >
                      <i className="far fa-plus-square me-2" /> Add Customer
                    </Link>
                  </div>
                </div>
              )}

              <div className="row heading_columns">
                <div className="col-md-2 border-right">
                  <p>Total customers</p>
                  <h4>{customers.totalCustomers}</h4>
                </div>
                <div className="col-md-2  border-right">
                  <p>Active customers</p>
                  <h4>{customers.activeCustomers}</h4>
                </div>
                <div className="col-md-2">
                  <p>Inactive customers</p>
                  <h4>{customers.inactiveCustomers}</h4>
                </div>
                <div className="col-md-6 text-end">
                  {permissions.find((item) => item.name === CUSTOMER_ENTRY) && (
                    <button className="btn btn-primary me-3">
                      Upload customers <i className="ms-2 fas fa-cloud-upload-alt" />
                    </button>
                  )}

                  <button className="btn btn-primary">
                    Download customers <i className="ms-2 fas fa-cloud-download-alt" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div id="card-content" className={'mt-4'}>
            <div className={'overflow-auto'}>
              <MaterialTable
                title={'All Customer'}
                columns={tableHead}
                data={customers?.paginatedData}
                isLoading={isLoading}
                totalCount={customers?.total}
                options={{
                  headerStyle: {
                    backgroundColor: '#F6F9FC',
                    color: '#000'
                  },
                  rowStyle: () => {
                    return {
                      fontSize: '14px',
                      color: '#000'
                    };
                  },
                  actionsColumnIndex: -1,
                  padding: 'default',
                  pageSize: 20,
                  pageSizeOptions: [10, 25, 50],
                  showFirstLastPageButtons: true,
                  exportAllData: true,
                  exportFileName: `Customers - ${moment(new Date()).format('LL')}`,
                  exportButton: true,
                  searchFieldAlignment: 'left',
                  searchFieldStyle: {
                    marginLeft: '3rem'
                  },
                  searchAutoFocus: true
                }}
                onSearchChange={handleSearch}
                actions={[
                  {
                    icon: 'refresh',
                    onClick: () => {},
                    isFreeAction: true,
                    tooltip: 'refresh'
                  }
                ]}
                onRowClick={(event, rowData) => handleDetails(event, rowData.id)}
                editable={
                  permissions.find((item) => item.name === MODIFY_CUSTOMERS) && {
                    onRowDelete: () =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                        }, 1000);
                      })
                  }
                }
                components={{
                  Pagination: () => (
                    <div className="text-center d-flex justify-content-end pagination mt-2">
                      <Pagination
                        color={'primary'}
                        count={customers?.lastPage}
                        page={customers?.currentPage}
                        siblingCount={2}
                        onChange={(e, value) => getCustomers({ page: value, search: searchText })}
                      />
                    </div>
                  )
                }}
              />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

Customers.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  customers: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  customers: state.customers.customers,
  auth: state.auth
});

export default connect(mapStateToProps, { getCustomers })(Customers);
