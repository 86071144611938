import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  addIncomeExpenseCategories,
  getBranches,
  getIncomeExpenseCategories,
  updateIncomeExpenseCategories
} from '../../../actions/system';
import {
  addBrand,
  addCategory,
  addColor,
  addSize,
  addType,
  addUnit,
  deleteBrand,
  deleteCategory,
  deleteColor,
  deleteSize,
  deleteType,
  deleteUnit,
  getBrands,
  getCategories,
  getColors,
  getSizes,
  getTypes,
  getUnits,
  updateBrand,
  updateCategory,
  updateColor,
  updateSize,
  updateType,
  updateUnit
} from '../../../actions/products';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import { BRANCHES, isNotSettingsHidden, SETTINGS } from '../../../permissions';
import Swal from 'sweetalert2';

const TYPE_CATEGORY = 'Add Category';
const TYPE_SUBCATEGORY = 'Add Subcategory';
const TYPE_BRAND = 'Add Brand';
const TYPE_UNIT = 'Add Unit';
const TYPE_TYPE = 'Add Type';
const TYPE_COLOR = 'Add Color';
const TYPE_SIZE = 'Add Size';
const IET_CATEGORY = 'Add IET Category';

const IET_CATEGORIES = ['income', 'expense', 'transfer'];

function Settings({
  isLoading,
  getCategories,
  getIncomeExpenseCategories,
  addIncomeExpenseCategories,
  getBranches,
  updateCategory,
  getTypes,
  addType,
  deleteType,
  updateColor,
  updateUnit,
  updateBrand,
  updateSize,
  updateType,
  addCategory,
  getColors,
  getSizes,
  addColor,
  addSize,
  addBrand,
  getBrands,
  addUnit,
  getUnits,
  deleteSize,
  deleteBrand,
  deleteCategory,
  deleteColor,
  deleteUnit,
  system: { branches, incomeExpenseCategories },
  auth: { permissions },
  updateIncomeExpenseCategories,
  products: { categories, brands, units, sizes, colors, types }
}) {
  const history = useHistory();

  useEffect(() => {
    if (permissions.length > 0) {
      if (!isNotSettingsHidden(permissions)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getIncomeExpenseCategories();
    getCategories();
    getBrands();
    getUnits();
    getSizes();
    getColors();
    getTypes();
    getBranches();
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    id: '',
    categoryId: '',
    type: IET_CATEGORIES[0]
  });
  const [dialogOpen, setDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [dialogType, setDialogType] = useState('');

  const { name, type } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleDialogOpen = (type, id) => {
    setIsEdit(false);
    setDialogType(type);
    setDialog(true);

    if (id) {
      setFormData({
        ...formData,
        categoryId: id,
        type: IET_CATEGORIES[0]
      });
    }
  };

  const handleDialogEditOpen = (type, id, name, ietType) => {
    setIsEdit(true);
    setDialogType(type);
    setDialog(true);

    if (id) {
      setFormData({
        ...formData,
        name,
        id,
        type: ietType || ''
      });
    }
  };

  const handleDialogClose = () => {
    setDialogType('');
    setIsEdit(false);
    setDialog(false);
    setFormData({
      name: '',
      categoryId: '',
      type: IET_CATEGORIES[0]
    });
  };

  const save = (e) => {
    e.preventDefault();

    if (name.length === 0) {
      return;
    }

    if (dialogType === TYPE_CATEGORY || dialogType === TYPE_SUBCATEGORY) {
      if (isEdit) {
        updateCategory(formData);
      } else {
        addCategory(formData);
      }
    } else if (dialogType === TYPE_BRAND) {
      if (isEdit) {
        updateBrand(formData);
      } else {
        addBrand(formData);
      }
    } else if (dialogType === TYPE_UNIT) {
      if (isEdit) {
        updateUnit(formData);
      } else {
        addUnit(formData);
      }
    } else if (dialogType === TYPE_TYPE) {
      if (isEdit) {
        updateType(formData);
      } else {
        addType(formData);
      }
    } else if (dialogType === TYPE_COLOR) {
      if (isEdit) {
        updateColor(formData);
      } else {
        addColor(formData);
      }
    } else if (dialogType === TYPE_SIZE) {
      if (isEdit) {
        updateSize(formData);
      } else {
        addSize(formData);
      }
    } else if (dialogType === IET_CATEGORY) {
      if (isEdit) {
        updateIncomeExpenseCategories(formData);
      } else {
        addIncomeExpenseCategories(formData);
      }
    }

    handleDialogClose();
  };

  const handleDeleteCategory = (id, main = false) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `${
        main && 'Deleting main category will delete all subcategory too and'
      } You can't be able to retrieve it!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCategory(id);
      }
    });
  };

  const handleDeleteBrand = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You can't be able to retrieve it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBrand(id);
      }
    });
  };

  const handleDeleteUnits = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You can't be able to retrieve it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUnit(id);
      }
    });
  };

  const handleDeleteColors = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You can't be able to retrieve it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteColor(id);
      }
    });
  };

  const handleDeleteSize = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You can't be able to retrieve it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSize(id);
      }
    });
  };

  const handleDeleteIET = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You can't be able to retrieve it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        // deleteSize(id)
      }
    });
  };

  const handleDeleteType = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You can't be able to retrieve it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteType(id);
      }
    });
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/settings'}>Settings</Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb
        breadcrumbs={breadcrumbs}
        sectionNames={[{ link: '/settings/branches', name: 'Branches' }]}
      />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <div className="row">
          {permissions.find((item) => item.name === BRANCHES) && (
            <div className="col-md-4">
              <div id="card-content">
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Branches</h5>
                      <p>Create and manage branches</p>
                    </div>
                    <div>
                      <Link to={'/settings/add-branch'} className="btn btn-primary me-2">
                        Add
                      </Link>
                      <Link to={'/settings/branches'} className="btn btn-outline-primary">
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <ul className="list-group ">
                    {branches.slice(0, 3).map((branch) => (
                      <li className="list-group-item d-flex align-items-center">
                        <div>
                          <h6>{branch.name}</h6>
                          <p className={'m-0'}>
                            {branch.address &&
                              `${branch.address.address}, ${branch.address.area}, ${branch.address.address}`}
                          </p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )}

          {permissions.find((item) => item.name === SETTINGS) && (
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-4">
                  <div id="card-content">
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <div>
                          <h5>Categories</h5>
                        </div>
                        <div>
                          <Link>
                            <i
                              onClick={(e) => handleDialogOpen(TYPE_CATEGORY)}
                              className="fas fa-plus"
                              style={{ fontSize: '18px' }}
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="card-body overflow-auto" style={{ height: '340px' }}>
                      <ul className="list-group">
                        {categories.map((category) => (
                          <Fragment>
                            <li className="list-group-item d-flex justify-content-between align-items-center p-1 pt-3">
                              <p className={'m-0 font-weight-bold'} style={{ fontSize: '16px' }}>
                                {category.name}
                              </p>
                              <div>
                                <i
                                  className="fa fa-plus add-icon ms-1 me-2"
                                  onClick={(e) => handleDialogOpen(TYPE_SUBCATEGORY, category.id)}
                                />
                                <i
                                  className="far fa-edit hover"
                                  onClick={() =>
                                    handleDialogEditOpen(TYPE_CATEGORY, category.id, category.name)
                                  }
                                />
                              </div>
                            </li>

                            {category.subcategories.map((subcategory) => (
                              <li className="list-group-item d-flex justify-content-between align-items-center p-1">
                                <p className={'m-0 ms-2'} style={{ fontSize: '14px' }}>
                                  - {subcategory.name}
                                </p>
                                <i
                                  className="far fa-edit hover"
                                  onClick={() =>
                                    handleDialogEditOpen(TYPE_CATEGORY, category.id, category.name)
                                  }
                                />
                              </li>
                            ))}
                          </Fragment>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div id="card-content" className={'mt-4'}>
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <div>
                          <h5>Types</h5>
                        </div>
                        <div>
                          <Link>
                            <i
                              onClick={(e) => handleDialogOpen(TYPE_TYPE)}
                              className="fas fa-plus"
                              style={{ fontSize: '18px' }}
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="card-body overflow-auto" style={{ height: '200px' }}>
                      <ul className="list-group">
                        {types.map((type) => (
                          <li className="list-group-item justify-content-between d-flex align-items-center p-1">
                            <p className={'m-0'}>{type.name}</p>
                            <i
                              className="far fa-edit hover"
                              onClick={() => handleDialogEditOpen(TYPE_TYPE, type.id, type.name)}
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-6">
                      <div id="card-content">
                        <div className="card-header">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h5>Brands</h5>
                            </div>
                            <div>
                              <Link>
                                <i
                                  onClick={(e) => handleDialogOpen(TYPE_BRAND)}
                                  className="fas fa-plus"
                                  style={{ fontSize: '18px' }}
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="card-body overflow-auto" style={{ height: '120px' }}>
                          <ul className="list-group">
                            {brands.map((brand) => (
                              <li className="list-group-item d-flex justify-content-between align-items-center p-1">
                                <p className={'m-0'}>{brand.name}</p>
                                <i
                                  className="far fa-edit hover"
                                  onClick={() =>
                                    handleDialogEditOpen(TYPE_BRAND, brand.id, brand.name)
                                  }
                                />
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div id="card-content">
                        <div className="card-header">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h5>Units</h5>
                            </div>
                            <div>
                              <Link>
                                <i
                                  onClick={(e) => handleDialogOpen(TYPE_UNIT)}
                                  className="fas fa-plus"
                                  style={{ fontSize: '18px' }}
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="card-body overflow-auto" style={{ height: '120px' }}>
                          <ul className="list-group">
                            {units.map((unit) => (
                              <li className="list-group-item justify-content-between d-flex align-items-center p-1">
                                <p className={'m-0'}>{unit.name}</p>
                                <i
                                  className="far fa-edit hover"
                                  onClick={() =>
                                    handleDialogEditOpen(TYPE_UNIT, unit.id, unit.name)
                                  }
                                />
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 mt-4">
                      <div id="card-content">
                        <div className="card-header">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h5>Colors</h5>
                            </div>
                            <div>
                              <Link>
                                <i
                                  onClick={(e) => handleDialogOpen(TYPE_COLOR)}
                                  className="fas fa-plus"
                                  style={{ fontSize: '18px' }}
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="card-body overflow-auto" style={{ height: '120px' }}>
                          <ul className="list-group">
                            {colors.map((color) => (
                              <li className="list-group-item d-flex justify-content-between align-items-center p-1">
                                <p className={'m-0'}>{color.name}</p>
                                <i
                                  className="far fa-edit hover"
                                  onClick={() =>
                                    handleDialogEditOpen(TYPE_COLOR, color.id, color.name)
                                  }
                                />
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 mt-4">
                      <div id="card-content">
                        <div className="card-header">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h5>Sizes</h5>
                            </div>
                            <div>
                              <Link>
                                <i
                                  onClick={(e) => handleDialogOpen(TYPE_SIZE)}
                                  className="fas fa-plus"
                                  style={{ fontSize: '18px' }}
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="card-body overflow-auto" style={{ height: '120px' }}>
                          <ul className="list-group">
                            {sizes.map((size) => (
                              <li className="list-group-item d-flex justify-content-between align-items-center p-1">
                                <p className={'m-0'}>{size.name}</p>
                                <i
                                  className="far fa-edit hover"
                                  onClick={() =>
                                    handleDialogEditOpen(TYPE_SIZE, size.id, size.name)
                                  }
                                />
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-8 mt-4">
                      <div id="card-content">
                        <div className="card-header">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h5>IET Categories</h5>
                            </div>
                            <div>
                              <Link>
                                <i
                                  onClick={(e) => handleDialogOpen(IET_CATEGORY)}
                                  className="fas fa-plus"
                                  style={{ fontSize: '18px' }}
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="card-body overflow-auto" style={{ height: '200px' }}>
                          <ul className="list-group">
                            {incomeExpenseCategories.map((IET) => (
                              <li className="list-group-item d-flex justify-content-between align-items-center p-1">
                                <div className="d-flex justify-content-between align-items-center w-75">
                                  <p className={'m-0'}>{IET.name}</p>
                                  <p className={'m-0 font-weight-bold text-uppercase'}>
                                    {IET.type}
                                  </p>
                                </div>
                                <i
                                  className="far fa-edit hover"
                                  onClick={() =>
                                    handleDialogEditOpen(IET_CATEGORY, IET.id, IET.name, IET.type)
                                  }
                                />
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Dialog
        open={dialogOpen}
        fullWidth="sm"
        maxWidth="sm"
        disableBackdropClick
        onClose={handleDialogClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {isEdit ? dialogType.replace('Add', 'Update') : dialogType}
        </DialogTitle>
        <DialogContent>
          <div className="bg-white shadow-sm rounded">
            <div className="card-body">
              <React.Fragment>
                <p className="my-auto">
                  <strong>Name</strong>
                </p>
                <TextField
                  id="name"
                  value={name}
                  onChange={(e) => onChange(e)}
                  margin="normal"
                  name={'name'}
                  placeholder="Enter name"
                  type="text"
                  fullWidth
                />

                {dialogType === IET_CATEGORY && (
                  <>
                    <p className="my-2 mt-4">
                      <strong>Type</strong>
                    </p>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={type}
                      name={'type'}
                      style={{ width: 200 }}
                      onChange={onChange}
                    >
                      {IET_CATEGORIES.map((iet) => (
                        <MenuItem value={iet}>{iet}</MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </React.Fragment>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button color="primary" onClick={(e) => save(e)}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  system: state.system,
  products: state.products,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getCategories,
  addBrand,
  getBrands,
  getUnits,
  addUnit,
  getTypes,
  addType,
  deleteType,
  getSizes,
  addCategory,
  getBranches,
  getColors,
  addSize,
  addColor,
  updateCategory,
  deleteSize,
  deleteBrand,
  deleteCategory,
  deleteColor,
  deleteUnit,
  getIncomeExpenseCategories,
  addIncomeExpenseCategories,
  updateColor,
  updateUnit,
  updateBrand,
  updateSize,
  updateType,
  updateIncomeExpenseCategories
})(Settings);
