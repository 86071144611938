import {
  ALL_PRODUCTS,
  BRANDS,
  CATEGORIES,
  COLORS,
  PRODUCTS,
  SEARCH_LOADING,
  SEARCH_LOADING_FALSE,
  SIZES,
  TYPES,
  UNITS
} from '../actions/types';

const initialState = {
  categories: [],
  brands: [],
  units: [],
  types: [],
  sizes: [],
  colors: [],
  products: null,
  allProducts: [],
  searchLoading: false
};

export default function (state = initialState, action) {
  const payload = action.payload;
  switch (action.type) {
    case CATEGORIES:
      return {
        ...state,
        categories: payload
      };

    case BRANDS:
      return {
        ...state,
        brands: payload
      };

    case UNITS:
      return {
        ...state,
        units: payload
      };

    case TYPES:
      return {
        ...state,
        types: payload
      };

    case COLORS:
      return {
        ...state,
        colors: payload
      };

    case SIZES:
      return {
        ...state,
        sizes: payload
      };

    case PRODUCTS:
      return {
        ...state,
        products: payload
      };

    case ALL_PRODUCTS:
      return {
        ...state,
        allProducts: payload
      };

    case SEARCH_LOADING:
      return {
        ...state,
        searchLoading: true
      };

    case SEARCH_LOADING_FALSE:
      return {
        ...state,
        searchLoading: false
      };

    default:
      return state;
  }
}
