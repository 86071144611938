import React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

function YearSelector({ setFormData, formData, year }) {
  const onYearSelected = (e, value) => {
    e.preventDefault();

    setFormData({
      ...formData,
      year: value || ''
    });
  };

  const YEARS = ['2021', '2020'];

  const value = YEARS.find((item) => item === year.toString());

  return (
    <Autocomplete
      value={value}
      id={'select-year'}
      onChange={(event, value) => onYearSelected(event, value)}
      options={YEARS}
      size={'small'}
      renderInput={(params) => (
        <TextField {...params} placeholder={'Select a year'} required variant="outlined" />
      )}
    />
  );
}

export default YearSelector;
