import { base_url, axios, headers } from '../api';

const qs = require('qs');

const getAccounts = async (data) => {
  return await axios.get(`${base_url}/accounts/`, {
    headers: headers()
  });
};

const postAccount = async (data) => {
  return await axios.post(`${base_url}/accounts/new`, qs.stringify(data), {
    headers: headers()
  });
};

export default {
  getAccounts,
  postAccount
};
