import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';

class IncomeExpenseCharts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: 'Income',
          data: []
        },
        {
          name: 'Expense',
          data: []
        }
      ],
      options: {
        chart: {
          type: 'area'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: [3, 3]
        },
        title: {
          text: '',
          align: 'left',
          offsetX: 110
        },
        xaxis: {
          categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
          ]
        },
        tooltip: {
          fixed: {
            enabled: false,
            position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
            offsetY: 30,
            offsetX: 60
          }
        },
        legend: {
          horizontalAlign: 'left',
          offsetX: 40
        },
        responsive: [
          {
            breakpoint: 1000,
            options: {
              legend: {
                position: 'bottom'
              },
              yaxis: []
            }
          }
        ]
      }
    };
  }

  componentDidMount() {
    const incomes = this.props.incomeExpense.map((item) => item.totalIncome);
    const expenses = this.props.incomeExpense.map((item) => item.totalExpense);

    this.setState({
      series: [
        {
          data: incomes
        },
        {
          data: expenses
        }
      ]
    });
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="area"
          height={320}
        />
      </div>
    );
  }
}

export default IncomeExpenseCharts;
