import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import { getAllSupplierPayment, getSuppliers } from '../../../actions/suppliers';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import {
  isNotSupplierHidden,
  SUPPLIER_ENTRY,
  SUPPLIERS,
  SUPPLIERS_DUE,
  SUPPLIERS_PAYMENTS
} from '../../../permissions';
import { SUPPLIER_SECTIONS } from '../../../utils';

function Supplier({
  isLoading,
  getSuppliers,
  getAllSupplierPayment,

  auth: { permissions },
  suppliers: { suppliers, allSupplierPayments }
}) {
  let history = useHistory();

  useEffect(() => {
    if (permissions.length > 0) {
      if (!isNotSupplierHidden(permissions)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getSuppliers();
    getAllSupplierPayment({ size: 5 });
  }, []);

  const handleDetails = (id) => {
    history.push({
      pathname: '/suppliers/details',
      search: `?id=${id}`
    });
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/suppliers'}>Suppliers</Link>
      </li>
    </ol>
  );
  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={SUPPLIER_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        {permissions.find((item) => item.name === SUPPLIER_ENTRY) && (
          <div id="card-content">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5>New Supplier</h5>
                  <p className={'mb-0'}>Add a new supplier details</p>
                </div>
                <div>
                  <Link
                    to={'/suppliers/add-supplier'}
                    className="btn btn-primary p-2 rounded text-decoration-none"
                  >
                    <i className="far fa-plus-square me-2" /> Add Supplier
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row mt-4">
          {permissions.find((item) => item.name === SUPPLIERS) && (
            <div className="col-md-5">
              <div id="card-content">
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Suppliers</h5>
                      <p>Create and manage suppliers</p>
                    </div>
                    <div>
                      {permissions.find((item) => item.name === SUPPLIER_ENTRY) && (
                        <Link to={'/suppliers/add-supplier'} className="btn btn-primary me-2">
                          Add
                        </Link>
                      )}
                      <Link to={'/suppliers/all'} className="btn btn-outline-primary">
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body mt-1">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Supplier name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Phone</TableCell>
                          {permissions.find((item) => item.name === SUPPLIERS_DUE) && (
                            <TableCell>Due (৳)</TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {suppliers.slice(0, 5).map((row) => (
                          <TableRow
                            className={'hover'}
                            onClick={() => handleDetails(row.id)}
                            key={row.name}
                          >
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell>{row.email}</TableCell>
                            <TableCell>{row.phone}</TableCell>
                            {permissions.find((item) => item.name === SUPPLIERS_DUE) && (
                              <TableCell>{row.due}</TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}

          {permissions.find((item) => item.name === SUPPLIERS_PAYMENTS) && (
            <div className="col-md-7">
              <div id="card-content" className={'h-100'}>
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Supplier Payments</h5>
                      <p>All supplier payments</p>
                    </div>
                    <div>
                      <Link to={'/suppliers/payments'} className="btn btn-primary">
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body mt-1">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Supplier name</TableCell>
                          <TableCell>Inv. No</TableCell>
                          <TableCell>Account</TableCell>
                          <TableCell>Reference</TableCell>
                          <TableCell>Amount (৳)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allSupplierPayments &&
                          allSupplierPayments.paginatedData.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell component="th" scope="row">
                                {row.supplier.name}
                              </TableCell>
                              <TableCell>{`INV-${row.purchaseOrderId}`}</TableCell>
                              <TableCell>{row.bank_tran.bank_account.account_name}</TableCell>
                              <TableCell>{row.reference}</TableCell>
                              <TableCell>{row.amount}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

Supplier.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  suppliers: PropTypes.array.isRequired,
  getSuppliers: PropTypes.func.isRequired,
  getAllSupplierPayment: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  suppliers: state.suppliers,
  auth: state.auth
});

export default connect(mapStateToProps, { getSuppliers, getAllSupplierPayment })(Supplier);
