import { axios, base_url, headers } from '../api';

const qs = require('qs');

const getSuppliers = async () => {
  return await axios.get(`${base_url}/suppliers/`, {
    headers: headers()
  });
};

const addSupplier = async (data) => {
  return await axios.post(`${base_url}/suppliers/new`, qs.stringify(data), {
    headers: headers()
  });
};

const updateSupplier = async (data) => {
  return await axios.put(`${base_url}/suppliers/${data.id}`, qs.stringify(data), {
    headers: headers()
  });
};

const addSupplierPayments = async (data) => {
  return await axios.post(`${base_url}/suppliers/${data.id}/payment`, qs.stringify(data), {
    headers: headers()
  });
};

const getSupplierPayments = async (data) => {
  return await axios.post(`${base_url}/suppliers/${data.id}/payments`, qs.stringify(data), {
    headers: headers()
  });
};

const getAllSupplierPayments = async (data) => {
  return await axios.post(`${base_url}/suppliers/payments`, qs.stringify(data), {
    headers: headers()
  });
};

const getSupplier = async (id) => {
  return await axios.get(`${base_url}/suppliers/${id}/details`, {
    headers: headers()
  });
};

export default {
  getSuppliers,
  addSupplier,
  updateSupplier,
  addSupplierPayments,
  getSupplierPayments,
  getSupplier,
  getAllSupplierPayments
};
