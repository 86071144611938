import {
  BANK_ACCOUNTS_DASH,
  DASHBOARD,
  INVENTORY_STATISTICS,
  LOW_STOCK,
  MOST_SOLD,
  POPULAR_CATEGORIES
} from '../actions/types';

const initialState = {
  statistics: null,
  lowStockProducts: null,
  mostSoldProducts: null,
  popularCategories: null,
  bankAccounts: null,
  inventoryStatistics: null
};

export default function (state = initialState, action) {
  const payload = action.payload;
  switch (action.type) {
    case DASHBOARD:
      return {
        ...state,
        statistics: payload
      };

    case LOW_STOCK:
      return {
        ...state,
        lowStockProducts: payload
      };

    case MOST_SOLD:
      return {
        ...state,
        mostSoldProducts: payload
      };

    case POPULAR_CATEGORIES:
      return {
        ...state,
        popularCategories: payload
      };

    case BANK_ACCOUNTS_DASH:
      return {
        ...state,
        bankAccounts: payload
      };

    case INVENTORY_STATISTICS:
      return {
        ...state,
        inventoryStatistics: payload
      };
    default:
      return state;
  }
}
