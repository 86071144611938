import { ALL_SUPPLIER_PAYMENTS, SUPPLIER, SUPPLIER_PAYMENTS, SUPPLIERS } from '../actions/types';

const initialState = {
  suppliers: [],
  supplierPayments: null,
  supplier: null,
  allSupplierPayments: null
};

export default function (state = initialState, action) {
  const payload = action.payload;
  switch (action.type) {
    case SUPPLIERS:
      return {
        ...state,
        suppliers: payload
      };
    case SUPPLIER_PAYMENTS:
      return {
        ...state,
        supplierPayments: payload
      };

    case SUPPLIER:
      return {
        ...state,
        supplier: payload
      };

    case ALL_SUPPLIER_PAYMENTS:
      return {
        ...state,
        allSupplierPayments: payload
      };

    default:
      return state;
  }
}
