import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import moment from 'moment';
import { getStockReports, setInventorySearch } from '../../../../actions/stocks';
import { Pagination } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';

function StockOutReport({
  isLoading,
  setInventorySearch,
  getStockReports,
  stocks: { reports, searchText }
}) {
  const history = useHistory();
  const [range, setRange] = useState({
    from_date: moment(Date()).subtract(30, 'days').format('YYYY-MM-DD'),
    to_date: moment(Date()).format('YYYY-MM-DD')
  });
  const { from_date, to_date } = range;

  useEffect(() => {
    getStockReports({ from_date, to_date, type: 'stock_out', search: searchText });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      getStockReports({ from_date, to_date, type: 'stock_out', search: searchText });
    }, 500);
  }, [searchText]);

  const tableHead = [
    { field: 'date', title: 'Date' },
    { field: 'item.code', title: 'Code' },
    { field: 'item.name', title: 'Name' },
    { field: 'item.price', title: 'Unit Price' },
    { field: 'user.username', title: 'Trans By' },
    { field: 'branch.name', title: 'Branch' },
    { field: 'qty', title: 'Qty' }
  ];

  const handlePurchaseDetails = (id) => {
    history.push({
      pathname: '/purchase/details',
      search: `?id=${id}`
    });
  };

  const handleProductSearch = (value) => {
    setInventorySearch(value);
  };

  const handleSaleDetails = (id) => {
    history.push({
      pathname: '/sale/details',
      search: `?id=${id}`
    });
  };

  return (
    <Fragment>
      <div className="container-fluid p-0">
        <div id="card-content" className={'shadow-none'}>
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Stock Out Reports</h5>
                <p className={'mb-0'}>All stock out reports</p>
              </div>
            </div>
          </div>

          {reports && (
            <div className="card-body d-grid">
              <div className={'overflow-auto'}>
                <MaterialTable
                  title={'Reports'}
                  columns={tableHead}
                  data={reports?.paginatedData}
                  isLoading={isLoading}
                  totalCount={reports?.total}
                  options={{
                    actionsColumnIndex: -1,
                    headerStyle: {
                      backgroundColor: '#F6F9FC',
                      color: '#000'
                    },
                    rowStyle: (rowData) => {
                      return {
                        fontSize: '14px',
                        color: '#000'
                      };
                    },
                    padding: 'dense',
                    pageSize: 20,
                    pageSizeOptions: [10, 25, 50],
                    showFirstLastPageButtons: true,
                    exportAllData: true,
                    exportFileName: `Stock Out - ${moment(new Date()).format('LL')}`,
                    exportButton: true,
                    searchFieldAlignment: 'left',
                    searchFieldStyle: {
                      marginLeft: '3rem'
                    },
                    searchText: searchText,
                    searchAutoFocus: true
                  }}
                  onSearchChange={handleProductSearch}
                  onRowClick={(event, rowData) =>
                    rowData.saleOrderId
                      ? handleSaleDetails(rowData.saleOrderId)
                      : rowData.purchaseOrderId
                      ? handlePurchaseDetails(rowData.purchaseOrderId)
                      : ''
                  }
                  components={{
                    Pagination: (props) => (
                      <div className="text-center d-flex justify-content-end pagination mt-2">
                        <Pagination
                          color={'primary'}
                          count={reports?.lastPage}
                          page={reports?.currentPage}
                          siblingCount={2}
                          onChange={(e, value) =>
                            getStockReports({
                              from_date,
                              to_date,
                              page: value,
                              type: 'stock_out',
                              search: searchText
                            })
                          }
                        />
                      </div>
                    )
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

StockOutReport.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getStockReports: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  stocks: state.stocks
});

export default connect(mapStateToProps, { getStockReports, setInventorySearch })(StockOutReport);
