import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import { addDepartment, getDepartment, updateDepartment } from '../../../../actions/employee';
import Breadcrumb from '../../../layout/Breadcrumb';
import LoadingBackdrop from '../../../layout/LoadingBackdrop';
import Swal from 'sweetalert2';
import { DEPARTMENTS } from '../../../../permissions';
import { EMPLOYEE_SECTIONS } from '../../../../utils';

function AddEditDepartment({
  isLoading,
  addDepartment,
  updateDepartment,
  auth: { permissions },
  getDepartment,
  location
}) {
  const [department, setDepartment] = useState(null);
  const history = useHistory();
  const initFormData = {
    name: department ? department.name : '',
    description: department ? department.description : ''
  };
  const [formData, setFormData] = useState(initFormData);

  const { name, description } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === DEPARTMENTS)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const id = query.get('id');

    if (id) {
      getDepartmentDetails(id);
    }
  }, []);

  useEffect(() => {
    setFormData(initFormData);
  }, [department]);

  const getDepartmentDetails = (id) => {
    getDepartment(id).then((data) => {
      setDepartment(data);
    });
  };

  const createDepartment = async () => {
    await addDepartment(formData);
    const dialogRes = await Swal.fire(
      'Department added!',
      'New department added successfully',
      'success'
    );
    if (dialogRes) {
      history.goBack();
    }
  };

  const onCancelButtonClicked = (e) => {
    history.goBack();
  };

  const saveDepartment = (e) => {
    e.preventDefault();

    department ? updateDepartment(department.id, formData) : createDepartment();
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/employee'}>Department</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/employee/department/all'}>Departments</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/employee/department/new'}>
          {department ? 'Edit Department' : 'Add Department'}
        </Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={EMPLOYEE_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <form id="card-content" onSubmit={(e) => saveDepartment(e)}>
          <div className="card-header">
            <div className="d-flex justify-content-between align-items center">
              <div>
                {department ? (
                  <Fragment>
                    <h5>Edit Department</h5>
                    <p>Edit existing department details</p>
                  </Fragment>
                ) : (
                  <Fragment>
                    <h5>Add New Department</h5>
                    <p>Add a new department</p>
                  </Fragment>
                )}
              </div>
              <div>
                <button type={'submit'} className="btn btn-primary p-2 me-2" disabled={name === ''}>
                  Save
                </button>
                <button
                  onClick={onCancelButtonClicked}
                  type={'button'}
                  className="btn btn-danger p-2 text-light"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <div className="card-body mt-2">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor={'name'}>Name:</label>
                  <input
                    type="text"
                    name={'name'}
                    required
                    value={name}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter name'}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor={'description'}>Description:</label>
                  <input
                    type="text"
                    name={'description'}
                    value={description}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter description'}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  auth: state.auth
});

export default connect(mapStateToProps, {
  addDepartment,
  updateDepartment,
  getDepartment
})(AddEditDepartment);
