import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../../layout/Breadcrumb';
import LoadingBackdrop from '../../../layout/LoadingBackdrop';
import MaterialTable from 'material-table';
import moment from 'moment';
import { Pagination } from '@material-ui/lab';
import { getBankTransferTransactions } from '../../../../actions/bankings';
import FilterLayout from '../../../layout/FilterLayout';
import { TRANSFERS } from '../../../../permissions';
import { ACCOUNTS_SECTIONS } from '../../../../utils';

function AllTransfers({
  isLoading,
  getBankTransferTransactions,
  auth: { permissions },
  bankings: { transferTransactions }
}) {
  const history = useHistory();
  const [search, setSearch] = useState('')
  const [from_date, setFromDate] = useState(
    moment(Date()).subtract(30, 'days').format('YYYY-MM-DD')
  );
  const [to_date, setToDate] = useState(moment(Date()).format('YYYY-MM-DD'));

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === TRANSFERS)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getAllBankTransferTransactions();
  }, []);

  const getAllBankTransferTransactions = () => {
    getBankTransferTransactions({ from_date, to_date });
  };

  useEffect(() => {
    const delayDebounce = setTimeout(async () => {
      getBankTransferTransactions({ search });
    }, 1000);
    return () => clearTimeout(delayDebounce);
  }, [search]);


  const handleHistory = () => {
    history.push('#');
  };

  const handleSaleSearch = (value) => {
    setSearch(value)
  };

  const tableHead = [
    { field: 'id', title: 'ID' },
    { field: 'date', title: 'Date' },
    { field: 'bank_account.account_name', title: 'Account Name' },
    { field: 'bank_account.bank_name', title: 'Bank Name' },
    { field: 'description', title: 'Description' },
    { field: 'amount', title: 'Amount (৳)' }
  ];

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/accounts'}>Accounts</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/accounts/transfers/all'}>Transfers</Link>
      </li>
    </ol>
  );
  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={ACCOUNTS_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>New Transfer</h5>
                <p className={'mb-0'}>Add new transfer</p>
              </div>
              <div>
                <Link
                  to={'/accounts/transfers/new'}
                  className="btn btn-primary p-2 rounded text-decoration-none"
                >
                  <i className="far fa-plus-square me-2" /> Add Transfer
                </Link>
              </div>
            </div>
          </div>
        </div>

        <FilterLayout
          handleActions={getAllBankTransferTransactions}
          setFromDate={setFromDate}
          setToDate={setToDate}
        />

        <div id="card-content" className={'mt-4'}>
          <div className={'overflow-auto'}>
            <MaterialTable
              title={'All Transfers'}
              columns={tableHead}
              data={transferTransactions?.paginatedData}
              isLoading={isLoading}
              totalCount={transferTransactions?.total}
              options={{
                headerStyle: {
                  backgroundColor: '#F6F9FC',
                  color: '#000'
                },
                rowStyle: (rowData) => {
                  return {
                    fontSize: '14px',
                    color: '#000'
                  };
                },
                padding: 'default',
                pageSize: 20,
                actionsColumnIndex: -1,
                pageSizeOptions: [10, 25, 50],
                showFirstLastPageButtons: true,
                exportAllData: true,
                exportFileName: `Transfer transactions - ${moment(new Date()).format('LL')}`,
                exportButton: true,
                searchFieldAlignment: 'left',
                searchFieldStyle: {
                  marginLeft: '3rem'
                }
              }}
              onSearchChange={handleSaleSearch}
              components={{
                Pagination: (props) => (
                  <div className="text-center d-flex justify-content-end pagination mt-2">
                    <Pagination
                      color={'primary'}
                      count={transferTransactions?.lastPage}
                      page={transferTransactions?.currentPage}
                      siblingCount={2}
                      onChange={(e, value) =>
                        getBankTransferTransactions({
                          from_date,
                          to_date,
                          page: value,
                          search
                        })
                      }
                    />
                  </div>
                )
              }}
              actions={[
                {
                  hidden: false,
                  icon: 'print',
                  onClick: (event, rowData) => handleHistory(),
                  isFreeAction: true,
                  tooltip: 'Print'
                },
                {
                  icon: 'refresh',
                  onClick: (event, rowData) => {},
                  isFreeAction: true,
                  tooltip: 'refresh'
                }
              ]}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

AllTransfers.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getBankTransferTransactions: PropTypes.func.isRequired,
  bankings: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  bankings: state.bankings,
  auth: state.auth
});

export default connect(mapStateToProps, { getBankTransferTransactions })(AllTransfers);
