import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import { getSaleReturnList, paySaleReturn } from '../../../actions/sale';
import moment from 'moment';
import MaterialTable from 'material-table';
import FilterLayout from '../../layout/FilterLayout';
import { SALES_RETURN } from '../../../permissions';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getBankAccounts } from '../../../actions/bankings';
import { getPaymentMethods } from '../../../actions/system';
import { SALE_SECTIONS } from '../../../utils';

function SaleReturnList({
  isLoading,
  getBankAccounts,
  getPaymentMethods,
  auth: { permissions },
  getSaleReturnList,
  sale: { saleReturns },
  paySaleReturn,
  bankings: { bankAccounts },
  system: { paymentMethods }
}) {
  const history = useHistory();
  const [from_date, setFromDate] = useState(moment().subtract(30, 'days').format('YYYY-MM-DD'));
  const [to_date, setToDate] = useState(moment().format('YYYY-MM-DD'));
  const [payDialog, setPayDialog] = useState(false);
  const [payDialogData, setPayDialogData] = useState({
    id: '',
    bankAccountId: '',
    paymentMethodId: '',
    returned_amount: 0,
    date: moment().format('YYYY-MM-DD'),
    reason: '',
    total: 0
  });
  const { id, bankAccountId, paymentMethodId, returned_amount, date, reason, total } =
    payDialogData;

  const onChange = (e) => setPayDialogData({ ...payDialogData, [e.target.name]: e.target.value });

  const handleHistory = () => {
    history.push('#');
  };

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === SALES_RETURN)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getAllSaleReturnList();
    getBankAccounts();
    getPaymentMethods();
  }, []);

  useEffect(() => {
    if (bankAccounts.length > 0 && paymentMethods.length > 0) {
      setPayDialogData({
        ...payDialogData,
        bankAccountId: bankAccounts.find((item) => item.default_sale === true).id,
        paymentMethodId: paymentMethods.find((item) => item.name === 'Cash').id
      });
    }
  }, [bankAccounts, paymentMethods]);

  const getAllSaleReturnList = () => {
    getSaleReturnList({ from_date, to_date });
  };

  const onBankAccountSelected = (e, value) => {
    e.preventDefault();

    setPayDialogData({
      ...payDialogData,
      bankAccountId: value ? value.id : ''
    });
  };

  const onPaymentMethodSelected = (e, value) => {
    e.preventDefault();

    setPayDialogData({
      ...payDialogData,
      paymentMethodId: value ? value.id : ''
    });
  };

  const showOrHidePayDialog = (value, data = {}) => {
    setPayDialog(value);
    if (data) {
      setPayDialogData({
        ...payDialogData,
        id: data.id,
        reason: data.reason,
        total: data.total - data.returned_amount,
        returned_amount: data.total - data.returned_amount
      });
    }
  };

  const submitPayNow = (e) => {
    e.preventDefault();

    paySaleReturn(payDialogData, from_date, to_date);
    showOrHidePayDialog(false);
  };

  const handleSaleDetails = (id) => {
    history.push({
      pathname: '/sale/details',
      search: `?id=${id}`
    });
  };

  const handleCustomerDetails = (id) => {
    history.push({
      pathname: '/customers/details',
      search: `?id=${id}`
    });
  };

  const handlePrintSalesReturn = (saleId) => {
    history.push({
      pathname: '/sale/return-list/print',
      search: `?saleId=${saleId}`
    });
  };

  const saleExchange = (id) => {
    history.push({
      pathname: '/sale/exchange',
      search: `?id=${id}`
    });
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/sale'}>Sale</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/sale/return-list'}>Sale Return List</Link>
      </li>
    </ol>
  );

  const tableHead = [
    {
      field: 'id',
      title: 'Inv.',
      cellStyle: { width: '5%' },
      render: (rowData) => `SRI-${rowData.id}`
    },
    {
      field: 'saleOrderId',
      title: 'Sale',
      cellStyle: { width: '5%' },
      render: (rowData) => (
        <span
          className={'font-weight-bold link-primary hover'}
          onClick={() => handleSaleDetails(rowData.saleOrderId)}
        >{`INV-${rowData.saleOrderId}`}</span>
      )
    },
    {
      field: 'createdAt',
      title: 'Date',
      render: (rowData) => {
        return moment(rowData.createdAt).format('YYYY-MM-DD');
      }
    },
    { field: 'sale_order_detail.description', title: 'Description', cellStyle: { width: '20%' } },
    { field: 'reason', title: 'Reason', cellStyle: { width: '10%' } },
    {
      field: 'sale_order.customer.name',
      title: 'C. Name',
      render: (rowData) => (
        <span
          className={'font-weight-bold link-primary hover'}
          onClick={() => handleCustomerDetails(rowData.sale_order.customerId)}
        >
          {rowData.sale_order.customer.name}
        </span>
      )
    },
    { field: 'sale_order.customer.phone', title: 'C. Phone' },
    { field: 'qty', title: 'Qty', type: 'numeric', cellStyle: { width: '5%' } },
    { field: 'total', title: 'Total (৳)', type: 'numeric' },
    { field: 'returned_amount', title: 'Paid (৳)', type: 'numeric' },
    {
      field: 'stock_returned',
      title: 'Stock',
      render: (rowData) => (rowData.stock_returned ? 'Added' : 'Not received')
    }
  ];

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={SALE_SECTIONS} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Sale Return List</h5>
                <p className={'mb-0'}>All sale return lists</p>
              </div>
            </div>
          </div>
        </div>

        <FilterLayout
          handleActions={getAllSaleReturnList}
          setFromDate={setFromDate}
          setToDate={setToDate}
        />

        <div id="card-content" className={'mt-4 d-grid'}>
          <div className={'overflow-auto'}>
            <MaterialTable
              title={'Sale Returns'}
              columns={tableHead}
              data={saleReturns}
              isLoading={isLoading}
              totalCount={saleReturns.length}
              options={{
                headerStyle: {
                  backgroundColor: '#F6F9FC',
                  color: '#000'
                },
                rowStyle: (rowData) => {
                  return {
                    fontSize: '14px',
                    color: '#000'
                  };
                },
                padding: 'default',
                pageSize: 20,
                actionsColumnIndex: -1,
                pageSizeOptions: [10, 25, 50],
                showFirstLastPageButtons: true,
                exportAllData: true,
                exportFileName: `Invoices - ${moment(new Date()).format('LL')}`,
                exportButton: true,
                searchFieldAlignment: 'left',
                searchFieldStyle: {
                  marginLeft: '3rem'
                }
              }}
              actions={[
                {
                  icon: 'refresh',
                  onClick: (event, rowData) => {},
                  isFreeAction: true,
                  tooltip: 'refresh'
                },
                (rowData) => ({
                  icon: 'print',
                  onClick: () => handlePrintSalesReturn(rowData.saleOrderId),
                  tooltip: 'Print All Returns'
                }),
                (rowData) => ({
                  icon: 'payment',
                  tooltip: 'Pay Returned Invoice',
                  isFreeAction: true,
                  disabled: rowData.total === rowData.returned_amount,
                  onClick: () => showOrHidePayDialog(true, rowData)
                }),
                (rowData) => ({
                  icon: 'repeat',
                  onClick: () => saleExchange(rowData.saleOrderId),
                  tooltip: 'Exchange',
                  disabled: rowData.total === rowData.returned_amount
                })
              ]}
            />
          </div>
        </div>
      </div>
      <Dialog
        open={payDialog}
        fullWidth="sm"
        maxWidth="sm"
        disableBackdropClick
        onClose={() => showOrHidePayDialog(false)}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="alert-dialog-slide-title">Pay Returned Sale</DialogTitle>
        <DialogContent>
          <div className="card-body">
            <React.Fragment>
              <div className="form-group">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label className={'m-md-0 mb-1'}>
                      Account<span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <Autocomplete
                      id={'select-branch'}
                      onChange={(event, value) => onBankAccountSelected(event, value)}
                      options={bankAccounts}
                      getOptionLabel={(option) => option.account_name}
                      renderOption={(option) => option.account_name}
                      defaultValue={bankAccounts.find((item) => item.id === bankAccountId)}
                      freeSolo
                      size={'small'}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={'Select One'}
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label className={'m-md-0 mb-1'}>
                      Payment Method<span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <Autocomplete
                      id={'select-branch'}
                      onChange={(event, value) => onPaymentMethodSelected(event, value)}
                      options={paymentMethods}
                      getOptionLabel={(option) => option.name}
                      renderOption={(option) => option.name}
                      defaultValue={paymentMethods.find((item) => item.id === paymentMethodId)}
                      freeSolo
                      size={'small'}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={'Select One'}
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label htmlFor={'returned_amount'}>
                      Returned Amount<span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <input
                      type="number"
                      name={'returned_amount'}
                      value={returned_amount}
                      required
                      min={0}
                      max={total}
                      onChange={(e) => onChange(e)}
                      step=".01"
                      placeholder={'Returned Amount'}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label className={'m-md-0 mb-1'}>
                      Date<span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <input
                      type="date"
                      name={'date'}
                      required
                      defaultValue={date}
                      onChange={(e) => onChange(e)}
                      className="form-control"
                      placeholder={'Choose a date'}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label htmlFor={'reason'}>
                      Returned Reason<span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <input
                      type="text"
                      name={'reason'}
                      value={reason}
                      placeholder={'Reason'}
                      onChange={(e) => onChange(e)}
                      disabled={true}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => showOrHidePayDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!bankAccountId || !paymentMethodId || returned_amount === 0}
            onClick={(e) => submitPayNow(e)}
          >
            Pay Now
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

SaleReturnList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getSaleReturnList: PropTypes.func.isRequired,
  sale: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  sale: state.sale,
  auth: state.auth,
  bankings: state.bankings,
  system: state.system
});

export default connect(mapStateToProps, {
  getSaleReturnList,
  getBankAccounts,
  getPaymentMethods,
  paySaleReturn
})(SaleReturnList);
