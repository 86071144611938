import { axios, base_url, headers } from '../api';

const qs = require('qs');

const login = async (data) => {
  return await axios.post(`${base_url}/auth/login`, qs.stringify(data));
};

const register = async (data) => {
  return await axios.post(`${base_url}/auth/signup`, qs.stringify(data));
};

const getToken = async (data) => {
  return await axios.post(`${base_url}/auth/token`, qs.stringify(data));
};

const logout = async () => {
  return await axios.delete(`${base_url}/auth/logout`, {
    headers: headers()
  });
};

// const logout = async () => {
//     return await axios.post(`${base_url}/auth/logout`, null, {
//         headers: headers()
//     });
// }

const getUser = async () => {
  return await axios.get(`${base_url}/auth/user`, {
    headers: headers()
  });
};

const createUser = async (data) => {
  return await axios.post(`${base_url}/auth/user`, qs.stringify(data), {
    headers: headers()
  });
};

const getUserById = async (id) => {
  return await axios.get(`${base_url}/auth/user/${id}`, {
    headers: headers()
  });
};

const updateUser = async (data) => {
  return await axios.put(`${base_url}/auth/user/update`, qs.stringify(data), {
    headers: headers()
  });
};

// const updateImage = async (fileData) => {
//     return await axios.put(`${base_url}/auth/user/update/image`, fileData, {
//         headers: headers()
//     });
// }

export default {
  login,
  register,
  getUser,
  getToken,
  logout,
  createUser,
  getUserById,
  updateUser
};
