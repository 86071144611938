import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import ReactToPrint from 'react-to-print';
import MaterialTable from 'material-table';
import { getCompany } from '../../../actions/system';
import { getFullAddress, SALE_SECTIONS } from '../../../utils';
import { getSaleReturnsDetails } from '../../../actions/sale';

import demo from '../../../assets/icons/user.png';
import moment from 'moment';
import { base_url_image } from '../../../api';

function PrintSalesReturnInvoice({
  isLoading,
  sale: { saleReturnsDetails },
  system: { company },
  getSaleReturnsDetails,
  location,
  getCompany
}) {
  const [officeData, setOfficeData] = useState([]);
  const componentRef = useRef();
  const history = useHistory();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const id = query.get('saleId');

    if (id) {
      getSaleReturnsDetails(id);
    }

    getCompany();
  }, []);

  useEffect(() => {
    document.getElementById('print-button')?.click();
  }, [componentRef.current]);

  useEffect(() => {
    getOfficeTableData();
  }, [saleReturnsDetails]);

  const goBackButton = (e) => {
    e.preventDefault();

    history.goBack();
  };

  const getOfficeTableData = () => {
    let returnRefs = [];
    let codes = [];
    let totalQty = 0;
    let returnedAmount = 0;
    let total = 0.0;

    if (saleReturnsDetails) {
      for (const returnItem of saleReturnsDetails.returns) {
        returnRefs.push(returnItem.id);
        codes.push(returnItem.sale_order_detail.item.code);
        totalQty += parseInt(returnItem.qty);
        total += parseFloat(returnItem.total);
        returnedAmount += parseFloat(returnItem.returned_amount);
      }
    }

    setOfficeData([
      {
        returnRefs: returnRefs.join(),
        codes: codes.join(),
        totalQty,
        returnedAmount: returnedAmount.toFixed(2),
        total: total.toFixed(2)
      }
    ]);
  };

  const getSummary = () => {
    let totalQty = 0;
    let returnedAmount = 0;
    let total = 0.0;

    if (saleReturnsDetails) {
      for (const returnItem of saleReturnsDetails.returns) {
        totalQty += parseInt(returnItem.qty);
        total += parseFloat(returnItem.total);
        returnedAmount += parseFloat(returnItem.returned_amount);
      }
    }

    return {
      totalQty,
      returnedAmount: returnedAmount.toFixed(2),
      total: total.toFixed(2)
    };
  };

  const tableHead = [
    {
      field: 'tableData.id',
      title: 'SL',
      cellStyle: {
        width: '5%'
      },
      render: (rowData) => {
        return rowData.tableData.id + 1;
      }
    },
    {
      field: 'createdAt',
      title: 'Date',
      render: (rowData) => moment(rowData.createdAt).format('YYYY-MM-DD')
    },
    { field: 'id', title: 'Return Ref', render: (rowData) => `SRI-${rowData.id}` },
    {
      field: 'sale_order_detail.description',
      title: 'Item',
      cellStyle: {
        width: '20%'
      }
    },
    {
      field: 'reason',
      title: 'Reason',
      cellStyle: {
        width: '20%'
      }
    },
    {
      field: 'qty',
      title: 'Qty'
    },
    {
      field: 'total',
      title: 'Total (৳)'
    },
    {
      field: 'returned_amount',
      title: 'Returned (৳)'
    }
  ];

  const officeTableHead = [
    {
      field: 'tableData.id',
      title: 'SL',
      cellStyle: {
        width: '5%'
      },
      render: (rowData) => {
        return rowData.tableData.id + 1;
      }
    },
    {
      field: 'returnRefs',
      title: 'Return Refs',
      cellStyle: {
        width: '25%'
      }
    },
    {
      field: 'codes',
      title: 'Product Codes',
      cellStyle: {
        width: '25%'
      }
    },
    { field: 'totalQty', title: 'Total Qty' },
    { field: 'total', title: 'Total (৳)' },
    { field: 'returnedAmount', title: 'Total Returned (৳)' }
  ];

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/sale'}>Sale</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/sale/return-list'}>Invoices</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/sale/return-list/print'}>Print</Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={SALE_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      {company && saleReturnsDetails && (
        <div className="container-fluid mt-4">
          <div id="card-content">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5>Print Sale Returns</h5>
                  <p>All the returns of a sale invoice shown here.</p>
                </div>
                <div>
                  <ReactToPrint
                    trigger={() => (
                      <button id={'print-button'} className="btn btn-primary me-2">
                        <i className="fas fa-print me-2" /> Print
                      </button>
                    )}
                    content={() => componentRef.current}
                  />

                  <button className="btn btn-outline-primary" onClick={goBackButton}>
                    Go Back
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div id="card-content" className={'mt-4 container p-5'} ref={componentRef}>
            <div className="customer-copy">
              <h6 className={'text-center'}>RETURN INVOICE</h6>
              <div className="d-flex justify-content-between align-items-center">
                <img
                  src={company ? base_url_image + company.logo : demo}
                  className={'invoice-logo'}
                  alt="logo"
                  width={80}
                />

                <div className={'text-end'}>
                  <h5>{company.name}</h5>
                  <p className={'mb-1'}>{getFullAddress(company.address)}</p>
                  <p className={'mb-0'}>
                    <span className={'font-weight-bold'}>Mobile:</span> {company.phone}
                    {company.phone2 && `, ${company.phone2}`}
                  </p>
                </div>
              </div>

              <div className="divider" />

              <div className="d-flex justify-content-between align-items-center mt-3">
                <div>
                  <h6 className={'bg-body ps-2 p-1'}>BILL TO</h6>
                  <div className={'ps-2'}>
                    <p className={'mb-1'}>
                      <span className="font-weight-bold">Name:</span>{' '}
                      {saleReturnsDetails.customer.name}
                    </p>
                    <p className={'mb-1'}>
                      <span className="font-weight-bold">Mobile:</span>{' '}
                      {saleReturnsDetails.customer.phone}
                    </p>
                    <p className={'mb-0'}>
                      <span className="font-weight-bold">Address:</span>{' '}
                      {saleReturnsDetails.customer.address}
                    </p>
                  </div>
                </div>

                <div className={'text-end'}>
                  <h4>Invoice #INV-{saleReturnsDetails.id}</h4>
                  <h6>Order Date: {saleReturnsDetails.date}</h6>
                </div>
              </div>

              <div className={'mt-4 d-grid'}>
                <div style={{ overflow: 'auto' }}>
                  <MaterialTable
                    columns={tableHead}
                    data={saleReturnsDetails?.returns}
                    totalCount={saleReturnsDetails?.returns.length}
                    options={{
                      toolbar: false,
                      exportButton: false,
                      showTitle: false,
                      headerStyle: {
                        padding: 4,
                        backgroundColor: '#F5F7FA'
                      },
                      padding: 'default',
                      pageSize: saleReturnsDetails?.returns.length,
                      paging: false,
                      actionsColumnIndex: -1
                    }}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-end gap-3 mt-4">
                <p>
                  <span className="font-weight-bold">Total Qty:</span> {getSummary().totalQty ?? 0}
                </p>
                <p>
                  <span className="font-weight-bold">Total Amount:</span> {getSummary().total ?? 0}{' '}
                  Tk
                </p>
                <p>
                  <span className="font-weight-bold">Returned:</span>{' '}
                  {getSummary().returnedAmount ?? 0} Tk
                </p>
              </div>
              <div className={'bg-body p-2'}>
                <p className={'mb-0'}>
                  <strong>Notes: </strong>
                </p>
              </div>
            </div>

            <div className="dotted-divider" />

            <div className="office-copy mt-4">
              <h6 className={'text-center'}>RETURN INVOICE | OFFICE COPY</h6>
              <div className="d-flex justify-content-between align-items-center">
                <img
                  src={company ? base_url_image + company.logo : demo}
                  className={'invoice-logo'}
                  alt="logo"
                  width={80}
                />

                <div className={'text-end'}>
                  <h6 className={'bg-body ps-2 p-1'}>BILL TO</h6>
                  <div className={'ps-2'}>
                    <p className={'mb-1'}>
                      <span className="font-weight-bold">Name:</span>{' '}
                      {saleReturnsDetails.customer.name}
                    </p>
                    <p className={'mb-1'}>
                      <span className="font-weight-bold">Mobile:</span>{' '}
                      {saleReturnsDetails.customer.phone}
                    </p>
                    <p className={'mb-0'}>
                      <span className="font-weight-bold">Address:</span>{' '}
                      {saleReturnsDetails.customer.address}
                    </p>
                  </div>
                </div>
              </div>

              <div className="divider" />

              <div className="d-flex justify-content-between align-items-center mt-3">
                <div className={'ps-2'}>
                  <h5>{company.name}</h5>
                  <p className={'mb-1'}>{getFullAddress(company.address)}</p>
                  <p className={'mb-0'}>
                    <span className={'font-weight-bold'}>Mobile:</span> {company.phone}
                    {company.phone2 && `, ${company.phone2}`}
                  </p>
                </div>

                <div className={'text-end'}>
                  <h4>Invoice #INV-{saleReturnsDetails.id}</h4>
                  <h6>Order Date: {saleReturnsDetails.date}</h6>
                </div>
              </div>

              <div className={'mt-4 d-grid'}>
                <div style={{ overflow: 'auto' }}>
                  <MaterialTable
                    columns={officeTableHead}
                    data={officeData}
                    totalCount={officeData.length}
                    options={{
                      toolbar: false,
                      exportButton: false,
                      showTitle: false,
                      headerStyle: {
                        padding: 4,
                        backgroundColor: '#F5F7FA'
                      },
                      padding: 'default',
                      pageSize: officeData.length,
                      paging: false,
                      actionsColumnIndex: -1
                    }}
                  />
                </div>
              </div>

              <div className={'bg-body p-2 mt-3'}>
                <p className={'mb-0'}>
                  <strong>Notes: </strong>
                  {saleReturnsDetails ? saleReturnsDetails.notes : ''}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

PrintSalesReturnInvoice.propTypes = {
  isLoading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  sale: state.sale,
  system: state.system
});

export default connect(mapStateToProps, {
  getSaleReturnsDetails,
  getCompany
})(PrintSalesReturnInvoice);
