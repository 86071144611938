import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../../layout/Breadcrumb';
import LoadingBackdrop from '../../../layout/LoadingBackdrop';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import moment from 'moment';
import { getIncomeCategories, getPaymentMethods } from '../../../../actions/system';
import { addBankTransaction, getBankAccounts } from '../../../../actions/bankings';
import { DEPOSITS } from '../../../../permissions';
import { ACCOUNTS_SECTIONS } from '../../../../utils';

function AddDeposit({
  isLoading,
  getIncomeCategories,
  getBankAccounts,
  auth: { permissions },
  getPaymentMethods,
  addBankTransaction,
  system: { incomeCategories, paymentMethods },
  bankings: { bankAccounts }
}) {
  const history = useHistory();
  const [formData, setFormData] = useState({
    bankAccountId: '',
    date: moment().format('YYYY-MM-DD'),
    description: '',
    amount: 0,
    categoryId: '',
    paymentMethodId: '',
    reference: '',
    trans_type: 'deposit'
  });
  const { bankAccountId, date, description, amount, categoryId, paymentMethodId, reference } =
    formData;
  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === DEPOSITS)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getBankAccounts();
    getIncomeCategories();
    getPaymentMethods();
  }, []);

  const onAccountChangeHandler = (e, value) => {
    e.preventDefault();

    if (value) {
      setFormData({
        ...formData,
        bankAccountId: value.id
      });
    }
  };

  const onCategoryChangeHandler = (e, value) => {
    e.preventDefault();

    setFormData({
      ...formData,
      categoryId: value ? value.id : ''
    });
  };

  const onPaymentMethodChangeHandler = (e, value) => {
    e.preventDefault();

    setFormData({
      ...formData,
      paymentMethodId: value ? value.id : ''
    });
  };

  const onCancelButtonClicked = (e) => {
    history.goBack();
  };

  const saveDeposit = (e) => {
    e.preventDefault();

    addBankTransaction(formData);
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/accounts'}>Accounts</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/accounts/deposits/new'}>New Deposit</Link>
      </li>
    </ol>
  );
  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={ACCOUNTS_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <form onSubmit={(e) => saveDeposit(e)}>
          <div id="card-content">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5>Add New Deposit</h5>
                  <p className={'mb-0'}>Enter deposit details</p>
                </div>
                <div>
                  <button
                    type={'submit'}
                    disabled={!bankAccountId || !amount}
                    className="btn btn-primary p-2 me-2"
                  >
                    Save
                  </button>
                  <button
                    onClick={onCancelButtonClicked}
                    type={'button'}
                    className="btn btn-danger p-2 text-light"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Account*</label>
                    <Autocomplete
                      onChange={(event, value) => onAccountChangeHandler(event, value)}
                      options={bankAccounts}
                      renderOption={(option) => option.account_name}
                      getOptionLabel={(option) => {
                        // e.g value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        return option.account_name;
                      }}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size={'small'}
                          placeholder={'Select Account'}
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>Date*</label>
                    <input
                      type="date"
                      name={'date'}
                      value={date}
                      onChange={(e) => onChange(e)}
                      className="form-control"
                      required
                      placeholder={'Enter date'}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>Description*</label>
                    <input
                      type="text"
                      name={'description'}
                      value={description}
                      onChange={(e) => onChange(e)}
                      className="form-control"
                      required
                      placeholder={'Enter description'}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>Amount*</label>
                    <input
                      type="number"
                      name={'amount'}
                      required
                      value={amount}
                      onChange={(e) => onChange(e)}
                      className="form-control"
                      placeholder={'Amount'}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>Category*</label>
                    <Autocomplete
                      onChange={(event, value) => onCategoryChangeHandler(event, value)}
                      options={incomeCategories}
                      renderOption={(option) => option.name}
                      getOptionLabel={(option) => {
                        // e.g value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        return option.name;
                      }}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size={'small'}
                          placeholder={'Select Category'}
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>Payment Method*</label>
                    <Autocomplete
                      onChange={(event, value) => onPaymentMethodChangeHandler(event, value)}
                      options={paymentMethods}
                      renderOption={(option) => option.name}
                      getOptionLabel={(option) => {
                        // e.g value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        return option.name;
                      }}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size={'small'}
                          placeholder={'Select Payment Method'}
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>Reference</label>
                    <input
                      type="text"
                      name={'reference'}
                      value={reference}
                      onChange={(e) => onChange(e)}
                      className="form-control"
                      placeholder={'Reference'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
}

AddDeposit.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  system: PropTypes.object.isRequired,
  getIncomeCategories: PropTypes.func.isRequired,
  bankings: PropTypes.object.isRequired,
  getBankAccounts: PropTypes.func.isRequired,
  getPaymentMethods: PropTypes.func.isRequired,
  addBankTransaction: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  system: state.system,
  bankings: state.bankings,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getIncomeCategories,
  addBankTransaction,
  getBankAccounts,
  getPaymentMethods
})(AddDeposit);
